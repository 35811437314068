import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface CheckModalProps {
  title: string;
  text: string;
  isOpen: boolean;
  onDecline: () => void;
  onAccept: () => void;
}

export const CheckModal = ({
  isOpen,
  title,
  text,
  onDecline,
  onAccept,
}: CheckModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onDecline}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDecline} color='secondary' variant='contained'>
          Besser nicht
        </Button>
        <Button onClick={onAccept} color='error' variant='contained'>
          Jop
        </Button>
      </DialogActions>
    </Dialog>
  );
};
