import { useState } from 'react';
import styled from 'styled-components';
import {
  Divider,
  ListItem,
  ListItemText,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Chip,
  ButtonGroup,
} from '@mui/material';

import GroupIcon from '@mui/icons-material/Group';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SportsScoreIcon from '@mui/icons-material/SportsScore';

import { COUNTRY_FLAG_API_MEDIUM } from '../../App';

import {
  DriverType,
  EventResult,
  EventType,
  Event_Calendar,
  RacingType,
} from '../../store/LeagueModel';
import { observer } from 'mobx-react-lite';
import { CancelModal } from './CancelModal';
import { ParticipantModal } from './ParticipantsModal';
import { ResultModal } from './ResultModal';

import { getTeamBackground } from '../../utils/getTeamBackground';
import { useContext } from 'react';
import { LeagueStore } from '../../store';
import { getRacingTypeLogo } from '../../utils/getRacingTypeLogo';

import { format, fromUnixTime, isAfter, isBefore } from 'date-fns';
import { CommitModal } from './CommitModal';
import { Breakpoint } from '../../utils/breakpoints';

interface EventProps {
  divId?: string;
  event: Event_Calendar;
}

const hoursBeforeEvent = 2;

export const Event = observer(({ divId, event }: EventProps) => {
  const [resultModal, setResultModal] = useState<boolean>(false);
  const [commitModal, setCommitModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [participantsModal, setParticipantsModal] = useState<boolean>(false);
  const leagueStore = useContext(LeagueStore);

  // remove offset calculation, if divisionService is fixed while creating new division

  const getSubtitle = (): string => {
    const offset = fromUnixTime(event.date).getTimezoneOffset();
    if (offset === -60) {
      return `${format(fromUnixTime(event.date), 'dd.MM.yyyy | HH:mm')} Uhr`;
    } else {
      return `${format(
        fromUnixTime(event.date - 3600),
        'dd.MM.yyyy | HH:mm'
      )} Uhr`;
    }
  };

  const getTimeToCancel = (): number => {
    const offset = fromUnixTime(event.date).getTimezoneOffset();

    if (offset === -60) {
      return event.date - hoursBeforeEvent * 3600;
    } else {
      return event.date - hoursBeforeEvent * 3600 - 3600;
    }
  };

  const getTimeToCancelText = (): string => {
    return `${format(fromUnixTime(getTimeToCancel()), 'HH:mm')} Uhr`;
  };

  const isCancelAvailable = (): boolean => {
    const hasUserCommitted = Boolean(
      event.commitments.find((c) => c.driver === leagueStore.driver?.name)
    );
    // is in League

    const offset = fromUnixTime(event.date).getTimezoneOffset();

    // is in League
    if (offset === -60) {
      return (
        isAfter(fromUnixTime(event.date), new Date()) &&
        leagueStore.driver?.driverType === DriverType.REGULAR &&
        hasUserCommitted
      );
    } else {
      return (
        isAfter(fromUnixTime(event.date - 3600), new Date()) &&
        leagueStore.driver?.driverType === DriverType.REGULAR &&
        hasUserCommitted
      );
    }
  };

  const isCommitAvailable = (): boolean => {
    const hasUserCanceld = Boolean(
      event.cancellations.find((c) => c.driver === leagueStore.driver?.name)
    );

    const offset = fromUnixTime(event.date).getTimezoneOffset();

    // is in League
    if (offset === -60) {
      return (
        isAfter(fromUnixTime(event.date), new Date()) &&
        leagueStore.driver?.driverType === DriverType.REGULAR &&
        leagueStore.isDriverInDivision() &&
        hasUserCanceld
      );
    } else {
      return (
        isAfter(fromUnixTime(event.date - 3600), new Date()) &&
        leagueStore.driver?.driverType === DriverType.REGULAR &&
        leagueStore.isDriverInDivision() &&
        hasUserCanceld
      );
    }
  };

  return (
    <Container>
      <Card className={event.isProvisionalResult ? 'provisional-result' : ''}>
        <CardHeader
          action={
            <IconButton color='primary' disabled>
              <Img
                src={`${COUNTRY_FLAG_API_MEDIUM}/${event.countryCode.toLowerCase()}.png`}
                alt='racingtype'
              ></Img>
            </IconButton>
          }
          title={
            <Subtitle>
              <SmallImg
                src={getRacingTypeLogo(event.racingType)}
                alt='racingtype'
              ></SmallImg>
              <Typography variant='subtitle1' component='p' className='truncate event-title__max-width'>
                {event.type === EventType.SPRINT
                  ? `${event.track} - Sprint`
                  : event.racingType === RacingType.F2
                  ? `${event.track} - Haupt`
                  : event.track}
              </Typography>
            </Subtitle>
          }
          subheader={
            <Subtitle>
              <AccessTimeIcon fontSize='inherit' />
              <Typography variant='body2' component='p'>
                {getSubtitle()}
              </Typography>
            </Subtitle>
          }
        />
        <Divider variant='middle' />
        {event.result && event.result.length > 0 ? (
          <>
            {event.result
              .slice()
              .sort((r1: EventResult, r2: EventResult) => r1.place - r2.place)
              .slice(0, 3)
              .map((r, index) => {
                return (
                  <span key={`${r.place}-${r.name}`}>
                    <ListItem>
                      <ItemContainerResult>
                        <Typography variant='body1'>{r.place}.</Typography>
                        <ResultImg src={getTeamBackground(r.team)} />
                        <ListItemText primary={r.name} />
                        <Chip
                          size='small'
                          label={
                            r.place === 1 || r.time === 'DNF'
                              ? r.time.replace('+', '')
                              : `+${r.time.replace('+', '')}`
                          }
                        />
                        <Typography variant='h6'>{r.points}</Typography>
                      </ItemContainerResult>
                    </ListItem>
                    {index < 2 && <Divider variant='middle' />}
                  </span>
                );
              })}
            <ButtonGroup variant='contained' fullWidth>
              <Button
                fullWidth
                onClick={() => setResultModal(true)}
                color='inherit'
                endIcon={<SportsScoreIcon />}
              >
                Ergebnisse
              </Button>
              {event.highlightLink && (
                <Button
                  fullWidth
                  color='info'
                  href={event.highlightLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  endIcon={<YouTubeIcon />}
                >
                  Highlights
                </Button>
              )}
            </ButtonGroup>
          </>
        ) : (
          <>
            <CardContent>
              <InfoPart>
                <Typography variant='body2' component='p'>
                  Runden
                </Typography>
                <Typography variant='body1' component='p'>
                  {event.laps}
                </Typography>
              </InfoPart>
              <InfoPart>
                <Typography variant='body2' component='p'>
                  Absagen bis
                </Typography>
                <Typography variant='body1' component='p'>
                  {getTimeToCancelText()}
                </Typography>
              </InfoPart>
            </CardContent>
            <Divider variant='middle' />
            <ButtonGroup variant='text' fullWidth color='secondary'>
              <Button
                onClick={() => setParticipantsModal(true)}
                color='inherit'
              >
                <GroupIcon htmlColor={'var(--srmGreen)'} />
                {event.commitments.length}
              </Button>

              <Button
                onClick={() => setCommitModal(true)}
                disabled={!isCommitAvailable()}
              >
                <ThumbUpIcon
                  htmlColor={
                    isCommitAvailable()
                      ? 'var(--srmGreen)'
                      : 'var(--srmGreyLight)'
                  }
                />
              </Button>
              <Button
                onClick={() => setCancelModal(true)}
                disabled={!isCancelAvailable()}
                color='inherit'
              >
                <ThumbDownIcon
                  htmlColor={
                    isCancelAvailable()
                      ? 'var(--srmRed)'
                      : 'var(--srmGreyLight)'
                  }
                />
                {event.cancellations.length}
              </Button>
            </ButtonGroup>
          </>
        )}
      </Card>
      {/* Cancel Modal */}
      {cancelModal && (
        <CancelModal
          divId={divId}
          isOpen={cancelModal}
          title={event.track}
          subtitle={getSubtitle()}
          flag={event.countryCode}
          onClose={() => setCancelModal(false)}
          selectedEvent={{ date: event.date, track: event.track }}
          isRegularCancel={isBefore(
            new Date(),
            fromUnixTime(getTimeToCancel())
          )}
        />
      )}

      {/* Commit Modal */}
      {commitModal && (
        <CommitModal
          divId={divId}
          isOpen={commitModal}
          title={event.track}
          subtitle={getSubtitle()}
          flag={event.countryCode}
          onClose={() => setCommitModal(false)}
          selectedEvent={{ date: event.date, track: event.track }}
          cancellations={event.cancellations}
        />
      )}

      {/* Participant Modal */}
      {participantsModal && (
        <ParticipantModal
          isOpen={participantsModal}
          title={event.track}
          subtitle={getSubtitle()}
          flag={event.countryCode}
          onClose={() => setParticipantsModal(false)}
          cancellations={event.cancellations}
          participants={event.commitments}
        />
      )}

      {/* Result Modal */}
      {resultModal && (
        <ResultModal
          isOpen={resultModal}
          title={event.track}
          subtitle={getSubtitle()}
          flag={event.countryCode}
          onClose={() => setResultModal(false)}
          results={event.result}
        />
      )}
    </Container>
  );
});

const Container = styled.div`
  .result-icon {
    max-height: 20px;
  }

  .MuiCardContent-root {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 8px 16px;
  }

  .MuiButtonGroup-root {
    padding-top: 16px;
    box-shadow: none;

    .MuiButton-root {
      &:not(:last-child) .MuiSvgIcon-root {
        margin-right: 10px;
        height: 24px;
        width: 24px;
      }
    }
  }
`;

const Img = styled.img`
  width: 36px;
`;

const SmallImg = styled.img`
  max-height: 12px;
  margin-right: 3px;
`;

const ResultImg = styled.img`
  max-width: 22px;
  border-radius: 4px;
`;

const ItemContainerResult = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 15px 22px 3fr 1fr 25px;
  gap: 5px;
  align-items: center;

  .MuiListItemText-root {
    white-space: nowrap;
  }

  p,
  h6 {
    justify-self: center;
  }

  @media only screen and (min-width: ${Breakpoint.desktop}px) {
    grid-template-columns: 15px 25px 3fr 1fr 30px;
  }

  @media only screen and (min-width: ${Breakpoint.mediumDesktop}px) {
    grid-template-columns: 15px 25px 5fr 1fr 35px;
  }
`;

const InfoPart = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .MuiTypography-body2 {
    color: var(--srmGreyLight);
  }
`;

const Subtitle = styled.div`
  display: flex;
  grid-gap: 3px;
  align-items: center;

  p {
    padding-top: 2px;
  }
`;
