import { format, fromUnixTime } from 'date-fns';

export const getEventTime = (date: number) => {
  const offset = fromUnixTime(date).getTimezoneOffset();

  if (offset === -60) {
    return `${format(fromUnixTime(date), 'dd.MM.yyyy | HH:mm')} Uhr`;
  } else {
    return `${format(fromUnixTime(date - 3600), 'dd.MM.yyyy | HH:mm')} Uhr`;
  }
};

export const timeout = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
