import { ReactNode, useState, useContext, useMemo } from 'react';
import {
  Fab,
  IconButton,
  Menu,
  MenuItem,
  SpeedDial,
  SpeedDialAction,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { Navigation } from '../components/Navigation';
import { NAVIGATION_PATH, NAVIGATION_TITLE } from '../utils/navigation';
import { LeagueStore, UserStore } from '../store';
import { useLocation, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Breakpoint, mediaMinWidth, useBreakpoint } from '../utils/breakpoints';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import { BottomNavigation } from '../components/BottomNavigation';
import { getRacingTypeLogo } from '../utils/getRacingTypeLogo';

interface LayoutProps {
  children: ReactNode;
}

export const Layout = observer(({ children }: LayoutProps) => {
  const isTablet = useBreakpoint(Breakpoint.tablet);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isDivisionFilterOpen, setIsDivisionFilterOpen] =
    useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();
  const leagueStore = useContext(LeagueStore);
  const userStore = useContext(UserStore);
  const [divisionFilter, setDivisionFilter] = useState<HTMLElement | null>(
    null
  );

  const showBackButton = useMemo(() => {
    return location.pathname.includes('manage/');
  }, [location]);

  const handleDivisionFilterClose = () => {
    setDivisionFilter(null);
  };

  const handleDivisionFilterOpen = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setDivisionFilter(event.currentTarget);
  };

  const handleDivisionFilterItem = (divisionId: string) => {
    leagueStore.setCurrentDivision(divisionId);
    handleDivisionFilterClose();
  };

  return (
    <Container showMenu={false}>
      <Header
        bgColor={
          location.pathname === NAVIGATION_PATH.PROFILE
            ? leagueStore.getTeam.color
            : undefined
        }
      >
        <BackButton>
          {showBackButton && (
            <IconButton onClick={() => history.goBack()} size='large'>
              <ArrowBackIcon />
            </IconButton>
          )}
        </BackButton>

        <Typography variant='h5'>
          {location.pathname === NAVIGATION_PATH.PROFILE
            ? userStore.displayName
            : NAVIGATION_TITLE[
                location.pathname.replace('/', '').replace('/', '_')
              ]}
        </Typography>
        <MenuButton>
          {!isTablet && (
            <IconButton size='medium' onClick={() => setIsMenuOpen(true)}>
              <MenuIcon />
            </IconButton>
          )}
        </MenuButton>
      </Header>
      <Navigation
        isOpen={isMenuOpen}
        handleClose={() => setIsMenuOpen(false)}
        handleOpen={() => setIsMenuOpen(true)}
      />
      <Content>
        {children}
        {(location.pathname === NAVIGATION_PATH.EVENTS ||
          location.pathname === NAVIGATION_PATH.STANDINGS ||
          location.pathname === NAVIGATION_PATH.PENALTIES) && (
          <FilterContainer>
            {!isTablet ? (
              <>
                <Fab
                  size='medium'
                  color='primary'
                  onClick={handleDivisionFilterOpen}
                >
                  {leagueStore.getLeagueDivision()?.shortName ?? ''}
                </Fab>
                <Menu
                  id='simple-menu'
                  anchorEl={divisionFilter}
                  keepMounted
                  open={Boolean(divisionFilter)}
                  onClose={handleDivisionFilterClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {leagueStore.getAllDivisions.map((div) => {
                    return (
                      <MenuItem
                        key={div._id}
                        onClick={() => handleDivisionFilterItem(div._id)}
                        selected={leagueStore.currentDivision === div._id}
                      >
                        {`${div.racingType} - ${div.name}`}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
            ) : (
              <SpeedDial
                hidden={!isTablet}
                ariaLabel='division auswahl'
                icon={
                  <Typography variant='subtitle1'>
                    {leagueStore.getLeagueDivision()?.shortName ?? ''}
                  </Typography>
                }
                onClose={() => setIsDivisionFilterOpen(false)}
                onOpen={() => setIsDivisionFilterOpen(true)}
                open={isDivisionFilterOpen}
              >
                {leagueStore.getAllDivisions.map((div) => {
                  return (
                    <SpeedDialAction
                      key={div._id}
                      icon={<Img src={getRacingTypeLogo(div.racingType)} />}
                      tooltipTitle={div.name}
                      tooltipOpen
                      onClick={() => handleDivisionFilterItem(div._id)}
                    />
                  );
                })}
              </SpeedDial>
            )}
          </FilterContainer>
        )}
      </Content>
      <BottomNavigation />
    </Container>
  );
});
interface ContainerProps {
  showMenu: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 'navigation header' 'navigation content' 'navigation bottomNavigation';
  height: 100%;
  width: 100%;
`;

interface HeaderProps {
  bgColor?: string;
}

const Header = styled.header<HeaderProps>`
  grid-area: header;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 0 10px;
  color: ${(props) => (props.bgColor ? '#fff' : 'var(--srmGrey)')};
  ${(props) => props.bgColor && 'text-shadow: 1px 1px 3px var(--srmGrey)'};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'var(--srmGreenLight)'};
  ${(props) => !props.bgColor && ' box-shadow: 0 -4px 10px var(--srmGrey)'};

  .MuiCardContent-root,
  .MuiCardContent-root:last-child {
    padding: 8px;
  }
`;

const BackButton = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  left: 5px;
  align-items: center;
`;

const MenuButton = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  right: 5px;
  align-items: center;
`;

const Content = styled.div`
  grid-area: content;
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

const FilterContainer = styled.div`
  position: fixed;
  z-index: 999;
  bottom: calc(56px + var(--srmPadding));
  right: var(--srmPadding);

  @media only screen and (min-width: ${mediaMinWidth.tablet}) {
    transform: none;
    left: auto;
    bottom: var(--srmPadding);
    right: var(--srmPaddingBig);
  }
`;

const Img = styled.img`
  height: 12px;
`;
