import {
  Drawer as UnstyledDrawer,
  IconButton,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

interface FullHeightModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode | React.ReactNode[];
  icon?: string;
  title: string;
  subtitle?: string;
  state?: boolean;
}

export const FullHeightModal = ({
  isOpen,
  onClose,
  children,
  icon,
  title,
  subtitle,
  state,
}: FullHeightModalProps) => {
  return (
    <Drawer anchor='right' open={isOpen} onClose={onClose}>
      <DrawerContent>
        <DrawerHeader>
          {title && (
            <>
              <TitleItems>
                {icon && (
                  <div>
                    <Img src={icon} alt='icon' />
                  </div>
                )}
                <Title>
                  <Typography variant='h6'>
                    {title}
                    {state !== undefined && (
                      <>
                        &nbsp;
                        {state && <Green>(aktiv)</Green>}
                        {!state && <Red>(inaktiv)</Red>}
                      </>
                    )}
                  </Typography>

                  {subtitle && (
                    <Typography variant='body2' component='span'>
                      {subtitle}
                    </Typography>
                  )}
                </Title>
              </TitleItems>
            </>
          )}

          <IconButton size='large'>
            <CloseIcon onClick={onClose} />
          </IconButton>
        </DrawerHeader>
        {children}
      </DrawerContent>
    </Drawer>
  );
};

const Drawer = styled(UnstyledDrawer)`
  .MuiDrawer-paper {
    background-color: var(--srmBackground) !important;
    color: var(--srmGrey) !important;
    width: 100% !important;

    @media only screen and (min-width: 1024px) {
      width: 60% !important;
    }

    @media only screen and (min-width: 1280px) {
      width: 40% !important;
    }
  }
`;

const DrawerContent = styled.div`
  padding: 15px;
`;

const DrawerHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: var(--srmPadding);
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h5,
  span {
    line-height: 1;
  }
`;

const TitleItems = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;

  div {
    display: flex;
    align-self: center;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    flex: 1;
  }
`;

const Img = styled.img`
  width: 36px;
`;

const Red = styled.span`
  color: var(--srmRed);
`;

const Green = styled.span`
  color: var(--srmSuccess);
`;
