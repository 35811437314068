import {
  Fab,
  Button,
  NativeSelect,
  TextField,
  FormControl,
  InputLabel,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { observer } from 'mobx-react-lite';
import { PageContent, ScrollingItemsContainer } from '../../components/Content';

import styled from 'styled-components';
import { FormEvent, useState } from 'react';
import { Modal } from '../../components/Modal/Modal';
import { useContext, useEffect } from 'react';
import { LeagueStore } from '../../store';
import AddIcon from '@mui/icons-material/Add';
import {
  EventType,
  IncidentFilter,
  IncidentUser,
  RacingType,
} from '../../store/LeagueModel';
import { FabContainer } from '../../components/FabContainer';
import { Incident } from '../../components/Incident';

import { Headline } from '../../components/Headline';

export const Incidents = observer(() => {
  const leagueStore = useContext(LeagueStore);
  const [newIncidentModal, setNewIncidentModal] = useState<boolean>(false);

  //   Create new incident
  const [incidentUser, setIncidentUser] = useState<IncidentUser>(
    IncidentUser.DRIVER
  );
  const [division, setDivision] = useState<string>(leagueStore.currentDivision);
  const [track, setTrack] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [view, setView] = useState<string>('');
  const [against, setAgainst] = useState<string>('Game');

  useEffect(() => {
    setDivision(leagueStore.currentDivision);
    setIncidentUser(IncidentUser.DRIVER);
    setAgainst('Game');
    setTrack('');
    setNote('');
    setView('');
  }, [newIncidentModal]);

  const createIncident = (event: FormEvent) => {
    leagueStore.createIncident(
      {
        divisionId: division,
        track: track === IncidentFilter.TEST_RACE ? 'Testrennen' : track,
        note:
          view === '' || !view.includes('https') ? `${note} (Kein Clip)` : note,
        view: view.includes('https') ? view : '',
        receiver: against,
      },
      incidentUser
    );
    setNewIncidentModal(false);
    event.preventDefault();
  };

  return (
    <PageContent>
      <Headline
        title='Aktuelle Vorfälle'
        tooltipText='Hier kannst du Vorfälle melden und deine Sichten eintragen, falls welche
        mit deiner Beteiligung vorliegen.'
      />

      <Divider />
      <br />
      <ScrollingItemsContainer>
        {leagueStore.getIncidents.map((c, i) => {
          return <Incident key={c.date + i} incident={c} />;
        })}
      </ScrollingItemsContainer>
      <FabContainer>
        <Fab
          color='primary'
          size='medium'
          onClick={() => setNewIncidentModal(!newIncidentModal)}
        >
          <AddIcon />
        </Fab>
      </FabContainer>

      {/* New Incident Modal */}
      {newIncidentModal && (
        <Modal
          isOpen={newIncidentModal}
          title='Einen neuen Vorfall melden'
          handleClose={() => setNewIncidentModal(false)}
        >
          <ModalContent>
            <form onSubmit={createIncident}>
              <RowContainer>
                <FormControl fullWidth variant='standard'>
                  <InputLabel htmlFor='incident-division'>
                    Wähle die Liga
                  </InputLabel>
                  <NativeSelect
                    id='incident-division'
                    fullWidth
                    onChange={(event) => setDivision(event.target.value)}
                    value={division}
                    required
                  >
                    <optgroup label='Sonderfall'>
                      <option key='Sonstige' value={IncidentFilter.TEST_RACE}>
                        Testrennen
                      </option>
                    </optgroup>
                    <optgroup label='F1 Ligen'>
                      {leagueStore.getAllDivisions
                        .filter((div) => div.racingType === RacingType.F1)
                        .map((div) => {
                          return (
                            <option key={div._id} value={div._id}>
                              {div.name}
                            </option>
                          );
                        })}
                    </optgroup>
                    <optgroup label='F2 Ligen'>
                      {leagueStore.getAllDivisions
                        .filter((div) => div.racingType === RacingType.F2)
                        .map((div) => {
                          return (
                            <option key={div._id} value={div._id}>
                              {div.name}
                            </option>
                          );
                        })}
                    </optgroup>
                  </NativeSelect>
                </FormControl>
                <br />
                <br />

                <NativeSelect
                  id='incident-track'
                  fullWidth
                  onChange={(event) => setTrack(event.target.value)}
                  value={track}
                  required
                  variant='standard'
                >
                  <option value='' disabled>
                    Wähle ein Rennen
                  </option>
                  {division === IncidentFilter.TEST_RACE && (
                    <optgroup label='Sonderfall'>
                      <option key='Testrennen' value='Testrennen'>
                        Testrennen
                      </option>
                    </optgroup>
                  )}
                  <optgroup label='Ligarennen'>
                    {division === IncidentFilter.TEST_RACE
                      ? leagueStore.getAllEvents().map((ev, i) => {
                          return (
                            <option key={ev.date + i} value={ev.track}>
                              {ev.track}
                            </option>
                          );
                        })
                      : leagueStore.getAllEvents(division).map((ev, i) => {
                          const name =
                            ev.type === EventType.SPRINT
                              ? `${ev.track} - Sprint`
                              : ev.racingType === RacingType.F2
                              ? `${ev.track} - Haupt`
                              : ev.track;

                          return (
                            <option key={ev.date + i} value={name}>
                              {name}
                            </option>
                          );
                        })}
                  </optgroup>
                </NativeSelect>
              </RowContainer>
              <br />
              <FormControl fullWidth variant='standard'>
                <InputLabel htmlFor='incident-against'>
                  Gegen wen oder was ?
                </InputLabel>
                <NativeSelect
                  id='incident-against'
                  fullWidth
                  onChange={(event) => setAgainst(event.target.value)}
                  value={against}
                  required
                >
                  <optgroup label='Sonderfälle'>
                    <option key='Game' value='Game'>
                      Game
                    </option>
                    <option key='Bewerber' value='Bewerber'>
                      Bewerber
                    </option>
                  </optgroup>

                  <optgroup label='Fahrer'>
                    {leagueStore.getAllDriver().map((dr) => {
                      return (
                        <option key={dr.driverId} value={dr.name}>
                          {dr.name}
                        </option>
                      );
                    })}
                  </optgroup>
                </NativeSelect>
              </FormControl>
              <br />
              <br />
              <TextField
                id='view'
                label='Deine Sicht'
                placeholder='Link von Twitch, YouTube oder WeTransfer'
                value={view}
                onChange={(event) => setView(event.target.value)}
                fullWidth
                variant='standard'
              />
              <br />
              <br />
              <TextField
                id='note'
                multiline
                maxRows={3}
                minRows={1}
                label='Beschreibung des Vorfalls'
                placeholder='Habe keinen Clip, aber...'
                required
                value={note}
                onChange={(event) => setNote(event.target.value)}
                fullWidth
                variant='standard'
              />
              <br />
              <br />
              {(leagueStore.isAdmin() || leagueStore.isSteward()) && (
                <>
                  <RadioGroup
                    row
                    value={incidentUser}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setIncidentUser(
                        (event.target as HTMLInputElement).value as IncidentUser
                      )
                    }
                  >
                    <FormControlLabel
                      value={IncidentUser.DIRECTOR}
                      control={<Radio color='primary' />}
                      label='Rennleitung'
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={IncidentUser.DRIVER}
                      control={<Radio color='primary' />}
                      label='Fahrer'
                      labelPlacement='end'
                    />
                  </RadioGroup>
                  <br />
                  <br />
                </>
              )}
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={!note || !against || !track}
                fullWidth
              >
                Vorfall melden
              </Button>
            </form>
          </ModalContent>
        </Modal>
      )}
    </PageContent>
  );
});

const ModalContent = styled.div``;

const RowContainer = styled.div`
  display: flex;
  gap: 5px;
`;
