import {
  Badge,
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import { useContext } from 'react';
import { LeagueStore } from '../store';
import { NAVIGATION_PATH } from '../utils/navigation';

import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ErrorIcon from '@mui/icons-material/Error';
import PersonIcon from '@mui/icons-material/Person';

import TodayIcon from '@mui/icons-material/Today';
import GavelIcon from '@mui/icons-material/Gavel';

import { Breakpoint, useBreakpoint } from '../utils/breakpoints';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

export const BottomNavigation = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const leagueStore = useContext(LeagueStore);
  const isTablet = useBreakpoint(Breakpoint.tablet);

  const navigate = (path: NAVIGATION_PATH) => {
    history.push(path);
  };
  return (
    <>
      {!location.pathname.includes(NAVIGATION_PATH.MANAGE) &&
        !location.pathname.includes(NAVIGATION_PATH.SETTINGS) &&
        leagueStore.currentLeague &&
        !isTablet && (
          <NavigationContainer>
            <MuiBottomNavigation
              value={location.pathname}
              onChange={(event, path) => {
                navigate(path);
              }}
              showLabels
            >
              <BottomNavigationAction
                label='Profil'
                icon={<PersonIcon />}
                value={NAVIGATION_PATH.PROFILE}
              />
              <BottomNavigationAction
                label='Tabellen'
                icon={<FormatListNumberedIcon />}
                value={NAVIGATION_PATH.STANDINGS}
              />
              <BottomNavigationAction
                label='Events'
                icon={<TodayIcon />}
                value={NAVIGATION_PATH.EVENTS}
              />
              <BottomNavigationAction
                label='Strafen'
                icon={<ErrorIcon />}
                value={NAVIGATION_PATH.PENALTIES}
              />

              <BottomNavigationAction
                label='Vorfälle'
                icon={
                  <Badge
                    color='error'
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    badgeContent={leagueStore.getIncidentsAgainstMe.length}
                  >
                    <GavelIcon />
                  </Badge>
                }
                value={NAVIGATION_PATH.INCIDENTS}
              />
            </MuiBottomNavigation>
          </NavigationContainer>
        )}
    </>
  );
});

const NavigationContainer = styled.div`
  grid-area: bottomNavigation;
  position: relative;
  z-index: 1;
  background-color: var(--srmBackground);
  box-shadow: 0 4px 10px var(--srmGrey);

  .MuiBottomNavigationAction-root {
    min-width: auto;
  }

  .MuiSvgIcon-root {
    width: 0.9em;
    height: 0.9em;
  }
`;
