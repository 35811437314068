import styled from 'styled-components';
import {
  IconButton,
  Modal as StyledModal,
  Typography,
  Zoom,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint, useBreakpoint } from '../utils/breakpoints';
import { ReactElement } from 'react';

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
}

export const FilterModal = ({
  isOpen,
  onClose,
  children,
  title,
  subtitle = '',
}: FilterModalProps) => {
  const handleClose = (event: any) => {
    if (event.target.id === 'modalBackground') {
      onClose();
    }
  };

  return (
    <StyledModal open={isOpen} onClose={onClose}>
      <div
        id='modalBackground'
        className='modal'
        style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          outline: 'none',
        }}
        onClick={handleClose}
      >
        <Transition direction='up'>
          <ModalContent className='modal'>
            <TitleItems>
              <Title>
                {title && (
                  <>
                    <Typography variant='h5'>{title}</Typography>
                    <Typography variant='subtitle1' component='span'>
                      {subtitle}
                    </Typography>
                  </>
                )}
              </Title>
              <IconButton size='large'>
                <CloseIcon onClick={onClose} />
              </IconButton>
            </TitleItems>

            {children}
          </ModalContent>
        </Transition>
      </div>
    </StyledModal>
  );
};

interface TransitionProps {
  children: ReactElement<any, any>;
  direction: 'up' | 'left' | 'right' | 'down' | undefined;
}

export const Transition = ({ direction, children }: TransitionProps) => {
  const isTablet = useBreakpoint(Breakpoint.tablet);
  return isTablet ? (
    <Zoom in mountOnEnter unmountOnExit>
      {children}
    </Zoom>
  ) : (
    <Slide direction={direction} in mountOnEnter unmountOnExit>
      {children}
    </Slide>
  );
};

interface ModalContentProps {
  background?: string;
  backgroundColor?: string | null;
  noPadding?: boolean;
}

const ModalContent = styled.div<ModalContentProps>`
  width: 100%;
  max-height: 50%;
  overflow-y: auto;
  position: absolute;
  bottom: 0;
  padding: var(--srmPadding);
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  @media only screen and (min-width: 768px) {
    max-height: 60%;
    border-radius: 15px;
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
    width: 40%;
  }

  @media only screen and (min-width: 1024px) {
    width: 30%;
  }
`;

const TitleItems = styled.div`
  padding-bottom: var(--srmPadding);
  display: flex;
  align-items: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    flex: 1;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h5,
  span {
    line-height: 1.1;
  }
`;
