import {
  MobileStepper,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { CenterModal } from '../../components/Modal/CenterModal';
import { LeagueStore } from '../../store';
import { Quizz_Answers } from '../../store/LeagueModel';

import DoneIcon from '@mui/icons-material/CheckCircle';
import NotDoneIcon from '@mui/icons-material/HighlightOff';
import styled from 'styled-components';

interface QuizzProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const Quizz = observer(({ isOpen, handleClose }: QuizzProps) => {
  const leagueStore = useContext(LeagueStore);
  const [step, setStep] = useState<number>(-1);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Quizz_Answers[]>([]);

  const handleCloseAndReset = () => {
    const newQuestions = leagueStore.getQuizz().map((q) => {
      return {
        ...q,
        yourAnswerId: 0,
      };
    });
    setQuestions(newQuestions);
    setStep(-1);
    setShowResults(false);
    handleClose();
  };

  useEffect(() => {
    const newQuestions = leagueStore.getQuizz().map((q) => {
      return {
        ...q,
        yourAnswerId: 0,
      };
    });

    setQuestions(newQuestions);
  }, []);

  const handleUpdateAnswer = (value: number) => {
    const tempQuestions = questions.slice();
    tempQuestions[step].yourAnswerId = value;

    setQuestions(tempQuestions);
  };

  const handleUpdateQuizz = () => {
    leagueStore.updateQuizz(isQuizzPassed());
    setShowResults(true);
  };

  const isQuizzPassed = () => {
    const count = questions.filter(
      (q) => q.correctAnswerId === q.yourAnswerId
    ).length;

    return count >= questions.length * 0.8;
  };

  const getResultText = (): string => {
    if (isQuizzPassed()) {
      return 'Glückwunsch! Du hast unsere Fahrprüfung bestanden :)';
    } else {
      return 'Leider hast du unsere Prüfung nicht bestanden. Bei Fragen, melde dich auf discord. Du kannst die Prüfung aber gerne nochmal absolvieren';
    }
  };

  return (
    <CenterModal
      title='Fahrprüfung'
      isOpen={isOpen}
      handleClose={handleCloseAndReset}
    >
      <br />
      <div>
        {step === -1 && (
          <Typography variant='body1'>
            Willkommen bei der Fahrprüfung der {leagueStore.name}. Hiermit
            wollen wir sicherstellen, das du das Regelwerk gelesen und
            verstanden hast. Solltest du Fragen haben, dann meld dich einfach
            auf Discord.
          </Typography>
        )}
        {step > -1 && step < questions.length && (
          <>
            <Typography variant='body1'>{questions[step].text}</Typography>
            <br />
            <RadioGroup
              value={questions[step].yourAnswerId}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleUpdateAnswer(
                  parseInt((event.target as HTMLInputElement).value, 10)
                )
              }
            >
              {questions[step].answers.map((q, i) => {
                return (
                  <FormControlLabel
                    key={`answer-${i}`}
                    value={q.answerId}
                    control={
                      <Radio
                        color='primary'
                        checked={q.answerId === questions[step].yourAnswerId}
                      />
                    }
                    label={q.text}
                  />
                );
              })}
            </RadioGroup>
          </>
        )}
        {step >= questions.length && (
          <div>
            {showResults ? (
              <>
                <Typography variant='h6' component='p'>
                  {getResultText()}
                </Typography>
                <br />
                <br />

                {questions.map((q) => {
                  return (
                    <span key={q.questionId}>
                      <Row>
                        <div>
                          <Typography variant='body1' component='p'>
                            <Bold>Frage:</Bold> {q.text}
                          </Typography>
                          <Typography variant='body1' component='p'>
                            <Bold>Antwort:</Bold> {q.correctAnswerText}
                          </Typography>
                        </div>
                        {q.correctAnswerId === q.yourAnswerId && (
                          <DoneIcon htmlColor='var(--srmGreen)' />
                        )}
                        {q.correctAnswerId !== q.yourAnswerId && (
                          <NotDoneIcon htmlColor='var(--srmRed)' />
                        )}
                      </Row>
                      <br />
                    </span>
                  );
                })}
              </>
            ) : (
              <>
                <Typography variant='body1'>
                  Du hast unsere Fahrprüfung abgeschlossen. Um dein Ergebnis zu
                  sehen und damit du weißt, ob du bestanden hast oder nicht,
                  bitte einmal den Button unten klicken
                </Typography>
                <br />
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={handleUpdateQuizz}
                >
                  Ergebnis anzeigen
                </Button>
              </>
            )}
          </div>
        )}
      </div>
      <br />
      {!showResults && (
        <MobileStepper
          variant='progress'
          steps={leagueStore.getQuizz.length + 1}
          position='static'
          activeStep={step}
          nextButton={
            <Button
              size='small'
              onClick={() => setStep(step + 1)}
              disabled={
                (step >= 0 &&
                  questions[step] &&
                  questions[step].yourAnswerId === 0) ||
                (step >= 0 && !questions[step])
              }
            >
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size='small'
              onClick={() => setStep(step - 1)}
              disabled={step < 0}
            >
              <KeyboardArrowLeft />
            </Button>
          }
        />
      )}
    </CenterModal>
  );
});

const Row = styled.div`
  display: flex;
  gap: var(--srmPadding);

  p {
    flex: 1;
  }
`;

const Bold = styled.span`
  font-weight: 600;
`;
