import { FormEvent, useContext, useState } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import {
  Contender as Contender_IF,
  DriverType,
} from '../../../store/LeagueModel';

import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/CheckCircle';
import NotDoneIcon from '@mui/icons-material/HighlightOff';
import { Modal } from '../../../components/Modal/Modal';
import { LeagueStore } from '../../../store';
import { format, fromUnixTime } from 'date-fns';
import { Tags } from '../../../components/Tags';

interface ContenderProps {
  contender: Contender_IF;
}

export const Contender = observer(({ contender }: ContenderProps) => {
  const leagueStore = useContext(LeagueStore);

  const [editContender, setEditContender] = useState<boolean>(false);
  const [isTestRaceDone, setIsTestRaceDone] = useState<boolean>(
    contender.testRace
  );
  const [driverType, setDriverType] = useState<DriverType>(
    DriverType.CONTENDER
  );
  const [divisionId, setDivisionId] = useState<string>('');
  const [newTeam, setNewTeam] = useState<string>('');
  const [carNumber, setCarNumber] = useState<number>(0);
  const [tags, setTags] = useState<string[]>([]);

  const addTag = (tag: string) => {
    const tempTags = tags.slice();
    tempTags.push(tag);
    setTags(tempTags);
  };

  const removeTag = (index: number) => {
    const tempTags = tags.slice();
    tempTags.splice(index, 1);
    setTags(tempTags);
  };

  const handleRemoveContender = () => {};

  const handleUpdate = (event: FormEvent) => {
    event.preventDefault();
    leagueStore.updateContender({
      ...contender,
      testRace: isTestRaceDone,
      driverType,
      divisionId,
      team: newTeam,
      carNumber,
      tags,
    });

    handleCloseEdit();
  };

  const handleCloseEdit = () => {
    setIsTestRaceDone(contender.testRace);
    setDriverType(DriverType.CONTENDER);
    setDivisionId('');
    setNewTeam('');
    setCarNumber(0);
    setEditContender(false);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={contender.name}
          subheader={`Beworben am: ${
            contender.date
              ? format(fromUnixTime(contender.date), 'dd.MM.yyyy')
              : 'k.A.'
          }`}
          avatar={<Avatar />}
          action={
            <IconButton onClick={() => setEditContender(true)} size='large'>
              <EditIcon htmlColor='var(--srmGreyMed)' />
            </IconButton>
          }
        />
        <CardContent>
          <MenuList dense>
            <MenuItem dense disableRipple disableTouchRipple>
              <ListItemIcon>
                {contender?.quizz ? (
                  <DoneIcon htmlColor='var(--srmGreen)' />
                ) : (
                  <NotDoneIcon htmlColor='var(--srmRed)' />
                )}
              </ListItemIcon>
              <ListItemText>Testrennen</ListItemText>
            </MenuItem>
            <MenuItem dense disableRipple disableTouchRipple>
              <ListItemIcon>
                {contender?.quizz ? (
                  <DoneIcon htmlColor='var(--srmGreen)' />
                ) : (
                  <NotDoneIcon htmlColor='var(--srmRed)' />
                )}
              </ListItemIcon>
              <ListItemText>Fahrprüfung</ListItemText>
              <Typography variant='body2' color='text.secondary'>
                x {contender.quizzTry}
              </Typography>
            </MenuItem>
          </MenuList>
        </CardContent>
      </Card>
      <Modal
        isOpen={editContender}
        handleClose={handleCloseEdit}
        title={contender.name}
      >
        <Tags tags={tags} onChange={addTag} onRemove={removeTag} />
        <br />
        <br />
        <form onSubmit={handleUpdate}>
          <FormGroup row>
            <FormControlLabel
              value={isTestRaceDone}
              control={
                <Checkbox
                  color='primary'
                  onChange={() => setIsTestRaceDone(!isTestRaceDone)}
                />
              }
              label='Testrennen absolviert ?'
              labelPlacement='end'
            />
          </FormGroup>
          <br />
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Fahrerart</FormLabel>
            <RadioGroup
              value={driverType}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setDriverType(
                  (event.target as HTMLInputElement).value as DriverType
                )
              }
            >
              <FormControlLabel
                value={DriverType.CONTENDER}
                control={<Radio color='primary' />}
                label='Bewerber'
              />
              <FormControlLabel
                value={DriverType.SUBSTITUTE}
                control={<Radio color='primary' />}
                label='Ersatzfahrer'
              />
              <FormControlLabel
                value={DriverType.REGULAR}
                control={<Radio color='primary' />}
                label='Stammfahrer'
              />
            </RadioGroup>
          </FormControl>
          <br />
          <br />
          {driverType === DriverType.REGULAR && (
            <>
              <NativeSelect
                value={divisionId}
                onChange={(event: any) => setDivisionId(event.target.value)}
                fullWidth
              >
                <option disabled value=''>
                  Wähle eine Liga
                </option>
                {leagueStore.allDivisions.map((d) => {
                  return (
                    <option key={d._id} value={d._id}>
                      {d.name}
                    </option>
                  );
                })}
              </NativeSelect>
              {divisionId && (
                <>
                  <br />
                  <br />
                  <NativeSelect
                    value={newTeam}
                    onChange={(event: any) => setNewTeam(event.target.value)}
                    fullWidth
                  >
                    <option disabled value=''>
                      Wähle ein Team
                    </option>
                    {leagueStore.getAllTeamNames(divisionId).map((t) => {
                      return (
                        <option key={t} value={t}>
                          {t}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </>
              )}
              <br />
              <br />
              {newTeam && (
                <>
                  <TextField
                    type='number'
                    color='secondary'
                    value={carNumber}
                    onChange={(
                      event: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => setCarNumber(parseInt(event.currentTarget.value, 10))}
                    label='Autonummer'
                    fullWidth
                  />
                  <br />
                  <br />
                </>
              )}
            </>
          )}
          <Button
            color='primary'
            variant='contained'
            type='submit'
            fullWidth
            disabled={
              driverType === DriverType.REGULAR && (!divisionId || !newTeam)
            }
          >
            Bewerber aktualisieren
          </Button>
        </form>
        <br />
        <Button
          color='error'
          variant='contained'
          type='submit'
          fullWidth
          onClick={handleRemoveContender}
          disabled
        >
          Bewerber löschen
        </Button>
      </Modal>
    </>
  );
});
