import { Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';
import styled from 'styled-components';

import InfoIcon from '@mui/icons-material/Info';

interface SRMTooltipProps {
  text: string;
  children?: ReactNode;
  size?: 'small' | 'medium' | 'large';
  color?: string;
  icon?: ReactNode;
  disableIcon?: boolean;
}

export const SRMTooltip = ({
  text,
  children,
  icon,
  size = 'small',
  color = 'var(--srmGreyLight)',
  disableIcon = false,
}: SRMTooltipProps) => {
  if (!text) {
    return <>{children}</>;
  }
  return (
    <Tooltip
      title={<Typography variant='body2'>{text}</Typography>}
      leaveDelay={3000}
      leaveTouchDelay={3000}
    >
      <TooltipContent hasGap={Boolean(children)}>
        {children}
        {disableIcon ? null : icon ? (
          icon
        ) : (
          <InfoIcon fontSize={size} htmlColor={color} />
        )}
      </TooltipContent>
    </Tooltip>
  );
};

interface TooltipContentProps {
  hasGap: boolean;
}

const TooltipContent = styled.div<TooltipContentProps>`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  ${(props) => props.hasGap && 'grid-gap: 5px'};
`;
