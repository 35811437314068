import { Fab, SpeedDial, SpeedDialAction } from '@mui/material';
import styled from 'styled-components';
import { FabContainer } from '../FabContainer';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ReactNode, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';

export interface SpeedDialActionProps {
  name: string;
  icon: ReactNode;
  tooltip: string;
  onClick: () => void;
}
interface EditLayoutProps {
  children: React.ReactNode | React.ReactNode[];
  onFilterClick?: () => void;
  enablePadding?: boolean;
  speedDialActions?: SpeedDialActionProps[];
}

export const EditLayout = ({
  children,
  onFilterClick,
  enablePadding = false,
  speedDialActions,
}: EditLayoutProps) => {
  const [isSpeedDialOpen, setIsSpeedDialOpen] = useState<boolean>(false);

  const handleSpeedDialClick = (callback?: () => void) => {
    if (callback) {
      callback();
    }
    setIsSpeedDialOpen(false);
  };

  return (
    <PageContent enablePadding={enablePadding}>
      {children}
      {onFilterClick && !speedDialActions && (
        <FabContainer>
          <Fab color='primary' size='small' onClick={onFilterClick}>
            <FilterAltIcon />
          </Fab>
        </FabContainer>
      )}
      {onFilterClick && speedDialActions && (
        <FabContainer>
          <SpeedDial
            ariaLabel='edit-speed-dial'
            icon={<EditIcon />}
            onClose={() => setIsSpeedDialOpen(false)}
            onOpen={() => setIsSpeedDialOpen(true)}
            open={isSpeedDialOpen}
          >
            <SpeedDialAction
              key='speed-dial-filter'
              icon={<FilterAltIcon />}
              tooltipTitle='Filter & Sortieren'
              tooltipOpen
              onClick={() => handleSpeedDialClick(onFilterClick)}
            />
            {speedDialActions?.map((action) => {
              return (
                <SpeedDialAction
                  key={`speed-dial-${action.name}`}
                  icon={action.icon}
                  tooltipTitle={action.tooltip}
                  tooltipOpen
                  onClick={() => handleSpeedDialClick(action.onClick)}
                />
              );
            })}
          </SpeedDial>
        </FabContainer>
      )}
    </PageContent>
  );
};

interface PageContentProps {
  enablePadding: boolean;
}

const PageContent = styled.div<PageContentProps>`
  overflow-y: auto;
  ${(props) =>
    props.enablePadding && 'padding: var(--srmPadding) var(--srmPadding) 0'};

  .MuiList-padding {
    padding-bottom: 0px;
  }

  .MuiSpeedDial-root {
    width: 40px !important;
  }

  .MuiSpeedDial-fab {
    height: 40px !important;
    width: 40px !important;
  }

  @media only screen and (min-width: 768px) {
    .MuiSpeedDial-fab {
      height: 56px !important;
      width: 56px !important;
    }

    .MuiSpeedDial-root {
      width: 56px !important;
    }
  }
`;
