export enum NAVIGATION_PATH {
  BASE = '/',
  PROFILE = '/profile',
  STANDINGS = '/standings',
  PENALTIES = '/penalties',
  EVENTS = '/events',
  INCIDENTS = '/incidents',
  SETTINGS = '/settings',
  TIMES = '/times',
  NEWS = '/news',
  CREATE = '/create',
  SEARCH = '/search',
  MANAGE = '/manage',
  HOME = '/home',
  RULES = '/rules',
  MANAGE_DRIVERS = '/manage/drivers',
  MANAGE_EVENTS = '/manage/events',
  MANAGE_SETTINGS = '/manage/settings',
  MANAGE_DIVISIONS = '/manage/divisions',
  MANAGE_CONTENDERS = '/manage/contenders',
  MANAGE_INCIDENTS = '/manage/incidents',
}

export const NAVIGATION_TITLE: Record<string, string> = {
  profile: 'Dein Profil',
  standings: 'Standings',
  penalties: 'Strafen',
  events: 'Events',
  incidents: 'Vorfälle',
  settings: 'Einstellungen',
  times: 'Zeiten eintragen',
  news: 'News',
  home: 'Wähle oder Gründe',
  rules: 'Regelwerk',
  search: 'Liga Suchen',
  create: 'Liga Gründen',
  manage: 'Verwalten',
  manage_drivers: 'Verw. - Fahrer',
  manage_events: 'Verw. - Events',
  manage_settings: 'Liga Einstellungen',
  manage_divisions: 'Verw. - Divisionen',
  manage_contenders: 'Verw. - Bewerber',
  manage_incidents: 'Verw. - Vorfälle',
};
