import { makeAutoObservable, computed, runInAction } from 'mobx';
import { STANDINGS_VIEW } from '../pages/user/Standings';
import { isBefore, isAfter, fromUnixTime } from 'date-fns';
import UserModel from './UserModel';
import MessageModal, { MessageType } from './MessageStore';
import { PENALTY_FILTER } from '../pages/user/Penalties';
import _ from 'lodash';
import DivisionModal from './EventModal';

const apiUrl = process.env.REACT_APP_API;

export enum Rights {
  UNAUTHORIZED = 'UNAUTHORIZED',
  AUTHORIZED = 'AUTHORIZED',
  DRIVER = 'DRIVER',
  STEWARD = 'STEWARD',
  ADMIN = 'ADMIN',
}

export enum RacingType {
  F1 = 'F1',
  F2 = 'F2',
}

export enum EventType {
  REGULAR = 'REGULAR',
  SPRINT = 'SPRINT',
}

export enum IncidentUser {
  DIRECTOR = 'DIRECTOR',
  DRIVER = 'DRIVER',
}

export enum DriverType {
  ALL = 'ALL',
  REGULAR = 'REGULAR',
  SUBSTITUTE = 'SUBSTITUTE',
  CONTENDER = 'CONTENDER',
}

export enum DriverRole {
  OWNER = 'OWNER',
  DRIVER = 'DRIVER',
}

export enum IncidentState {
  CREATED = 'CREATED',
  IN_PROCESS = 'IN_PROCESS',
  DONE = 'DONE',
}

export enum IncidentFilter {
  OPEN = 'OPEN',
  ALL = 'ALL',
  TEST_RACE = 'TEST_RACE',
}

export interface ComparisonDriver {
  name: string;
  quali: number;
  race: number;
  wins: number;
  podien: number;
}

export interface ComparisonData {
  driver: ComparisonDriver;
  mate: ComparisonDriver;
}

export interface Rating {
  safety: number; // max 5
  pace: number; // max 5
}

export interface Standing {
  name: string;
  carNumber: number;
  teamName?: string;
  teamPoints?: number;
  drivers?: string[];
  points: number;
  color: string;
  penaltyPoints?: number;
  avgRace?: number;
  avgQuali?: number;
  rating?: Rating;
}

export interface Quali {
  time: number;
  place: number;
}

export interface EventResult {
  place: number;
  points: number;
  name: string;
  team: string;
  time: string;
  hasFastestLap: boolean;
  isDnfOrDsq: boolean;
  quali: Quali | null;
}

export enum CONTENDER_FILTER {
  ALL = 'ALL',
  QUIZZ_DONE = 'QUIZZ',
  TEST_RACE_DONE = 'TEST_RACE_DONE',
}

export interface ContenderState {
  name: string;
  quizz: boolean;
  quizzTry: number;
  testRace: boolean;
}

export interface CreatePenalty {
  driver: string;
  type: string;
  note: string;
  penaltyPoints: number;
}

export interface Penalty {
  track: string;
  driver: string;
  type: string;
  note: string;
  penaltyPoints: number;
  date: number;
}

export interface Author {
  driver: string;
  link: string;
  note: string;
}

export interface Receiver extends Author {
  date: number;
}

export interface Incident_View {
  driver: string;
  link: string;
  note: string;
}

export interface New_Incident {
  divisionId: string;
  track: string;
  note: string;
  view: string;
  receiver: string;
}

export interface Update_Incident_IF {
  _id: string;
  driver: string;
  link: string;
  note: string;
}

export interface Incident_IF {
  _id: string;
  divisionId: string;
  leagueId: string;
  state: IncidentState;
  track: string;
  date: number;
  author: Author;
  receiver: Receiver;
  views: Incident_View[];
  penalty: Penalty | null;
}

export interface Contender {
  name: string;
  testRace: boolean;
  quizz: boolean;
  quizzTry: number; // max 3
  date: number;
}

export interface UpdateContender {
  name: string;
  testRace: boolean;
  quizz: boolean;
  quizzTry: number; // max 3
  driverType: DriverType;
  divisionId: string;
  team: string;
  carNumber: number;
  tags: string[];
}

export interface Quizz_Answers extends Quizz {
  yourAnswerId: number;
}

export interface Quizz {
  questionId: number;
  text: string;
  answers: Answer[];
  correctAnswerId: number;
  correctAnswerText: string;
}

export interface Answer {
  answerId: number;
  text: string;
}

export interface League {
  _id: string;
  incidents: Incident_IF[];
  penalties: Penalty[];
  contenders?: any[];
  owner: string;
  logo: string;
  joinCode?: string;
  shortName: string;
  name: string;
  divisions: DivisionModal[];
  quizz: Quizz[];
}

// export interface LeagueDivision {
//   _id: string;
//   teams: Team[];
//   events: Event[];
//   leagueId: string;
//   racingType: RacingType;
//   console: string;
//   name: string;
//   shortName: string;
//   drivers: DivisionDriver[];
// }

export interface Averages {
  avgRace: number;
  avgQuali: number;
  racesDriven: number;
}

export interface DivisionDriver {
  name: string;
  carNumber: number;
  isActive: boolean;
  role: string;
  driverType: DriverType;
  isAdmin: boolean;
  isSteward: boolean;
  penaltyPoints: number;
  points: number;
  team: Team;
  currentHistory: History;
  allTimeHistory: History;
  rating: Rating;
  divisions: DriverDivision[];
  driverId: string;
  driverLicense: string[];
  averages: Averages;
  memberSince: number;
  tags: string[];
  divisionShortName?: string;
}

export interface Event {
  _id: string;
  track: string;
  countryCode: string;
  date: number;
  cancellations: EventUser[];
  commitments: EventUser[];
  result: EventResult[];
  type: EventType;
  laps: number;
  qualiPace: string;
  highlightLink: string;
  isProvisionalResult: boolean;
}

export interface Event_Calendar extends Event {
  racingType: RacingType;
  divisionId: string;
  divisionShortName: string;
}

export interface EventUser {
  driver: string;
  team: string;
  note?: string;
}

export interface Team {
  color: string;
  racingType: RacingType;
  name: string;
  points: number;
  drivers: string[];
}

export interface DriverDivision {
  driverId: string;
  divisionId: string;
  team: string;
  points: number;
}

export interface UpdateDriver {
  name: string;
  divisions: DriverDivision[];
  driverType: DriverType;
  carNumber: number;
  driverLicense: string[];
  tags: string[];
}

export interface History {
  wins: number;
  podien: number;
  dnf: number;
  points: number;
  races: number;
  poles: number;
  titles: number;
  fastestLaps: number;
}

export interface CancelCommitEvent {
  track: string;
  team?: string;
  date: number;
  note?: string;
  divId?: string;
}

export interface AllDivision {
  name: string;
  shortName: string;
  divisionId: string;
}

interface ApiResponse {
  message: {
    status: number;
    key: string;
  };
  data: any;
}

const defaultHistory = {
  dnf: 0,
  races: 0,
  wins: 0,
  podien: 0,
  points: 0,
  poles: 0,
  fastestLaps: 0,
  titles: 0,
};

class LeagueModel {
  private incidents: Incident_IF[] = [];
  private penalties: Penalty[] = [];
  private contenders?: Contender[] = [];
  private quizz: Quizz[] = [];
  private owner: string = '';
  shortName: string = '';
  private divisions: { [key: string]: DivisionModal } = {};
  allDivisions: DivisionModal[] = [];
  private efDriver: DivisionDriver | null = null;
  private allTeams: Partial<Team>[] = [];
  private substitutes: DivisionDriver[] = [];
  private stewards: string[] = [];
  private admins: string[] = [];

  private user: UserModel;
  private messageStore: MessageModal;

  name: string = '';
  logo: string = '';
  joinCode: string = '';
  currentLeague: string = '';
  currentDivision: string = '';
  currentProfileDivision?: string;
  contenderState?: ContenderState;

  constructor(user: UserModel, messageStore: MessageModal) {
    makeAutoObservable(this);
    this.user = user;
    this.messageStore = messageStore;
  }

  updateContender(contender: UpdateContender) {
    if (this.isSteward() || this.isAdmin()) {
      fetch(`${apiUrl}/leagues/${this.currentLeague}/contenders`, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.user?.token,
        },
        body: JSON.stringify({
          ...contender,
        }),
      })
        .then((response: any) => {
          response.json().then(({ message, data }: ApiResponse) => {
            if (message.status === 200 || message.status === 201) {
              runInAction(() => {
                this.contenders = data.contenders;
                this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
              });
            }

            if (message.status >= 400) {
              this.messageStore.sendMessage(message.key, MessageType.ERROR);
            }
          });
        })
        .catch((e) => {
          this.messageStore.sendMessage('ERROR', MessageType.ERROR);
        });
    } else {
      this.messageStore.sendMessage('NO_RIGHTS_ERROR', MessageType.ERROR);
    }
  }

  async joinLeague(code: string): Promise<boolean> {
    return fetch(`${apiUrl}/leagues/join`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user?.token,
      },
      body: JSON.stringify({
        code,
      }),
    })
      .then((response: any) => {
        return response
          .json()
          .then(({ message, data }: ApiResponse) => {
            if (message.status === 200 || message.status === 201) {
              runInAction(() => {
                Object.assign(this, { ...data.league, divisions: [] });
                this.currentLeague = data.league._id;
              });

              const allDivs: DivisionModal[] = [];
              data.league.divisions.forEach((div: DivisionModal) => {
                div.events.sort((e1: Event, e2: Event) => e1.date - e2.date);
                div.teams.sort((t1: Team, t2: Team) => t2.points - t1.points);
                div.drivers.sort(
                  (d1: DivisionDriver, d2: DivisionDriver) =>
                    d2.points - d1.points
                );

                runInAction(() => {
                  this.divisions[div._id] = div;
                });

                allDivs.push(div);
              });

              runInAction(() => {
                this.allDivisions = allDivs;
                this.user.rightsRole = Rights.DRIVER;

                if (data.league.baseDivision) {
                  this.currentDivision = data.league.baseDivision ?? null;
                  this.currentProfileDivision = data.league.baseDivision;
                } else {
                  this.currentDivision = data.league.divisions[0]?._id;
                }
              });

              this.user.addNewLeague({
                name: data.league.name,
                leagueId: data.league._id,
                logo: data.league.logo,
              });

              this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
              return Promise.resolve(true);
            }

            if (message.status >= 400) {
              this.messageStore.sendMessage(message.key, MessageType.ERROR);
              Promise.reject(false);
            }
          })
          .catch(() => {
            this.messageStore.sendMessage('ERROR', MessageType.ERROR);
            Promise.reject(false);
          });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
        return Promise.reject(false);
      });
  }

  getLeagueById(id: string) {
    fetch(`${apiUrl}/leagues/${id}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user?.token,
      },
    })
      .then((response: any) => {
        response.json().then(({ message, data }: ApiResponse) => {
          if (message.status === 200 || message.status === 201) {
            runInAction(() => {
              Object.assign(this, { ...data.league, divisions: [] });
              this.currentLeague = data.league._id;
            });

            const allDivs: DivisionModal[] = [];
            data.league.divisions.forEach((div: DivisionModal) => {
              div.events.sort((e1: Event, e2: Event) => e1.date - e2.date);
              div.teams.sort((t1: Team, t2: Team) => t2.points - t1.points);
              div.drivers.sort(
                (d1: DivisionDriver, d2: DivisionDriver) =>
                  d2.points - d1.points
              );

              runInAction(() => {
                this.divisions[div._id] = div;
              });

              allDivs.push(div);
            });

            runInAction(() => {
              this.allDivisions = allDivs;
              this.user.rightsRole = data.league.admins.includes(
                this.user.displayName
              )
                ? Rights.ADMIN
                : data.league.stewards.includes(this.user.displayName)
                ? Rights.STEWARD
                : Rights.DRIVER;

              if (data.league.baseDivision) {
                this.currentDivision = data.league.baseDivision ?? null;
                this.currentProfileDivision = data.league.baseDivision;
              } else {
                this.currentDivision = data.league.divisions[0]._id;
              }
            });
          }

          // Unauthorized
          if (message.status === 401) {
            this.user.logout();
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  }

  cancelEvent(eventData: CancelCommitEvent) {
    const team = this.isDriverInDivision()
      ? this.getTeam.name
      : this.getTeamInCommitments(eventData.track, eventData.date);

    const divisionId = eventData.divId || this.currentDivision;

    if (team !== undefined) {
      fetch(`${apiUrl}/divisions/${divisionId}/cancel-event`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.user?.token,
        },
        body: JSON.stringify({
          ...eventData,
          team,
          driverName: this.user?.displayName,
        }),
      })
        .then((response: any) => {
          response.json().then(({ message, data }: ApiResponse) => {
            if (message.status === 200 || message.status === 201) {
              runInAction(() => {
                this.divisions[divisionId].events = data.events;
                this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
              });
            }

            if (message.status >= 400) {
              this.messageStore.sendMessage(message.key, MessageType.ERROR);
            }
          });
        })
        .catch((e) => {
          this.messageStore.sendMessage('ERROR', MessageType.ERROR);
        });
    }
  }

  commitEvent(eventData: CancelCommitEvent) {
    const newEventData = { ...eventData };
    const divisionId = eventData.divId || this.currentDivision;

    if (this.isRegularDriver) {
      newEventData.team = this.getTeam.name;
    }

    fetch(`${apiUrl}/divisions/${divisionId}/commit-event`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user?.token,
      },
      body: JSON.stringify({
        ...newEventData,
        driverName: this.user?.displayName,
      }),
    })
      .then((response: any) => {
        response.json().then(({ message, data }: ApiResponse) => {
          if (message.status === 200 || message.status === 201) {
            runInAction(() => {
              this.divisions[divisionId].events = data.events;
              this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
            });
          }

          if (message.status >= 400) {
            this.messageStore.sendMessage(message.key, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  }

  createIncident(incident: New_Incident, user: IncidentUser) {
    fetch(`${apiUrl}/leagues/${this.currentLeague}/incidents`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user?.token,
      },
      body: JSON.stringify({
        ...incident,
        author:
          user === IncidentUser.DIRECTOR
            ? 'Rennleitung'
            : this.user?.displayName,
      }),
    })
      .then((response: any) => {
        response.json().then(({ message, data }: ApiResponse) => {
          if (message.status === 200 || message.status === 201) {
            runInAction(() => {
              this.incidents = [...this.incidents, data.incident];
              this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
            });
          }

          if (message.status >= 400) {
            this.messageStore.sendMessage(message.key, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  }

  createPenalty(incident: Incident_IF, penalty: CreatePenalty) {
    fetch(
      `${apiUrl}/leagues/${this.currentLeague}/incidents/${incident._id}/penalty`,
      {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.user?.token,
        },
        body: JSON.stringify({
          ...penalty,
        }),
      }
    )
      .then((response: any) => {
        response.json().then(({ message, data }: ApiResponse) => {
          if (message.status === 200 || message.status === 201) {
            runInAction(() => {
              const incidentIndex = this.incidents.findIndex(
                (inc) => inc._id === data.incident._id
              );
              if (incidentIndex >= 0) {
                this.incidents[incidentIndex] = data.incident;
              }
              this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
            });
          }

          if (message.status >= 400) {
            this.messageStore.sendMessage(message.key, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  }

  uploadLogo(file: any) {
    const formData = new FormData();
    formData.append('logo', file);
    fetch(`${apiUrl}/leagues/${this.currentLeague}/logo`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Authorization: 'Bearer ' + this.user?.token,
      },
      body: formData,
    })
      .then((response) => {
        response.json().then((res) => {
          this.logo = res.image;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  updateIncident(incident: Update_Incident_IF) {
    fetch(`${apiUrl}/leagues/${this.currentLeague}/incidents/${incident._id}`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user?.token,
      },
      body: JSON.stringify({
        ...incident,
      }),
    })
      .then((response: any) => {
        response.json().then(({ message, data }: ApiResponse) => {
          if (message.status === 200 || message.status === 201) {
            runInAction(() => {
              const incidentIndex = this.incidents.findIndex(
                (inc) => inc._id === data.incident._id
              );
              if (incidentIndex >= 0) {
                this.incidents[incidentIndex] = data.incident;
              }
              this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
            });
          }

          if (message.status >= 400) {
            this.messageStore.sendMessage(message.key, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  }

  setDriverInactive(driverId: string, name: string) {
    fetch(`${apiUrl}/leagues/${this.currentLeague}/inactive-driver`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user?.token,
      },
      body: JSON.stringify({
        driverId,
        name,
      }),
    })
      .then((response: any) => {
        response.json().then(({ message, data }: ApiResponse) => {
          if (message.status === 200 || message.status === 201) {
            const allDivs: DivisionModal[] = [];

            data.divisions.forEach((div: DivisionModal) => {
              div.events.sort((e1: Event, e2: Event) => e1.date - e2.date);
              div.teams.sort((t1: Team, t2: Team) => t2.points - t1.points);
              div.drivers.sort(
                (d1: DivisionDriver, d2: DivisionDriver) =>
                  d2.points - d1.points
              );

              runInAction(() => {
                this.divisions[div._id] = div;
              });

              allDivs.push(div);
            });

            runInAction(() => {
              this.allDivisions = allDivs;
              this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
            });
          }

          if (message.status >= 400) {
            this.messageStore.sendMessage(message.key, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  }

  updateDriver = (driver: UpdateDriver) => {
    fetch(`${apiUrl}/leagues/${this.currentLeague}/update-driver`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user?.token,
      },
      body: JSON.stringify({
        ...driver,
      }),
    })
      .then((response: any) => {
        response.json().then(({ message, data }: ApiResponse) => {
          if (message.status === 200 || message.status === 201) {
            const allDivs: DivisionModal[] = [];

            data.divisions.forEach((div: DivisionModal) => {
              div.events.sort((e1: Event, e2: Event) => e1.date - e2.date);
              div.teams.sort((t1: Team, t2: Team) => t2.points - t1.points);
              div.drivers.sort(
                (d1: DivisionDriver, d2: DivisionDriver) =>
                  d2.points - d1.points
              );

              runInAction(() => {
                this.divisions[div._id] = div;
              });

              allDivs.push(div);
            });

            runInAction(() => {
              this.allDivisions = allDivs;
              this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
            });
          }

          if (message.status >= 400) {
            this.messageStore.sendMessage(message.key, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  };

  getStandingsByView(
    view: STANDINGS_VIEW,
    division = this.currentDivision
  ): Standing[] {
    const standingsTable: Standing[] = [];

    if (this.divisions && division) {
      if (view === STANDINGS_VIEW.DRIVER) {
        this.divisions[division].drivers.forEach((d) => {
          standingsTable.push({
            name: d.name,
            teamName: d.team.name,
            teamPoints: d.team.points,
            drivers: d.team.drivers,
            points: d.points,
            color: d.team.color,
            carNumber: d.carNumber,
            penaltyPoints: d.penaltyPoints,
            rating: d.rating,
          });
        });
      }

      if (view === STANDINGS_VIEW.TEAM) {
        this.divisions[division].teams.forEach((t) => {
          standingsTable.push({
            name: t.name,
            teamName: t.name,
            teamPoints: t.points,
            drivers: t.drivers,
            points: t.points,
            color: t.color,
            carNumber: 0,
          });
        });
      }
      if (view === STANDINGS_VIEW.AVERAGE) {
        this.divisions[division].drivers.forEach((d) => {
          standingsTable.push({
            name: d.name,
            teamName: d.team.name,
            teamPoints: d.team.points,
            drivers: d.team.drivers,
            points: d.points,
            color: d.team.color,
            carNumber: d.carNumber,
            penaltyPoints: d.penaltyPoints,
            avgRace: d.averages.avgRace,
            avgQuali: d.averages.avgQuali,
          });
        });
      }

      standingsTable.sort(
        (st1: Standing, st2: Standing) => st2.points - st1.points
      );
    }

    return standingsTable;
  }

  refreshData() {
    this.getLeagueById(this.currentLeague);
  }

  getLastEvents(
    count = 1,
    divisionId = this.currentDivision
  ): Event_Calendar[] {
    if (this.divisions && divisionId) {
      const division = this.divisions[divisionId];
      const events: Event[] = division?.events.filter(
        (e) => isBefore(fromUnixTime(e.date), new Date()) && e.result
      );

      if (events) {
        events?.sort((e1: Event, e2: Event) => e2.date - e1.date);

        return events
          .slice(0, count)
          .map((e) => {
            return {
              ...e,
              racingType: division.racingType,
              divisionId,
              divisionShortName: division.shortName,
            };
          })
          .sort((e1: Event, e2: Event) => e1.date - e2.date);
      }

      return [];
    }

    return [];
  }

  getNextEvents(
    count = 1,
    divisionId = this.currentDivision
  ): Event_Calendar[] {
    if (this.divisions && divisionId) {
      const division = this.divisions[divisionId];
      const events: Event[] = division?.events.filter(
        (e) =>
          isAfter(fromUnixTime(e.date), new Date()) &&
          (!e.result || e.result?.length === 0)
      );

      if (events) {
        count = count > events.length ? events.length : count;

        return events.slice(0, count).map((e) => {
          return {
            ...e,
            racingType: division.racingType,
            divisionId,
            divisionShortName: division.shortName,
          };
        });
      }

      return [];
    }

    return [];
  }

  getTeamPointsFromEvent(team: string, track: string, date: number) {
    let teamPoints: number = 0;

    if (this.divisions && this.currentDivision && team) {
      const event = this.divisions[this.currentDivision].events.find(
        (e) => e.track === track && e.date === date
      );

      event?.result?.forEach((r) => {
        if (r.team === team) {
          teamPoints += r.points;
        }
      });
    }

    return teamPoints;
  }

  removeEvent(divisionId: string, eventId: string) {
    if (this.isAdmin()) {
      fetch(`${apiUrl}/divisions/${divisionId}/events/${eventId}`, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.user?.token,
        },
      })
        .then((response: any) => {
          response.json().then(({ message, data }: ApiResponse) => {
            if (message.status === 200 || message.status === 201) {
              runInAction(() => {
                const eventIndex = this.divisions[
                  data.divisionId
                ].events.findIndex((e) => e._id === data.eventId);

                this.divisions[data.divisionId].events.splice(eventIndex, 1);
              });
              this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
            }
            // runInAction(() => {});
            if (message.status >= 400) {
              this.messageStore.sendMessage(message.key, MessageType.ERROR);
            }
          });
        })
        .catch((e) => {
          this.messageStore.sendMessage('ERROR', MessageType.ERROR);
        });
    } else {
      this.messageStore.sendMessage('NO_RIGHTS', MessageType.ERROR);
    }
  }

  updateEvent(divisionId: string, event: Partial<Event>) {
    fetch(`${apiUrl}/leagues/${this.currentLeague}/${divisionId}/events`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user?.token,
      },
      body: JSON.stringify({
        event: {
          ...event,
          racingType: this.getLeagueDivision(divisionId)?.racingType,
        },
      }),
    })
      .then((response: any) => {
        response.json().then(({ message, data }: ApiResponse) => {
          if (message.status === 200 || message.status === 201) {
            runInAction(() => {
              const eventIndex = this.divisions[divisionId].events.findIndex(
                (e) => e._id === data._id
              );

              if (eventIndex >= 0) {
                this.divisions[divisionId].events[eventIndex].result =
                  data.result;
                this.divisions[divisionId].events[eventIndex].date = data.date;
                this.divisions[divisionId].events[eventIndex].highlightLink =
                  data.highlightLink;
                this.divisions[divisionId].events[
                  eventIndex
                ].isProvisionalResult = data.isProvisionalResult;
              }

              this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
            });
          }

          if (message.status >= 400) {
            this.messageStore.sendMessage(message.key, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  }

  updateQuizz(isQuizzPassed: boolean) {
    if (this.contenderState) {
      fetch(`${apiUrl}/leagues/${this.currentLeague}/contenders`, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.user?.token,
        },
        body: JSON.stringify({
          name: this.user.displayName,
          quizz: isQuizzPassed,
          quizzTry: this.contenderState.quizzTry + 1,
          testRace: this.contenderState.testRace,
          driverType: DriverType.CONTENDER,
        }),
      })
        .then((response: any) => {
          response.json().then(({ message, data }: ApiResponse) => {
            if (message.status === 200 || message.status === 201) {
              runInAction(() => {
                if (this.contenderState) {
                  const contender = data.contenders.find(
                    (c: Contender) => c.name === this.user.displayName
                  );
                  this.contenderState.quizz = contender.quizz;
                  this.contenderState.quizzTry = contender.quizzTry;
                }

                if (this.contenders) {
                  this.contenders = data.contenders;
                }

                this.messageStore.sendMessage(message.key, MessageType.INFO);
              });
            }

            if (message.status >= 400) {
              this.messageStore.sendMessage(message.key, MessageType.ERROR);
            }
          });
        })
        .catch((e) => {
          this.messageStore.sendMessage('ERROR', MessageType.ERROR);
        });
    } else {
      if (this.driver?.driverType === DriverType.CONTENDER) {
        this.messageStore.sendMessage('UPDATE_QUIZZ_ERROR', MessageType.ERROR);
      }
    }
  }

  setCurrentDivision(divisionId: string) {
    this.currentDivision = divisionId;

    if (this.isDriverInDivision(this.user?.displayName, divisionId)) {
      this.currentProfileDivision = divisionId;
    }
  }

  setCurrentProfileDivision(divisionId: string) {
    this.currentProfileDivision = divisionId;
  }

  setCurrentLeague(leagueId: string) {
    this.currentLeague = leagueId;
  }

  getTeamColor(team: string): string {
    return (
      this.allTeams.find((t) => t.name === team)?.color || 'var(--srmGreen)'
    );
  }

  getTeamInCommitments(track: string, date: number): string | undefined {
    const event = this.divisions[this.currentDivision].events.find(
      (e) => e.track === track && e.date === date
    );

    return event?.commitments.find((c) => c.driver === this.user?.displayName)
      ?.team;
  }

  getAllTeamNames(
    divisionId = this.currentDivision,
    hasFreeSeat = false,
    exception?: string[]
  ): string[] {
    let teams = _.cloneDeep(this.divisions[divisionId].teams);
    let teamNames: string[] = [];

    if (hasFreeSeat && exception) {
      teamNames = teams
        .filter(
          (t) =>
            t.drivers === undefined ||
            t.drivers?.length < 2 ||
            exception.includes(t.name)
        )
        .map((t) => t.name);
    } else if (hasFreeSeat) {
      teamNames = teams
        .filter((t) => t.drivers === undefined || t.drivers.length < 2)
        .map((t) => t.name);
    } else {
      teamNames = teams.map((t) => t.name);
    }

    return teamNames;
  }

  getRacesDrivenInByDriver(driver: string, divisionId?: string): number {
    const events = this.getEventsWithResult(divisionId);

    return events.filter((event) => event.result.find((r) => r.name === driver))
      ?.length;
  }

  getCancellationsCountByDriver(driver: string, divisionId?: string): number {
    const events = this.getEventsWithResult(divisionId);

    return events.filter((ev) =>
      ev.cancellations.find((c) => c.driver === driver)
    )?.length;
  }

  getEventsWithResult(divisionId?: string): Event_Calendar[] {
    return this.getAllEvents(divisionId).filter(
      (event) => event.result && event.result?.length > 0
    );
  }

  getRacesWithoutCancellation(driver: string, divisionId?: string): number {
    const events = this.getEventsWithResult(divisionId);

    return (
      events?.length -
      this.getRacesDrivenInByDriver(driver, divisionId) -
      this.getCancellationsCountByDriver(driver, divisionId)
    );
  }

  getAllEvents(divisionId?: string): Event_Calendar[] {
    if (this.divisions) {
      const events: Event_Calendar[] = [];

      if (divisionId) {
        const division = this.divisions[divisionId];
        events.push(
          ..._.cloneDeep(division.events).map((e) => {
            return {
              ...e,
              racingType: division.racingType,
              divisionId: division._id,
              divisionShortName: division.shortName,
            };
          })
        );
      } else {
        this.allDivisions.forEach((div) => {
          events.push(
            ..._.cloneDeep(this.divisions[div._id].events).map((e) => {
              return {
                ...e,
                racingType: div.racingType,
                divisionId: div._id,
                divisionShortName: div.shortName,
              };
            })
          );
        });
      }

      return events.sort((e1: Event, e2: Event) => e1.date - e2.date);
    }

    return [];
  }

  getHistoryByDriver(driverName: string): History {
    if (this.divisions && this.currentDivision) {
      const driver = this.divisions[this.currentDivision].drivers.find(
        (d) => d.name === driverName
      );

      if (driver) {
        return driver?.allTimeHistory;
      }
    }

    return defaultHistory;
  }

  getRatingByDriver(driverName: string): Rating {
    if (this.divisions && this.currentDivision) {
      const driver = this.divisions[this.currentDivision].drivers.find(
        (d) => d.name === driverName
      );

      if (driver) {
        return driver?.rating;
      }
    }

    return {
      safety: 0,
      pace: 0,
    };
  }

  isDriverInDivision(
    driver = this.user?.displayName,
    division = this.currentDivision
  ): boolean {
    const newDriver = this.divisions[division].drivers.find(
      (d) => d.name === driver
    );

    return Boolean(newDriver);
  }

  getDriverByName(driver: string): DivisionDriver | null {
    const dr = this.getAllDriver().find((dr) => dr.name === driver);

    if (dr) {
      return dr;
    }

    return null;
  }

  getPenalties(filter = PENALTY_FILTER.ALL, track = 'ALL'): Incident_IF[] {
    if (this.divisions && this.currentDivision) {
      const user = this.user.displayName;
      const incidents = _.cloneDeep(this.incidents);

      if (filter === PENALTY_FILTER.ALL && track === 'ALL') {
        return incidents
          .filter(
            (inc) => inc.penalty && inc.divisionId === this.currentDivision
          )
          .sort((inc1: Incident_IF, inc2: Incident_IF) => {
            if (inc1.penalty && inc2.penalty) {
              return inc2.penalty?.date - inc1.penalty?.date;
            } else {
              return inc2.date - inc1.date;
            }
          });
      }

      if (filter === PENALTY_FILTER.ALL) {
        return incidents
          .filter(
            (inc) =>
              inc.penalty &&
              inc.divisionId === this.currentDivision &&
              inc.track === track
          )
          .sort((inc1: Incident_IF, inc2: Incident_IF) => {
            if (inc1.penalty && inc2.penalty) {
              return inc2.penalty?.date - inc1.penalty?.date;
            } else {
              return inc2.date - inc1.date;
            }
          });
      }

      if (filter === PENALTY_FILTER.SELF && track === 'ALL') {
        return incidents
          .filter(
            (inc) =>
              inc.penalty &&
              inc.divisionId === this.currentDivision &&
              inc.penalty.driver === user
          )
          .sort((inc1: Incident_IF, inc2: Incident_IF) => {
            if (inc1.penalty && inc2.penalty) {
              return inc2.penalty?.date - inc1.penalty?.date;
            } else {
              return inc2.date - inc1.date;
            }
          });
      }

      if (filter === PENALTY_FILTER.SELF) {
        return incidents
          .filter(
            (inc) =>
              inc.penalty &&
              inc.divisionId === this.currentDivision &&
              inc.penalty.driver === user &&
              inc.track === track
          )
          .sort((inc1: Incident_IF, inc2: Incident_IF) => {
            if (inc1.penalty && inc2.penalty) {
              return inc2.penalty?.date - inc1.penalty?.date;
            } else {
              return inc2.date - inc1.date;
            }
          });
      }
    }
    return [];
  }

  getIncidentsById(): Incident_IF[] {
    return _.cloneDeep(
      this.incidents.filter((inc) => inc.state !== IncidentState.DONE)
    );
  }

  getDivisionNameById(id: string): string | null {
    if (this.divisions) {
      if (this.divisions[id]) {
        return this.divisions[id].name;
      }
    }
    return null;
  }

  getDivisionShortNameById(id: string): string | null {
    if (this.divisions) {
      if (this.divisions[id]) {
        return this.divisions[id].shortName;
      }
    }
    return null;
  }

  getContenders(): Contender[] {
    return _.cloneDeep(this.contenders) || [];
  }

  isSteward(driver = this.user?.displayName || ''): boolean {
    return this.stewards.includes(driver);
  }

  isAdmin(driver = this.user?.displayName || ''): boolean {
    return this.admins.includes(driver);
  }

  @computed
  get isRegularDriver(): boolean {
    return this.driver?.driverType === DriverType.REGULAR;
  }

  @computed
  get getProfileDivision(): DivisionModal | null {
    if (this.divisions && this.currentProfileDivision) {
      return { ...this.divisions[this.currentProfileDivision] };
    }

    return null;
  }

  @computed
  get ownerId(): string {
    return this.owner;
  }

  @computed
  get getAdmins(): string[] {
    return this.admins.slice();
  }

  @computed
  get getStewards(): string[] {
    return this.stewards.slice();
  }

  getLeagueDivision(divisionId = this.currentDivision): DivisionModal | null {
    if (this.divisions && divisionId) {
      return { ...this.divisions[divisionId] };
    }

    return null;
  }

  getAllDriverDivisions(name = this.user?.displayName): DivisionModal[] {
    const allDivisions = _.cloneDeep(this.allDivisions);

    if (name) {
      return allDivisions
        .filter((d) => d.drivers.find((dr) => dr.name === name))
        .sort((d1: DivisionModal, d2: DivisionModal) =>
          d1.name.localeCompare(d2.name)
        );
    } else {
      return [];
    }
  }

  getAllDriver(): DivisionDriver[] {
    const substitutes: DivisionDriver[] = this.substitutes.slice();
    const drivers: DivisionDriver[] = [];
    const allDivisions = this.allDivisions.slice();

    const tempDrivers: DivisionDriver[] = [];

    allDivisions.forEach((d) => {
      d.drivers.forEach((dr) => {
        tempDrivers.push({ ...dr, divisionShortName: d.shortName });
      });
    });

    tempDrivers.forEach((dr) => {
      const driver = drivers.find((driver) => driver.driverId === dr.driverId);

      if (!driver) {
        drivers.push(dr);
      }
    });

    substitutes.forEach((s) => {
      drivers.push({
        ...s,
        divisionShortName: 'Ersatzfahrer',
      });
    });

    

    
    return _.uniqBy(drivers, 'driverId').sort((dr1: DivisionDriver, dr2: DivisionDriver) =>
      dr1.name.localeCompare(dr2.name)
    );
  }

  @computed
  get getAllDivisions(): DivisionModal[] {
    const allDivisions = _.cloneDeep(this.allDivisions);

    return allDivisions.sort((d1: DivisionModal, d2: DivisionModal) =>
      d1.name.localeCompare(d2.name)
    );
  }

  @computed
  get getTeam(): Team {
    if (this.divisions && this.currentProfileDivision) {
      const driverInDivision = this.divisions[
        this.currentProfileDivision
      ].drivers.find((dr) => dr.name === this.user?.displayName);

      if (driverInDivision) {
        const team = this.divisions[this.currentProfileDivision].teams.find(
          (t) => t.name === driverInDivision.team.name
        );

        if (team) {
          return team;
        }
      }
    }

    return {
      color: 'var(--srmGreen)',
      racingType: RacingType.F1,
      points: 0,
      name: 'EF Team',
      drivers: [],
    };
  }

  getIncidentsByDriverName(name: string): Incident_IF[] {
    return this.incidents
      .filter((i) => i.penalty && i.penalty.driver === name)
      .sort((inc1: Incident_IF, inc2: Incident_IF) => {
        if (inc1.penalty && inc2.penalty) {
          return inc1.penalty?.date - inc2.penalty?.date;
        } else {
          return 0;
        }
      });
  }

  @computed
  get getIncidents(): Incident_IF[] {
    return this.incidents.filter(
      (i) =>
        (i.author.driver === this.user?.displayName ||
          i.receiver.driver === this.user?.displayName) &&
        i.state !== IncidentState.DONE
    );
  }

  @computed
  get getIncidentsAgainstMe(): Incident_IF[] {
    return _.cloneDeep(this.incidents).filter(
      (i) =>
        i.receiver.driver === this.user?.displayName &&
        i.state === IncidentState.CREATED
    );
  }

  @computed
  get lastRacePenalties(): Penalty[] {
    const lastRace = this.getLastEvents(1)[0];
    const penalties: Penalty[] = [];

    if (lastRace) {
      return _.cloneDeep(this.penalties).filter(
        (p) => p.driver === this.user?.displayName && p.track === lastRace.track
      );
    }

    return penalties;
  }

  @computed
  get driver(): DivisionDriver | undefined {
    if (this.efDriver) {
      return _.cloneDeep(this.efDriver);
    } else if (this.divisions && this.currentProfileDivision) {
      return _.cloneDeep(
        this.divisions[this.currentProfileDivision]?.drivers.find(
          (dr) => dr.name === this.user?.displayName
        )
      );
    } else {
      return undefined;
    }
  }

  @computed
  get teamMate(): string {
    return (
      this.driver?.team.drivers.find((dr) => dr !== this.user.displayName) ?? ''
    );
  }

  @computed
  get newsDivisions(): DivisionModal[] {
    return this.getAllDivisions;
  }

  currentForm(numberOfRaces: number): any {
    let races: any[] = [];
    let quali: any[] = [];

    this.getLastEvents(numberOfRaces, this.currentProfileDivision).map(
      (e, i) => {
        const result = e.result?.find((r) => r.name === this.user?.displayName);

        races.push({
          x: i + 1,
          y: result ? result.place : null,
        });

        quali.push({
          x: i + 1,
          y: result && result.quali ? result.quali.place : null,
        });
      }
    );

    return [
      {
        id: 'race',
        data: races,
      },
      {
        id: 'quali',
        data: quali,
      },
    ];
  }

  getQuizz(): Quizz[] {
    const specialQuestion = _.cloneDeep(this.quizz).find(
      (q) => q.questionId === 101
    );

    const allOtherQuestions = _.cloneDeep(this.quizz).filter(
      (q) => q.questionId !== 101
    );

    const quizz: Quizz[] = [];

    new Array(10).fill(null).forEach(() => {
      const random = Math.floor(Math.random() * allOtherQuestions.length);

      if (allOtherQuestions[random]) {
        const newQuestion = allOtherQuestions[random];
        allOtherQuestions.splice(random, 1);
        quizz.push(newQuestion);
      }
    });

    if (quizz && specialQuestion) {
      return [...quizz, specialQuestion];
    }
    return [];
  }

  comparisonData(
    driver1 = this.user.displayName,
    driver2 = this.teamMate
  ): ComparisonData {
    const user = this.getDriverByName(driver1);
    const mate = this.getDriverByName(driver2);

    // check for same division

    const events = this.getLastEvents(99, this.currentProfileDivision);

    let driverRaces = 0;
    let driverQuali = 0;
    let driverWins = 0;
    let driverPodien = 0;

    let mateRaces = 0;
    let mateQuali = 0;
    let mateWins = 0;
    let matePodien = 0;

    if (user && mate) {
      let tempDriver: EventResult | null = null;
      let tempMate: EventResult | null = null;
      events.forEach((ev) => {
        const results = ev.result;

        results?.forEach((r) => {
          if (r.name === user?.name) {
            tempDriver = r;
          }
          if (r.name === mate?.name) {
            tempMate = r;
          }
        });

        if (
          results &&
          tempDriver &&
          tempMate &&
          tempDriver.quali &&
          tempMate.quali
        ) {
          if (tempDriver.place > tempMate.place) {
            mateRaces++;
          } else {
            driverRaces++;
          }
          if (tempDriver.quali?.place > tempMate.quali?.place) {
            mateQuali++;
          } else {
            driverQuali++;
          }

          if (tempDriver.place <= 3) {
            driverPodien++;
          }
          if (tempDriver.place === 1) {
            driverWins++;
          }
          if (tempMate.place <= 3) {
            matePodien++;
          }
          if (tempMate.place === 1) {
            mateWins++;
          }
        }

        tempDriver = null;
        tempMate = null;
      });
    }

    return {
      driver: {
        name: user?.name || '',
        race: driverRaces,
        quali: driverQuali,
        wins: driverWins,
        podien: driverPodien,
      },
      mate: {
        name: mate?.name || '',
        race: mateRaces,
        quali: mateQuali,
        wins: mateWins,
        podien: matePodien,
      },
    };
  }

  addNewSteward(driver: string) {
    fetch(`${apiUrl}/leagues/${this.currentLeague}/add-steward`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user?.token,
      },
      body: JSON.stringify({
        driver,
      }),
    })
      .then((response: any) => {
        response.json().then(({ message, data }: ApiResponse) => {
          if (message.status === 200 || message.status === 201) {
            runInAction(() => {
              this.stewards = data.stewards;
            });
            this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
          }

          if (message.status >= 400) {
            this.messageStore.sendMessage(message.key, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  }

  removeSteward(driver: string) {
    fetch(`${apiUrl}/leagues/${this.currentLeague}/remove-steward`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user?.token,
      },
      body: JSON.stringify({
        driver,
      }),
    })
      .then((response: any) => {
        response.json().then(({ message, data }: ApiResponse) => {
          if (message.status === 200 || message.status === 201) {
            runInAction(() => {
              this.stewards = data.stewards;
            });
            this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
          }

          if (message.status >= 400) {
            this.messageStore.sendMessage(message.key, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  }

  addNewAdmin(driver: string) {
    fetch(`${apiUrl}/leagues/${this.currentLeague}/add-admin`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user?.token,
      },
      body: JSON.stringify({
        driver,
      }),
    })
      .then((response: any) => {
        response.json().then(({ message, data }: ApiResponse) => {
          if (message.status === 200 || message.status === 201) {
            runInAction(() => {
              this.admins = data.admins;
            });
            this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
          }

          if (message.status >= 400) {
            this.messageStore.sendMessage(message.key, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  }

  removeAdmin(driver: string) {
    fetch(`${apiUrl}/leagues/${this.currentLeague}/remove-admin`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.user?.token,
      },
      body: JSON.stringify({
        driver,
      }),
    })
      .then((response: any) => {
        response.json().then(({ message, data }: ApiResponse) => {
          if (message.status === 200 || message.status === 201) {
            runInAction(() => {
              this.admins = data.admins;
            });
            this.messageStore.sendMessage(message.key, MessageType.SUCCESS);
          }

          if (message.status >= 400) {
            this.messageStore.sendMessage(message.key, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  }
}

export default LeagueModel;
