import * as React from 'react';
import styled from 'styled-components';
import { Modal as StyledModal, Typography, Avatar, Zoom } from '@mui/material';
import { COUNTRY_FLAG_API_BIG } from '../../App';

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  background?: string;
  backgroundColor?: string;
  title?: string;
  subtitle?: string;
  noPadding?: boolean;
  flag?: string;
  textColor?: string;
}

export const CenterModal = ({
  isOpen,
  handleClose,
  children,
  background,
  backgroundColor,
  title,
  subtitle = '',
  noPadding = false,
  flag,
  textColor,
}: ModalProps) => {
  const onClose = (event: any) => {
    if (event.target.id === 'modalBackground') {
      handleClose();
    }
  };

  return (
    <StyledModal open={isOpen} onClose={onClose}>
      <div
        id='modalBackground'
        className='modal'
        style={{
          backgroundColor: 'rgba(0,0,0,0.7)',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          outline: 'none',
        }}
        onClick={onClose}
      >
        <Zoom in mountOnEnter unmountOnExit>
          {/* TODO: rework as Card + CardContent */}
          <ModalContent
            className='modal'
            background={background}
            backgroundColor={backgroundColor}
            textColor={textColor}
            noPadding={noPadding}
          >
            {title && (
              <div>
                <TitleItems>
                  <Typography variant='h5'>{title}</Typography>
                  {flag && (
                    <Avatar
                      variant='square'
                      src={`${COUNTRY_FLAG_API_BIG}/${flag.toLowerCase()}.png`}
                    />
                  )}
                </TitleItems>
                <Typography variant='subtitle1'>{subtitle}</Typography>
              </div>
            )}

            {children}
          </ModalContent>
        </Zoom>
      </div>
    </StyledModal>
  );
};

interface ModalContentProps {
  background?: string;
  backgroundColor?: string;
  textColor?: string;
  noPadding?: boolean;
}

const ModalContent = styled.div<ModalContentProps>`
  min-width: 30%;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
  position: absolute;
  padding: ${(props) => (props.noPadding ? '0 0 15px' : '15px')};
  ${(props) =>
    props.backgroundColor
      ? `background-color: ${props.backgroundColor}`
      : 'background-color: #fff'};
  ${(props) => (props.textColor ? `color: ${props.textColor}` : 'color: #000')};
  border-radius: 5px;

  @media only screen and (min-width: 768px) {
    max-width: 60%;
  }
  @media only screen and (min-width: 1024px) {
    max-width: 50%;
  }
`;

const TitleItems = styled.div`
  display: flex;
  align-items: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    flex: 1;
  }
`;
