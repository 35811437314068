import _ from 'lodash';
import { computed, makeAutoObservable, runInAction } from 'mobx';
import { create, persist } from 'mobx-persist';
import { Rights } from './LeagueModel';
import MessageModal, { MessageType } from './MessageStore';

const apiUrl = process.env.REACT_APP_API;

export interface UserLeague {
  leagueId: string;
  name: string;
  logo: string;
}

export interface Settings {
  hasMessageId: boolean;
}

export interface User {
  displayName: string;
  loginName: string;
  token: string;
  leagues: UserLeague[];
  settings: Settings;
}

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

class UserModel {
  @persist displayName: string = '';
  @persist loginName: string = '';
  @persist token: string = '';
  @persist rightsRole: Rights = Rights.UNAUTHORIZED;
  @persist('list') leagues: UserLeague[] = [];
  @persist('object') settings: Settings = {
    hasMessageId: false,
  };

  private messageStore: MessageModal;
  private isCollapsed: boolean = false;

  constructor(messageStore: MessageModal) {
    makeAutoObservable(this);
    hydrate('srm-user', this);
    this.messageStore = messageStore;
  }

  addNewLeague(data: UserLeague) {
    this.leagues.push(data);
  }

  async login(name: string, password: string): Promise<boolean> {
    let status: number;

    return fetch(`${apiUrl}/users/login`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, password }),
    })
      .then((response: any) => {
        status = response.status;
        return response
          .json()
          .then((data: any) => {
            if (status === 201) {
              runInAction(() => {
                this.displayName = data.displayName;
                this.leagues = data.leagues;
                this.token = data.token;
                this.loginName = data.loginName;
                this.settings = data.settings;
                this.rightsRole = Rights.AUTHORIZED;
              });

              this.messageStore.sendMessage(
                'LOGIN_SUCCESS',
                MessageType.SUCCESS
              );
              return Promise.resolve(true);
            }

            if (status >= 400) {
              this.messageStore.sendMessage(data.message, MessageType.ERROR);
            }
          })
          .catch(() => Promise.reject(false));
      })
      .catch((e) => {
        this.logout();

        console.error('ERROR while login fetch: ', e);
        return Promise.reject(false);
      });
  }

  async register(name: string, password: string): Promise<boolean> {
    let status: number;

    return fetch(`${apiUrl}/users`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, password }),
    })
      .then((response: any) => {
        status = response.status;
        return response
          .json()
          .then((data: any) => {
            if (status === 201) {
              runInAction(() => {
                this.displayName = data.displayName;
                this.leagues = data.leagues;
                this.token = data.token;
                this.loginName = data.loginName;
                this.settings = data.settings;
                this.rightsRole = Rights.AUTHORIZED;
              });

              this.messageStore.sendMessage(
                'REGISTER_SUCCESS',
                MessageType.SUCCESS
              );
              return Promise.resolve(true);
            }

            if (status >= 400 && status !== 403) {
              this.messageStore.sendMessage(data.message, MessageType.ERROR);
            }

            if (status === 403) {
              this.messageStore.sendMessage(data.message, MessageType.INFO);
            }
          })
          .catch(() => Promise.reject(false));
      })
      .catch((e) => {
        console.error('ERROR while regsiter user: ', e);
        return Promise.reject(false);
      });
  }

  changePassword(oldPW: string, newPW: string, newPWRepeat: string) {
    if (newPW === newPWRepeat) {
      let status: number;
      fetch(`${apiUrl}/users`, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token,
        },
        body: JSON.stringify({ oldPassword: oldPW, password: newPW }),
      })
        .then((response: any) => {
          status = response.status;

          response.json().then((data: any) => {
            if (status === 200) {
              this.messageStore.sendMessage(data.message, MessageType.SUCCESS);
              this.logout();
            }

            if (status >= 400) {
              this.messageStore.sendMessage(data.message, MessageType.ERROR);
            }
          });
        })
        .catch((e) => {
          this.logout();
          console.error('ERROR while change passwword: ', e);
        });
    } else {
      this.messageStore.sendMessage('PASSWORD_NOT_EQUAL', MessageType.ERROR);
    }
  }

  addMessageId(messageId: string) {
    fetch(`${apiUrl}/users/add-message-id`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
      body: JSON.stringify({ messageId }),
    })
      .then((response: any) => {
        response.json().then((data: any) => {
          if (data.message.status === 200 || data.message.status === 201) {
            runInAction(() => {
              this.settings.hasMessageId = true;
            });

            this.messageStore.sendMessage(
              data.message.key,
              MessageType.SUCCESS
            );
          }

          if (data.message.status >= 400) {
            this.messageStore.sendMessage(data.message.key, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.messageStore.sendMessage('ERROR', MessageType.ERROR);
      });
  }

  changeLoginName(oldPW: string, name: string) {
    let status: number;
    fetch(`${apiUrl}/users`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
      body: JSON.stringify({ oldPassword: oldPW, loginName: name }),
    })
      .then((response: any) => {
        status = response.status;

        response.json().then((data: any) => {
          if (status === 200) {
            this.messageStore.sendMessage(data.message, MessageType.SUCCESS);
            this.logout();
          }

          if (status >= 400) {
            this.messageStore.sendMessage(data.message, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        this.logout();
        console.error('ERROR while change loginname: ', e);
      });
  }

  changeDisplayName(oldPW: string, name: string) {
    let status: number;
    fetch(`${apiUrl}/users`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token,
      },
      body: JSON.stringify({ oldPassword: oldPW, displayName: name }),
    })
      .then((response: any) => {
        status = response.status;

        response.json().then((data: any) => {
          if (status === 200) {
            this.messageStore.sendMessage(data.message, MessageType.SUCCESS);
            this.logout();
          }

          if (status >= 400) {
            this.messageStore.sendMessage(data.message, MessageType.ERROR);
          }
        });
      })
      .catch((e) => {
        setTimeout(() => {
          this.logout();
        }, 1000);
        console.error('ERROR while change displayname: ', e);
      });
  }

  isAuthorized(): boolean {
    // call Service and check authToken is OK
    // get leagues and save leagues

    return (
      Boolean(localStorage.getItem('srm-user')) &&
      !!this.displayName &&
      !!this.loginName &&
      !!this.token
    );
  }

  logout() {
    localStorage.removeItem('srm-user');
    window.location.reload();
  }

  switchCollapsedState() {
    this.isCollapsed = !this.isCollapsed;
  }

  @computed
  get getLeagues(): UserLeague[] {
    return _.cloneDeep(this.leagues);
  }

  @computed
  get isNavigationCollapsed(): boolean {
    return this.isCollapsed;
  }
}

export default UserModel;
