import { Button, TextField } from '@mui/material';
import { Modal } from '../Modal/Modal';
import styled from 'styled-components';
import { CancelCommitEvent } from '../../store/LeagueModel';
import { useState, useContext, FormEvent } from 'react';
import { LeagueStore } from '../../store';
import { observer } from 'mobx-react-lite';

interface CancelModalProps {
  divId?: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  flag: string;
  selectedEvent: CancelCommitEvent;
  isRegularCancel: boolean;
}

export const CancelModal = observer(
  ({
    divId,
    isOpen,
    onClose,
    title,
    subtitle,
    flag,
    selectedEvent,
    isRegularCancel = true,
  }: CancelModalProps) => {
    const leagueStore = useContext(LeagueStore);
    const [note, setNote] = useState<string>('');

    const cancelEvent = (event: FormEvent) => {
      event.preventDefault();

      leagueStore.cancelEvent({
        ...selectedEvent,
        note: isRegularCancel ? note : `${note} (zu spät)`,
        divId,
      });

      setNote('');
      onClose();
    };

    return (
      <Modal
        isOpen={isOpen}
        handleClose={onClose}
        title={title}
        flag={flag}
        subtitle={subtitle}
      >
        <ModalContent>
          <form onSubmit={cancelEvent}>
            <TextField
              variant='standard'
              id='note'
              label='Notiz - Optional'
              placeholder='z.B. Antritt der Rennsperre'
              value={note}
              onChange={(event) => setNote(event.target.value)}
              fullWidth
            />
            <Button type='submit' variant='contained' color='primary' fullWidth>
              Absagen
            </Button>
          </form>
        </ModalContent>
      </Modal>
    );
  }
);

const ModalContent = styled.div`
  .MuiButton-root {
    margin-top: var(--srmPaddingBig);
  }
`;
