import { computed, makeAutoObservable } from 'mobx';

const KeyToMessage: Record<string, string> = {
  OK: 'Erfolgreich!',
  ERROR: 'Es ist ein Fehler aufgetreten',

  // RIGHTS
  NO_RIGHTS: 'Für diese Aktion fehlen dir die Rechte',

  //ERRORS
  ERROR_JOIN: 'Die Liga konnte nicht gefunden werden',
  LOGIN_ERROR: 'Name oder Passwort falsch',
  LOGIN_SUCCESS: 'Erfolgreich angemeldet!',
  DOUBLE_USER_ERROR: 'Benutzername bereits vergeben',

  // LOGOUT
  LOGOUT: 'Erfolgreich abgemeldet!',
  LOGOUT_ERROR: 'Fehler beim ausloggen',
};

export enum MessageType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export interface Message_IF {
  message: string;
  type: MessageType;
}

class MessageModal {
  private key: string = '';
  private type: MessageType = MessageType.SUCCESS;
  private timeout: NodeJS.Timeout | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  sendMessage(key: string, type: MessageType) {
    this.key = key;
    this.type = type;

    this.timeout = setTimeout(() => {
      this.clearMessage();
    }, 2000);
  }

  clearMessage() {
    this.key = '';
    this.type = MessageType.SUCCESS;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  @computed
  get getMessage(): Message_IF {
    return {
      message: KeyToMessage[this.key],
      type: this.type,
    };
  }
}

export default MessageModal;
