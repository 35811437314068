import styled from 'styled-components';
import {
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { LeagueStore } from '../../../store';
import {
  EventType,
  Event_Calendar,
  RacingType,
} from '../../../store/LeagueModel';
import { EditLayout } from '../../../components/Edit/EditLayout';
import { COUNTRY_FLAG_API_MEDIUM } from '../../../App';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { EditEvent } from './EditEvent';
import { observer } from 'mobx-react-lite';
import { getEventTime, timeout } from '../../../utils/time';
import { Search, SearchContainer } from '../../../components/Search';
import { FilterModal } from '../../../components/FilterModal';
import { Loading } from '../../../components/Loading';
import { Headline } from '../../../components/Headline';
import { DriverTypeMapping } from '../Drivers/DriversOverview';
import AddIcon from '@mui/icons-material/Add';

export const EventsOverview = observer(() => {
  const leagueStore = useContext(LeagueStore);
  const [currentEvent, setCurrentEvent] = useState<Event_Calendar | null>(null);
  const [filterTags, setFilterTags] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allEvents, setAllEvents] = useState<Event_Calendar[]>([]);
  const [events, setEvents] = useState<Event_Calendar[]>([]);

  const handleAddFilterTag = (tag: string | string[]) => {
    setFilterTags(typeof tag === 'string' ? tag.split(',') : tag);
  };

  const handleRemoveFilterTag = (index: number) => {
    const tempTags = filterTags.slice();
    tempTags.splice(index, 1);
    setFilterTags(tempTags);
  };

  const handleAddEvent = () => {};

  useEffect(() => {
    setIsLoading(true);
    const data = leagueStore.getAllEvents();
    timeout(300).then(() => {
      setEvents(data);
      setAllEvents(data);

      setIsLoading(false);
    });
  }, [leagueStore.currentLeague]);

  useEffect(() => {
    const tempData = allEvents
      .filter((ev) => {
        if (
          filterTags.length === 0 ||
          filterTags.includes(ev.divisionShortName)
        ) {
          return ev;
        }
      })
      .filter((ev) =>
        search ? ev.track.toLowerCase().includes(search.toLowerCase()) : ev
      );

    setEvents(tempData);
  }, [search, filterTags]);

  return (
    <PageLayout>
      <SearchContainer>
        <Search
          placeholder='Suche ein Event'
          searchTerm={search}
          onSearch={setSearch}
          onClear={() => setSearch('')}
          tags={filterTags.map((t) => {
            return (
              DriverTypeMapping[t] || leagueStore.getDivisionNameById(t) || t
            );
          })}
          onRemoveTag={handleRemoveFilterTag}
        />
      </SearchContainer>
      <Headline title='Events' infoText={events.length} enablePadding />
      <EditLayout
        onFilterClick={() => setShowFilter(true)}
        speedDialActions={[
          {
            name: 'add',
            icon: <AddIcon />,
            tooltip: 'Erstellen',
            onClick: handleAddEvent,
          },
        ]}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <List>
            {events
              .filter((ev) => {
                if (
                  filterTags.length === 0 ||
                  filterTags.includes(ev.divisionShortName)
                ) {
                  return ev;
                }
              })
              .filter((ev) =>
                search
                  ? ev.track.toLowerCase().includes(search.toLowerCase())
                  : ev
              )
              .map((ev: Event_Calendar, i: number ) => {
                return (
                  <ListItem
                    key={ev.date + i}
                    button
                    onClick={() => setCurrentEvent(ev)}
                    divider
                    dense
                  >
                    <ListItemIcon>
                      {ev.result && ev.highlightLink ? (
                        <CheckCircleIcon color='success' fontSize='small' />
                      ) : ev.result || ev.highlightLink ? (
                        <RemoveCircleIcon color='info' fontSize='small' />
                      ) : (
                        <CancelIcon color='error' fontSize='small' />
                      )}
                      {}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Subtitle>
                          <Typography variant='body1' component='span'>
                            {ev.divisionShortName}
                          </Typography>
                          <SmallImg
                            src={`${COUNTRY_FLAG_API_MEDIUM}/${ev.countryCode.toLowerCase()}.png`}
                            alt={ev.countryCode}
                          />
                          <Typography variant='body1' component='span'>
                            {ev.type === EventType.SPRINT
                              ? `${ev.track} - Sprint`
                              : ev.racingType === RacingType.F2
                              ? `${ev.track} - Haupt`
                              : ev.track}
                          </Typography>
                        </Subtitle>
                      }
                      secondary={
                        <Subtitle>
                          <AccessTimeIcon fontSize='inherit' />
                          <Typography variant='body2' component='span'>
                            {getEventTime(ev.date)}
                          </Typography>
                        </Subtitle>
                      }
                    />
                  </ListItem>
                );
              })}
          </List>
        )}
      </EditLayout>
      <Divider />

      {/* Filter Modal */}
      {showFilter && (
        <FilterModal
          isOpen={showFilter}
          onClose={() => setShowFilter(false)}
          title='Filtern & Sortieren'
        >
          <FormControl fullWidth>
            <InputLabel id='filter-drivers'>Filtern nach</InputLabel>
            <Select
              id='filter-drivers'
              multiple
              value={filterTags}
              onChange={(event: SelectChangeEvent<typeof filterTags>) =>
                handleAddFilterTag(event.target.value)
              }
              renderValue={(selected) =>
                selected
                  .map((s) => {
                    return (
                      DriverTypeMapping[s] ||
                      leagueStore.getDivisionNameById(s) ||
                      s
                    );
                  })
                  .join(', ')
              }
            >
              {leagueStore.getAllDivisions.map((d) => (
                <MenuItem key={d.shortName} value={d.shortName}>
                  <Checkbox checked={filterTags.indexOf(d.shortName) > -1} />
                  <ListItemText primary={d.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FilterModal>
      )}
      {/* Edit Single Event */}
      {currentEvent && (
        <EditEvent
          currentEvent={currentEvent}
          onClose={() => setCurrentEvent(null)}
        />
      )}
    </PageLayout>
  );
});

const PageLayout = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
`;

const Subtitle = styled.span`
  display: flex;
  grid-gap: 3px;
  align-items: center;
`;

const SmallImg = styled.img`
  max-height: 14px;
  margin-right: 3px;
`;
