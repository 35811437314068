import {
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Modal } from '../Modal/Modal';
import styled from 'styled-components';

import SpeedIcon from '@mui/icons-material/Speed';
import { observer } from 'mobx-react-lite';
import { EventResult } from '../../store/LeagueModel';
import { getTeamBackground } from '../../utils/getTeamBackground';
import { useContext, useState } from 'react';
import { LeagueStore } from '../../store';

interface CommitModal {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  flag: string;
  results?: EventResult[];
}

enum EVENT_RESULT_VIEW {
  RACE = 'RACE',
  QUALI = 'QUALI',
}

export const ResultModal = observer(
  ({ isOpen, onClose, title, subtitle, flag, results }: CommitModal) => {
    const leagueStore = useContext(LeagueStore);
    const [view, setView] = useState<EVENT_RESULT_VIEW>(EVENT_RESULT_VIEW.RACE);

    return (
      <Modal
        isOpen={isOpen}
        handleClose={onClose}
        title={title}
        flag={flag}
        subtitle='Ergebnisse'
      >
        {results ? (
          <>
            <Tabs
              variant='fullWidth'
              value={view}
              indicatorColor='primary'
              textColor='secondary'
              onChange={(event, value) => setView(value)}
            >
              <Tab label='Rennen' value={EVENT_RESULT_VIEW.RACE} />
              <Tab label='Qualifying' value={EVENT_RESULT_VIEW.QUALI} />
            </Tabs>
            {view === EVENT_RESULT_VIEW.RACE && (
              <StyledList>
                {results
                  .slice()
                  .sort(
                    (r1: EventResult, r2: EventResult) => r1.place - r2.place
                  )
                  .map((r) => {
                    return (
                      <span key={`${r.place}-${r.time}`}>
                        <ListItem>
                          <ItemContainerResult>
                            <Typography variant='body1'>{r.place}.</Typography>
                            <Img src={getTeamBackground(r.team)}></Img>
                            <ListItemText
                              primary={
                                <span>
                                  {r.name}{' '}
                                  {r.hasFastestLap && (
                                    <SpeedIcon
                                      fontSize='small'
                                      htmlColor='var(--srmPink)'
                                    />
                                  )}
                                </span>
                              }
                            />
                            <Chip
                              size='small'
                              label={
                                r.place === 1 || r.time === 'DNF'
                                  ? r.time.replace('+', '')
                                  : `+${r.time.replace('+', '')}`
                              }
                            />
                            <Typography variant='h6'>{r.points}</Typography>
                          </ItemContainerResult>
                        </ListItem>
                        <Divider variant='middle' />
                      </span>
                    );
                  })}
              </StyledList>
            )}
            {view === EVENT_RESULT_VIEW.QUALI && (
              <QualiContainer>
                {results
                  .slice()
                  .sort((r1: EventResult, r2: EventResult) => {
                    if (r1.quali && r2.quali) {
                      return r1.quali?.place - r2.quali?.place;
                    } else return 1;
                  })
                  .map((r) => {
                    if (r.quali) {
                      const minutes = Math.floor(r.quali?.time / 60);
                      const seconds = r.quali?.time - minutes * 60;
                      return (
                        <QualiPlaceLayout
                          color={leagueStore.getTeamColor(r.team)}
                        >
                          <Typography variant='h6'>
                            #{r.quali?.place}
                          </Typography>
                          <Typography variant='body2'>
                            {`${minutes},${seconds.toFixed(3)}`}
                          </Typography>
                          <Typography variant='body2'>{r.name}</Typography>
                        </QualiPlaceLayout>
                      );
                    }
                  })}
              </QualiContainer>
            )}
          </>
        ) : (
          <Center>
            <Typography variant='h6'>
              Die Ergebnisse werden noch eingetragen
            </Typography>
          </Center>
        )}
      </Modal>
    );
  }
);

const StyledList = styled(List)`
  .MuiListItem-gutters {
    padding-left: 0;
    padding-right: 0;
  }
`;

const QualiContainer = styled.div`
  margin-top: var(--srmPadding);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--srmPaddingBig);
`;

const QualiPlaceLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 70px;
  &:nth-child(even) {
    margin-top: 70px;
  }
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 2px solid ${(props) => props.color};
  border-left: 2px solid ${(props) => props.color};
  border-right: 2px solid ${(props) => props.color};
`;

const Img = styled.img`
  max-height: 28px;
  border-radius: 2px;
  align-self: center;
  justify-self: center;
`;

const ItemContainerResult = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 15px 25px 3fr 1fr var(--srmPaddingBig);
  gap: 8px;
  align-items: center;

  p,
  h6 {
    justify-self: center;
  }
`;

const Center = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;
