import { createTheme, DeprecatedThemeOptions } from '@mui/material/styles';

export const theme: DeprecatedThemeOptions = createTheme({
  typography: {
    button: {
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.025rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1.05rem',
      },
    },
    subtitle1: {
      '@media (min-width:600px)': {
        fontSize: '1.075rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1.125rem',
      },
    },
    body1: {
      '@media (min-width:600px)': {
        fontSize: '1.075rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.125rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1.175rem',
      },
    },
    body2: {
      '@media (min-width:600px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:960px)': {
        fontSize: '0.95rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1rem',
      },
    },
    h5: {
      '@media (min-width:600px)': {
        fontSize: '1.3rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.4rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1.5rem',
      },
    },
    h6: {
      '@media (min-width:600px)': {
        fontSize: '1.175rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.25rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1.325rem',
      },
    },

    fontFamily: [
      'Play',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#A8E000',
      contrastText: '#333',
    },
    secondary: {
      main: '#333',
      contrastText: '#A8E000',
    },
  },
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          color: '#888',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#888',
        },
      },
      defaultProps: {
        disableFocusListener: true,
        enterTouchDelay: 100,
        leaveTouchDelay: 2000,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 12,
        },
        avatar: {
          marginRight: 10,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 12,
          '&:last-child': {
            paddingBottom: 14,
          },
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
          '&:nth-of-type(odd)': {
            backgroundColor: '#f5f5f5',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: '#333',
          color: '#f5f5f5',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 36,
          height: 36,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '@media (min-width:768px)': {
            fontSize: '0.9rem',
          },
          '@media (min-width:960px)': {
            fontSize: '0.95rem',
          },
          '@media (min-width:1280px)': {
            fontSize: '1rem',
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        raised: true,
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: 16,
          minWidth: 0,
        },
      },
    },
  },
});
