import { useState, useContext } from 'react';
import styled from 'styled-components';
import { Chip, NativeSelect, Typography } from '@mui/material';
import { PenaltyRow } from './PenaltyRow';
import { LeagueStore } from '../../store';
import { observer } from 'mobx-react-lite';
import { ScrollingItemsContainer } from '../../components/Content';
import { DarkBorder } from '../../components/DarkBorder';

export enum PENALTY_FILTER {
  ALL = 'ALL',
  SELF = 'SELF',
}

export const Penalties = observer(() => {
  const [filter, setFilter] = useState<PENALTY_FILTER>(PENALTY_FILTER.SELF);
  const leagueStore = useContext(LeagueStore);
  const [trackFilter, setTrackFilter] = useState<string>('ALL');

  return (
    <Container>
      <FilterContainer>
        <ChipContainer>
          <Chip
            color={filter === PENALTY_FILTER.ALL ? 'primary' : 'default'}
            label='Alle'
            onClick={() => setFilter(PENALTY_FILTER.ALL)}
          />
          <Chip
            color={filter === PENALTY_FILTER.SELF ? 'primary' : 'default'}
            label='Nur meine'
            onClick={() => setFilter(PENALTY_FILTER.SELF)}
          />
        </ChipContainer>
        <NativeSelect
          value={trackFilter}
          onChange={(event: any) => setTrackFilter(event.target.value)}
        >
          <optgroup label='Events'>
            <option key='all' value='ALL'>
              Alle Events
            </option>
            {[
              ...new Set(
                leagueStore
                  .getPenalties(PENALTY_FILTER.ALL, 'ALL')
                  .map((p) => p.track)
              ),
            ].map((pen, i) => (
              <option key={`${i}-${pen}`} value={pen}>
                {pen}
              </option>
            ))}
          </optgroup>
        </NativeSelect>
      </FilterContainer>
      <DarkBorder>
        <Typography variant='h6' component='p'>
          {filter === PENALTY_FILTER.ALL ? 'Alle Strafen' : 'Nur meine Strafen'}
        </Typography>
      </DarkBorder>
      <ScrollContainer>
        <ScrollingItemsContainer>
          {leagueStore
            .getPenalties(filter, trackFilter)

            .map((d, i) => {
              return <PenaltyRow data={d} key={d.date + i} />;
            })}
        </ScrollingItemsContainer>
      </ScrollContainer>
    </Container>
  );
});

const FilterContainer = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
`;

const ScrollContainer = styled.div`
  padding: var(--srmPadding);
  overflow-y: auto;
`;

const ChipContainer = styled.div`
  display: flex;
  gap: 10px;
`;
