import {
  Typography,
  Avatar,
  Card,
  CardHeader,
  CardActionArea,
  CardContent,
  Button,
} from '@mui/material';
import styled from 'styled-components';
import { PageContent, ScrollingItemsContainer } from '../../components/Content';
import { saveAs } from 'file-saver';

import GavelIcon from '@mui/icons-material/Gavel';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { useState } from 'react';
import { Quizz } from './Quizz';

export const Rules = () => {
  const [showQuizz, setShowQuizz] = useState<boolean>(false);

  const saveFile = () => {
    saveAs('/Regelwerk.pdf', 'DHDL_Regelwerk.pdf');
  };

  return (
    <PageContent>
      <Typography variant='subtitle1' component='p'>
        Hier findest du unser aktuelles Regelwerk. Du kannst es dir einfach
        downloaden und durchlesen
      </Typography>
      <br />

      <ScrollingItemsContainer>
        <Card>
          <CardActionArea onClick={saveFile}>
            <CardHeader
              title='DHDL Regelwerk 4.8'
              titleTypographyProps={{ variant: 'h6', component: 'p' }}
              avatar={
                <Avatar variant='rounded'>
                  <GavelIcon htmlColor='var(--srmGrey)' />
                </Avatar>
              }
            />
            <CardContent>
              <CenterText>
                <Typography variant='h6' component='p'>
                  Klicken zum downloaden!
                </Typography>
              </CenterText>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card>
          <CardHeader
            title='Fahrprüfung'
            titleTypographyProps={{ variant: 'h6', component: 'p' }}
            avatar={
              <Avatar variant='rounded'>
                <NotListedLocationIcon htmlColor='var(--srmGrey)' />
              </Avatar>
            }
          />
          <CardContent>
            <Typography variant='body1' component='p'>
              Hier kannst du unsere theoretische Fahrprüfung zu unserem
              Regelwerk absolvieren. Es gibt mehr Fragen, als du in einem
              Durchgang bearbeitest, heißt du kannst die Prüfung auch gerne
              wiederholen. Viel Spaß!
            </Typography>
            <br />
            <Button
              onClick={() => setShowQuizz(true)}
              variant='outlined'
              color='secondary'
            >
              Prüfung starten
            </Button>
          </CardContent>
        </Card>
      </ScrollingItemsContainer>
      {showQuizz && (
        <Quizz isOpen={showQuizz} handleClose={() => setShowQuizz(false)} />
      )}
    </PageContent>
  );
};

const CenterText = styled.div`
  text-align: center;
`;
