import { useState, useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { FullHeightModal } from '../../../components/Modal/FullHeightModal';
import { ResponsivePie } from '@nivo/pie';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import {
  DivisionDriver,
  DriverDivision,
  DriverType,
  RacingType,
} from '../../../store/LeagueModel';
import { LeagueStore } from '../../../store';
import { observer } from 'mobx-react-lite';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  NativeSelect,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CenterModal } from '../../../components/Modal/CenterModal';
import { getRacingTypeLogo } from '../../../utils/getRacingTypeLogo';

import _ from 'lodash';
import { format, fromUnixTime } from 'date-fns';
import { Tags } from '../../../components/Tags';
import { CheckModal } from '../../../components/CheckModal';

import { Headline } from '../../../components/Headline';
import { SwiperContainer } from '../../user/News';
import { Breakpoint } from '../../../utils/breakpoints';

interface EditDriverProps {
  currentDriver: DivisionDriver;

  onClose: () => void;
}

export const EditDriver = observer(
  ({ currentDriver, onClose }: EditDriverProps) => {
    const leagueStore = useContext(LeagueStore);
    const [driverType, setDriverType] = useState<DriverType>(
      DriverType.SUBSTITUTE
    );
    const [carNumber, setCarNumber] = useState<number>(
      currentDriver?.carNumber || 0
    );
    const [divisions, setDivisions] = useState<DriverDivision[]>([]);

    const [addDivision, setAddDivision] = useState<boolean>(false);
    const [newDivisionId, setNewDivisionId] = useState<string>('');
    const [driverLicense, setDriverLicense] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);

    const [isRemoveDriverCheck, setIsRemoveDriverCheck] =
      useState<boolean>(false);
    const [isInactiveCheck, setIsInactiveCheck] = useState<boolean>(false);

    const allDivisions = leagueStore.allDivisions;

    // slider pro Liga, wenn Stammfahrer. Wenn EF, dann single mit ALLEN Rennen und teilnahmen
    // als info vermerken

    useEffect(() => {
      setCarNumber(currentDriver?.carNumber || 0);
      setDivisions(_.cloneDeep(currentDriver?.divisions) || []);
      setDriverType(currentDriver?.driverType || DriverType.SUBSTITUTE);
      setDriverLicense(currentDriver?.driverLicense || []);
      setTags(currentDriver.tags || []);
    }, [currentDriver]);

    const resetDriver = () => {
      setCarNumber(currentDriver?.carNumber || 0);
      setDivisions(_.cloneDeep(currentDriver?.divisions) || []);
      setDriverType(currentDriver?.driverType || DriverType.SUBSTITUTE);
      setDriverLicense(currentDriver?.driverLicense || []);
    };

    const handleRemoveDriver = () => {
      // leagueStore.removeDriver(currentDriver.driverId)
      setIsRemoveDriverCheck(false);
    };

    const handleSetDriverInactive = () => {
      leagueStore.setDriverInactive(currentDriver.driverId, currentDriver.name);
      handleClose();
    };

    const handleClose = () => {
      setCarNumber(0);
      setDivisions([]);
      setDriverType(DriverType.SUBSTITUTE);
      setIsInactiveCheck(false);
      setIsRemoveDriverCheck(false);
      onClose();
    };

    const addNewTag = (tag: string) => {
      const tempTags = tags.slice();
      tempTags.push(tag);
      setTags(tempTags);
    };

    const removeTag = (index: number) => {
      const tempTags = tags.slice();
      tempTags.splice(index, 1);
      setTags(tempTags);
    };

    const handleDriverLicenseChange = (divisionId: string) => {
      let tempDriverLicense = driverLicense.slice();
      if (tempDriverLicense.includes(divisionId)) {
        tempDriverLicense = tempDriverLicense.filter((l) => l !== divisionId);
      } else {
        tempDriverLicense.push(divisionId);
      }

      setDriverLicense(tempDriverLicense);
    };

    const removeDivision = (index: number) => {
      const tempDivisions = divisions.slice();
      tempDivisions.splice(index, 1);
      setDivisions(tempDivisions);
    };

    const updateDivisionTeam = (index: number, team: string) => {
      const tempDivisions = divisions.slice();
      tempDivisions[index].team = team;
      setDivisions(tempDivisions);
    };

    const handleNewDivision = () => {
      const tempDivisions = divisions.slice();
      if (currentDriver?.driverId) {
        tempDivisions.push({
          divisionId: newDivisionId,
          driverId: currentDriver?.driverId,
          team: '',
          points: 0,
        });
        setNewDivisionId('');
        setAddDivision(false);
        setDivisions(tempDivisions);
      }
    };

    const handleDriverSave = () => {
      if (currentDriver) {
        leagueStore.updateDriver({
          name: currentDriver.name,
          divisions,
          driverType,
          carNumber,
          driverLicense,
          tags,
        });
      }

      onClose();
    };

    const isDisabled: boolean = useMemo(() => {
      let tempValue = false;
      divisions.forEach((div) => {
        if (!div.team) {
          tempValue = true;
        }
      });

      if (driverType === DriverType.REGULAR && divisions.length === 0) {
        tempValue = true;
      }

      return tempValue;
    }, [divisions, driverType]);

    return (
      <FullHeightModal
        isOpen={Boolean(currentDriver)}
        onClose={handleClose}
        title={currentDriver?.name || ''}
        state={currentDriver.isActive}
        subtitle={`Mitglied seit: ${
          currentDriver.memberSince
            ? format(fromUnixTime(currentDriver.memberSince), 'dd.MM.yyyy')
            : 'k.A.'
        }`}
      >
        <div>
          <Card raised={false}>
            <CardContent>
              <InfoRow>
                <InfoPart>
                  <Typography variant='body1' component='p'>
                    {leagueStore.isAdmin(currentDriver.name)
                      ? 'Admin'
                      : leagueStore.isSteward(currentDriver.name)
                      ? 'Steward'
                      : currentDriver.driverType === DriverType.CONTENDER
                      ? 'Bewerber'
                      : 'Fahrer'}
                  </Typography>
                  <Divider />
                  <Typography variant='body2' component='p'>
                    Rolle
                  </Typography>
                </InfoPart>
                <Divider orientation='vertical' />
                <InfoPart>
                  <Typography variant='body1' component='p'>
                    {currentDriver.penaltyPoints}
                  </Typography>
                  <Divider />
                  <Typography variant='body2' component='p'>
                    Strafpunkte
                  </Typography>
                </InfoPart>
              </InfoRow>
            </CardContent>
          </Card>
          <br />
          <Card raised={false}>
            <CardContent>
              <SwiperContainer>
                <Headline title='Eventteilnahmen pro Liga' />
                <Swiper
                  grabCursor={true}
                  slidesPerView={1}
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                    clickable: true,
                    renderBullet: (index, className) =>
                      `<span class='${className}'>${allDivisions[index].shortName}</span>`,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className='news-slider'
                >
                  {allDivisions.map((div) => {
                    const drivenIn = leagueStore.getRacesDrivenInByDriver(
                      currentDriver.name,
                      div._id
                    );

                    const cancellations =
                      leagueStore.getCancellationsCountByDriver(
                        currentDriver.name,
                        div._id
                      );

                    const noCancellations =
                      leagueStore.getRacesWithoutCancellation(
                        currentDriver.name,
                        div._id
                      );

                    const events =
                      leagueStore.getEventsWithResult(div._id)?.length -
                      drivenIn;

                    const data = currentDriver.divisions.find(
                      (d) => d.divisionId === div._id
                    )
                      ? [
                          {
                            id: 'Teilgenommen',
                            value: drivenIn,
                            color: 'var(--srmGreen)',
                          },
                          {
                            id: 'Nicht Teilgenommen',
                            value: cancellations,
                            color: 'var(--srmGreyLight)',
                          },
                          {
                            id: 'Ohne Absage',
                            value: noCancellations,
                            color: 'var(--srmRed)',
                          },
                        ]
                      : [
                          {
                            id: 'Teilgenommen',
                            value: drivenIn,
                            color: 'var(--srmGreen)',
                          },
                          {
                            id: 'Nicht Teilgenommen',
                            value: events,
                            color: 'var(--srmGreyLight)',
                          },
                        ];

                    return (
                      <SwiperSlide key={div._id}>
                        <ChartContainer>
                          <ResponsivePie
                            data={data}
                            padAngle={2}
                            margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                            cornerRadius={2}
                            arcLabelsSkipAngle={10}
                            innerRadius={0.5}
                            colors={({ data }) => (data as any).color}
                            activeInnerRadiusOffset={1}
                            activeOuterRadiusOffset={1}
                            enableArcLinkLabels={false}
                            arcLabelsTextColor='#333'
                          />
                        </ChartContainer>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <Divider />
                <Legend>
                  <LegendText>
                    <Skeleton
                      variant='circular'
                      width={12}
                      height={12}
                      animation={false}
                      sx={{
                        backgroundColor: 'var(--srmGreen)',
                      }}
                    />
                    <Typography variant='body2' component='p'>
                      Teilgenommen
                    </Typography>
                  </LegendText>

                  <LegendText>
                    <Skeleton
                      variant='circular'
                      width={12}
                      height={12}
                      animation={false}
                      sx={{
                        backgroundColor: 'var(--srmGreyLight)',
                      }}
                    />
                    <Typography variant='body2' component='p'>
                      Nicht Teilgenommen
                    </Typography>
                  </LegendText>

                  <LegendText>
                    <Skeleton
                      variant='circular'
                      width={12}
                      height={12}
                      animation={false}
                      sx={{
                        backgroundColor: 'var(--srmRed)',
                      }}
                    />
                    <Typography variant='body2' component='p'>
                      Ohne Absage
                    </Typography>
                  </LegendText>
                </Legend>
                <Divider />
              </SwiperContainer>
            </CardContent>
          </Card>

          <br />

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Informationen</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Tags tags={tags} onRemove={removeTag} onChange={addNewTag} />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Allgemeines</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                type='number'
                color='secondary'
                fullWidth
                value={carNumber}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => setCarNumber(parseInt(event.currentTarget.value, 10))}
                label='Autonummer'
              />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Strafen</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <StyledList dense>
                {leagueStore
                  .getIncidentsByDriverName(currentDriver.name)
                  .map((inc, i) => {
                    return (
                      <ListItem
                        key={`${i}-${inc.penalty?.driver}`}
                        divider
                        dense
                      >
                        <ListItemText
                          primary={`${inc.author.driver} vs. ${inc.receiver.driver}`}
                          secondary={`${inc.track} - ${inc.penalty?.type}`}
                        />

                        <Typography variant='body1' color='error' component='p'>
                          {inc.penalty?.penaltyPoints}
                        </Typography>
                      </ListItem>
                    );
                  })}
              </StyledList>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Fahrerart</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                row
                value={driverType}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setDriverType(
                    (event.target as HTMLInputElement).value as DriverType
                  )
                }
              >
                <FormControlLabel
                  value={DriverType.SUBSTITUTE}
                  control={<Radio color='primary' />}
                  label='Ersatzfahrer'
                />
                <FormControlLabel
                  value={DriverType.REGULAR}
                  control={<Radio color='primary' />}
                  label='Stammfahrer'
                />
              </RadioGroup>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={driverType === DriverType.SUBSTITUTE}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Aktuelle Ligen</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LeaguesContainer>
                {divisions.map((div, i) => {
                  const oldTeam =
                    currentDriver?.divisions.find(
                      (d) => d.divisionId === div.divisionId
                    )?.team || '';

                  const racingType =
                    leagueStore.getLeagueDivision(div.divisionId)?.racingType ??
                    RacingType.F1;
                  return (
                    <Card key={`driverDiv-${div.divisionId}`}>
                      <CardHeader
                        avatar={
                          <Avatar
                            variant='square'
                            src={getRacingTypeLogo(racingType)}
                          />
                        }
                        title={
                          leagueStore.getLeagueDivision(div.divisionId)?.name
                        }
                        titleTypographyProps={{ variant: 'h6' }}
                        action={
                          <IconButton
                            onClick={() => removeDivision(i)}
                            disabled={driverType === DriverType.SUBSTITUTE}
                            size='large'
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        }
                      />
                      <CardContent>
                        <Inputs>
                          <FormControl>
                            <InputLabel htmlFor='team-label' color='secondary'>
                              Team
                            </InputLabel>
                            <NativeSelect
                              disabled={driverType === DriverType.SUBSTITUTE}
                              id='team-label'
                              value={div.team}
                              fullWidth
                              onChange={(event: any) =>
                                updateDivisionTeam(i, event.currentTarget.value)
                              }
                            >
                              <option disabled value=''>
                                Team
                              </option>
                              {leagueStore
                                .getAllTeamNames(div.divisionId, true, [
                                  oldTeam,
                                ])
                                .map((t) => (
                                  <option key={t} value={t}>
                                    {t}
                                  </option>
                                ))}
                            </NativeSelect>
                          </FormControl>
                          <TextField
                            disabled
                            type='number'
                            color='secondary'
                            fullWidth
                            value={div.points}
                            inputProps={{
                              min: 0,
                            }}
                            label='Ligapunkte'
                          />
                        </Inputs>
                      </CardContent>
                    </Card>
                  );
                })}
                {driverType === DriverType.REGULAR && (
                  <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => setAddDivision(true)}
                  >
                    Liga hinzufügen
                  </Button>
                )}
              </LeaguesContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled={driverType === DriverType.CONTENDER}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Fahrerlaubnis für Ligen</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                {leagueStore.allDivisions.map((div) => {
                  return (
                    <FormControlLabel
                      key={div._id}
                      control={
                        <Checkbox
                          color='primary'
                          checked={driverLicense.includes(div._id)}
                          onChange={() => handleDriverLicenseChange(div._id)}
                          name={div.shortName}
                        />
                      }
                      label={
                        <Row>
                          <RacingTypeLogo
                            src={getRacingTypeLogo(div.racingType)}
                            alt='racing-type'
                          />
                          <Typography variant='subtitle1'>
                            {div.name}
                          </Typography>
                        </Row>
                      }
                    />
                  );
                })}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <br />
          <div>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              disabled={isDisabled}
              onClick={handleDriverSave}
            >
              Fahrer Speichern
            </Button>
            <br />
            <br />
            <Button
              fullWidth
              variant='contained'
              color='secondary'
              onClick={() => setIsInactiveCheck(true)}
              disabled={
                currentDriver.isAdmin ||
                currentDriver.driverId === leagueStore.ownerId
              }
            >
              Auf inaktiv setzen
            </Button>
            <br />
            <br />
            {/* Sicherheitscheck */}
            <Button fullWidth variant='contained' color='error' disabled>
              Aus Liga werfen
            </Button>
            <br />
            <br />
            <Button
              fullWidth
              variant='outlined'
              color='secondary'
              onClick={resetDriver}
            >
              Reset Fahrer
            </Button>
          </div>
        </div>
        {addDivision && (
          <CenterModal
            isOpen={addDivision}
            handleClose={() => setAddDivision(false)}
            title='Wähle eine neue Liga'
          >
            <br />
            <NativeSelect
              id='team-label'
              value={newDivisionId}
              fullWidth
              onChange={(event: any) =>
                setNewDivisionId(event.currentTarget.value)
              }
            >
              <option disabled value=''>
                Liga
              </option>
              {leagueStore.allDivisions.map((div) => (
                <option key={div.racingType} value={div._id}>
                  {`${div.racingType}: ${div.name}`}
                </option>
              ))}
            </NativeSelect>
            <br />
            <br />
            <Button
              fullWidth
              variant='contained'
              color='primary'
              disabled={!newDivisionId}
              onClick={handleNewDivision}
            >
              Hinzufügen
            </Button>
          </CenterModal>
        )}

        {isRemoveDriverCheck && (
          <CheckModal
            title={`${currentDriver.name} rauswerfen ?`}
            text='Der Fahrer wird mit deiner Zustimmung aus der Liga entfernt. Um dies rückgängig zumachen, muss der Fahrer der Liga erneut beitreten.'
            isOpen={isRemoveDriverCheck}
            onDecline={() => setIsRemoveDriverCheck(false)}
            onAccept={handleRemoveDriver}
          />
        )}
        {isInactiveCheck && (
          <CheckModal
            title={`${currentDriver.name} ist inaktiv ?`}
            text="Der Status des Fahrers wird auf 'inaktiv' gesetzt. Dadurch wird dieser aus allen 'Ligen' entfernt und Berechtigungen entzogen, sich zu einem Event anzumelden, Vorfälle einzureichen usw."
            isOpen={isInactiveCheck}
            onDecline={() => setIsInactiveCheck(false)}
            onAccept={handleSetDriverInactive}
          />
        )}
      </FullHeightModal>
    );
  }
);

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--srmPadding);
`;

const LeaguesContainer = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 15px;
`;

const RacingTypeLogo = styled.img`
  height: 12px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px;
  align-items: center;
`;

const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-items: center;
`;

const InfoPart = styled.div`
  display: grid;
  grid-template-rows: 1fr auto auto;
  grid-gap: 3px;
  text-align: center;
`;

const StyledList = styled(List)`
  .MuiListItem-root {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ChartContainer = styled.div`
  position: relative;
  height: 100px;
  width: 100%;
`;

const Legend = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;

  @media only screen and (min-width: ${Breakpoint.tablet}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const LegendText = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
  align-items: center;

  @media only screen and (max-width: ${Breakpoint.tablet}px) {
    &:last-child {
      grid-column: 1 / -1;
    }
  }
`;
