import { Card, CardContent, Typography, Rating, Divider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { Rating as RatingIF } from '../store/LeagueModel';
import { SRMTooltip } from './SRMTooltip';

interface DriverRatingProps {
  rating?: RatingIF;
  penaltyPoints?: number;
}

export const DriverRating = observer(
  ({ rating, penaltyPoints }: DriverRatingProps) => {
    return (
      <Card>
        <CardContent>
          {rating ? (
            <>
              <Row>
                <Info>
                  <SRMTooltip text='Die Berechnung erfolgt abhängig von den gefahrenen Rennen und der Anzahl an Strafpunkten'>
                    <Typography variant='body1'>Safety</Typography>
                  </SRMTooltip>
                  <Rating
                    value={rating.safety || 0}
                    max={5}
                    readOnly
                    precision={0.5}
                  />
                </Info>
                <Info>
                  <Typography variant='body1'>Pace&nbsp;</Typography>

                  <Rating
                    value={rating.pace || 0}
                    max={5}
                    readOnly
                    precision={0.5}
                  />
                </Info>
              </Row>

              {penaltyPoints !== undefined && (
                <>
                  <br />
                  <Divider />
                  <br />
                  <Row>
                    <Typography variant='subtitle1'>
                      Aktuelle Strafpunkte: <RedText>{penaltyPoints}</RedText>
                    </Typography>
                  </Row>
                </>
              )}
            </>
          ) : (
            <Typography variant='subtitle1'>
              Aktuelles Fahrerrating nicht verfügbar
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  }
);

const Row = styled.div`
  display: flex;
  gap: var(--srmPadding);
  justify-content: space-evenly;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const RedText = styled.span`
  color: var(--srmRed);
`;
