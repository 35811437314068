import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ScrollingItemsContainer } from '../../../components/Content';
import { LeagueStore } from '../../../store';
import { Contender as Contender_IF } from '../../../store/LeagueModel';
import { Contender } from './Contender';

import { Search } from '../../../components/Search';
import { Headline } from '../../../components/Headline';

export const ContendersOverview = observer(() => {
  const leagueStore = useContext(LeagueStore);
  const [search, setSearch] = useState<string>('');
  const [contender, setContender] = useState<Contender_IF[]>([]);

  useEffect(() => {
    const tempContender = leagueStore
      .getContenders()
      .filter((c) =>
        search ? c.name.toLowerCase().includes(search.toLowerCase()) : c
      );

    setContender(tempContender);
  }, [search]);

  return (
    <PageLayout>
      <SearchContainer>
        <Search
          placeholder='Suche einen Bewerber'
          searchTerm={search}
          onSearch={setSearch}
          onClear={() => setSearch('')}
        />
      </SearchContainer>
      <Headline title='Bewerber' infoText={contender.length} enablePadding />
      <Content>
        <ScrollingItemsContainer>
          {contender.map((c, index) => {
            return <Contender key={`${index}-${c.name}`} contender={c} />;
          })}
        </ScrollingItemsContainer>
      </Content>
    </PageLayout>
  );
});

const PageLayout = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
  overflow-y: hidden;
`;

const Content = styled.div`
  padding: 15px 15px 0;
  overflow-y: auto;
`;

const SearchContainer = styled.div`
  padding: var(--srmPadding);
`;
