import {
  Card,
  CardHeader,
  NativeSelect,
  CardContent,
  Typography,
  Divider,
  Chip,
} from '@mui/material';
import { useMemo, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { LeagueStore } from '../../store';
import { SRMTooltip } from '../SRMTooltip';

interface DriverComparisonProps {
  driver?: string;
  mate?: string;
}

export const DriverComparison = ({ driver, mate }: DriverComparisonProps) => {
  const leagueStore = useContext(LeagueStore);
  const [compareToDriver, setCompareToDriver] = useState<string>(mate || '');

  const comparisonData = useMemo(() => {
    return leagueStore.comparisonData(driver, compareToDriver);
  }, [compareToDriver]);

  useEffect(() => {
    setCompareToDriver(mate || leagueStore.teamMate);
  }, [mate]);

  return (
    <Card>
      <CardHeader
        title={driver ? driver : 'Fahrervergleich'}
        titleTypographyProps={{ variant: 'h6' }}
        subheader={
          mate ? (
            `vs. ${mate}`
          ) : (
            <CompareDriverContainer>
              vs.{' '}
              <NativeSelect
                style={{ color: 'var(--srmGreyLight)' }}
                value={compareToDriver}
                onChange={(event: any) =>
                  setCompareToDriver(event.target.value)
                }
              >
                {leagueStore
                  .getAllDriver()
                  .filter((dr) => dr.name !== leagueStore.driver?.name)
                  .map((dr, index) => (
                    <option key={`${dr.name}-${index}`} value={dr.name}>
                      {dr.name}
                    </option>
                  ))}
              </NativeSelect>
            </CompareDriverContainer>
          )
        }
        action={
          <SRMTooltip text=' Es werden nur die Rennen gewertet, in denen auch beide Fahrer mitgefahren sind' />
        }
      />
      <CardContent>
        <ComparisonContainer>
          <ComparisonPart>
            <Typography variant='subtitle1'>Rennen</Typography>
            <Divider variant='fullWidth' />
            <Space />
            <Chip
              color='primary'
              size='small'
              label={`${comparisonData.driver.race} : ${comparisonData.mate.race}`}
            />
          </ComparisonPart>
          <ComparisonPart>
            <Typography variant='subtitle1'>Qualifying</Typography>
            <Divider variant='fullWidth' />
            <Space />
            <Chip
              color='primary'
              size='small'
              label={`${comparisonData.driver.quali} : ${comparisonData.mate.quali}`}
            />
          </ComparisonPart>
          <ComparisonPart>
            <Typography variant='subtitle1'>Wins</Typography>
            <Divider variant='fullWidth' />
            <Space />
            <Chip
              color='primary'
              size='small'
              label={`${comparisonData.driver.wins} : ${comparisonData.mate.wins}`}
            />
          </ComparisonPart>
          <ComparisonPart>
            <Typography variant='subtitle1'>Podien</Typography>
            <Divider variant='fullWidth' />
            <Space />
            <Chip
              color='primary'
              size='small'
              label={`${comparisonData.driver.podien} : ${comparisonData.mate.podien}`}
            />
          </ComparisonPart>
        </ComparisonContainer>
      </CardContent>
    </Card>
  );
};

const ComparisonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: var(--srmPadding);
  justify-items: center;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const ComparisonPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  hr {
    width: 100%;
  }
`;

const Space = styled.span`
  height: 10px;
`;

const CompareDriverContainer = styled.div`
  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }
`;
