import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  TableSortLabel,
  Menu,
  MenuItem,
} from '@mui/material';
import styled from 'styled-components';
import { useState, useContext } from 'react';
import { LeagueStore } from '../../store';
import {
  Event,
  EventResult,
  Standing,
  Standing as StandingsIF,
} from '../../store/LeagueModel';
import { COUNTRY_FLAG_API_SMALL } from '../../App';
import { observer } from 'mobx-react-lite';
import { CenterModal } from '../../components/Modal/CenterModal';
import { useBreakpoint, Breakpoint } from '../../utils/breakpoints';
import { DriverComparison } from '../../components/DriverComparison/DriverComparison';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { DriverRating } from '../../components/DriverRating';

export enum DIFF_FILTER {
  LEADING = 'LEADING',
  INTERVAL = 'INTERVAL',
}

export enum STANDINGS_VIEW {
  DRIVER = 'DRIVER',
  TEAM = 'TEAM',
  AVERAGE = 'AVERAGE',
}

export enum AVGERAGE_SORT {
  RACE_DESC = 'RACE_DESC',
  RACE_ASC = 'RACE_ASC',
  QUALI_DESC = 'QUALI_DESC',
  QUALI_ASC = 'QUALI_ASC',
}

export const Standings = observer(() => {
  const leagueStore = useContext(LeagueStore);
  const [view, setView] = useState<STANDINGS_VIEW>(STANDINGS_VIEW.DRIVER);
  const [avgSort, setAvgSort] = useState<AVGERAGE_SORT>(
    AVGERAGE_SORT.RACE_DESC
  );
  const [activePart, setActivePart] = useState<StandingsIF | null>(null);
  const [diffFilter, setDiffFilter] = useState<DIFF_FILTER>(
    DIFF_FILTER.LEADING
  );
  const [diffMenu, setDiffMenu] = useState<HTMLElement | null>(null);

  const isSmallDesktop = useBreakpoint(Breakpoint.smallDesktop);
  const isTablet = useBreakpoint(Breakpoint.tablet);

  const handleSetDiffFilter = (filter: DIFF_FILTER) => {
    setDiffFilter(filter);
    handleDiffMenuClose();
  };

  const handleDiffMenuClose = () => {
    setDiffMenu(null);
  };

  const handleDiffMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDiffMenu(event.currentTarget);
  };

  const getSubtitle = () => {
    let subtitle = '';
    if (activePart) {
      if (view === STANDINGS_VIEW.DRIVER) {
        subtitle = activePart.teamName ? activePart.teamName : '';
      }
      if (view === STANDINGS_VIEW.TEAM) {
        activePart.drivers?.map((d, i) => {
          subtitle += d;
          if (activePart.drivers && activePart.drivers.length > 1 && i === 0) {
            subtitle += ' & ';
          }
        });
      }
    }

    return subtitle;
  };

  return (
    <Container>
      <Tabs
        variant='fullWidth'
        value={view}
        indicatorColor='primary'
        textColor='secondary'
        onChange={(_, value) => setView(value)}
        aria-label='standing tabs'
      >
        <Tab label='Fahrer' value={STANDINGS_VIEW.DRIVER} />
        <Tab label='Teams' value={STANDINGS_VIEW.TEAM} />
        <Tab
          label={isTablet ? 'Durchschnitt' : 'Schnitt'}
          value={STANDINGS_VIEW.AVERAGE}
        />
      </Tabs>
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align='center'>
                <Typography variant='body1' component='p'>
                  #
                </Typography>
              </TableCell>

              <TableCell align='left'>
                <Typography variant='body1' component='p'>
                  {view === STANDINGS_VIEW.TEAM ? 'Team' : 'Fahrer'}
                </Typography>
              </TableCell>
              {view !== STANDINGS_VIEW.AVERAGE && (
                <TableCell align='center'>
                  <LastRacesContainer amount={isSmallDesktop ? 5 : 3}>
                    {leagueStore
                      .getLastEvents(isSmallDesktop ? 5 : 3)
                      .map((ev: Event, i: number) => {
                        return (
                          <img
                            src={`${COUNTRY_FLAG_API_SMALL}/${ev.countryCode.toLowerCase()}.png`}
                            alt={ev.countryCode}
                            key={ev.date + i}
                          />
                        );
                      })}
                  </LastRacesContainer>
                </TableCell>
              )}
              {view !== STANDINGS_VIEW.AVERAGE && (
                <>
                  <TableCell align='center'>
                    <Typography variant='body1' component='p'>
                      Pkt.
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <DiffContainer>
                      <Typography
                        variant='body1'
                        component='span'
                        onClick={handleDiffMenuOpen}
                      >
                        Diff.
                      </Typography>
                      <KeyboardArrowDownIcon fontSize='small' sx={{}} />
                    </DiffContainer>
                  </TableCell>
                </>
              )}

              {view === STANDINGS_VIEW.AVERAGE && (
                <>
                  <TableCell
                    align='center'
                    sortDirection={
                      avgSort.toLowerCase().includes('desc') ? 'desc' : 'asc'
                    }
                  >
                    <TableSortLabel
                      active={
                        avgSort === AVGERAGE_SORT.RACE_ASC ||
                        avgSort === AVGERAGE_SORT.RACE_DESC
                      }
                      direction={
                        avgSort.toLowerCase().includes('desc') ? 'desc' : 'asc'
                      }
                      onClick={() =>
                        setAvgSort(
                          avgSort === AVGERAGE_SORT.QUALI_ASC
                            ? AVGERAGE_SORT.RACE_ASC
                            : avgSort === AVGERAGE_SORT.QUALI_DESC
                            ? AVGERAGE_SORT.RACE_DESC
                            : avgSort === AVGERAGE_SORT.RACE_ASC
                            ? AVGERAGE_SORT.RACE_DESC
                            : AVGERAGE_SORT.RACE_ASC
                        )
                      }
                    >
                      <Typography variant='body1' component='p'>
                        {isTablet ? 'Rennen ∅' : 'R ∅'}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align='center'
                    sortDirection={
                      avgSort.toLowerCase().includes('desc') ? 'desc' : 'asc'
                    }
                  >
                    <TableSortLabel
                      active={
                        avgSort === AVGERAGE_SORT.QUALI_ASC ||
                        avgSort === AVGERAGE_SORT.QUALI_DESC
                      }
                      direction={
                        avgSort.toLowerCase().includes('desc') ? 'desc' : 'asc'
                      }
                      onClick={() =>
                        setAvgSort(
                          avgSort === AVGERAGE_SORT.RACE_ASC
                            ? AVGERAGE_SORT.QUALI_ASC
                            : avgSort === AVGERAGE_SORT.RACE_DESC
                            ? AVGERAGE_SORT.QUALI_DESC
                            : avgSort === AVGERAGE_SORT.QUALI_ASC
                            ? AVGERAGE_SORT.QUALI_DESC
                            : AVGERAGE_SORT.QUALI_ASC
                        )
                      }
                    >
                      <Typography variant='body1' component='p'>
                        {isTablet ? 'Qualifying ∅' : 'Q ∅'}
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {leagueStore
              .getStandingsByView(view)
              .slice()
              .sort((stand1: StandingsIF, stand2: StandingsIF) => {
                if (
                  view === STANDINGS_VIEW.AVERAGE &&
                  stand1.avgRace &&
                  stand1.avgQuali &&
                  stand2.avgRace &&
                  stand2.avgQuali
                ) {
                  // ASC = Aufsteigend = Pfeil hoch
                  // DESC = Absteigend = Pfeil runter
                  switch (avgSort) {
                    case AVGERAGE_SORT.RACE_ASC:
                      return stand2.avgRace - stand1.avgRace;
                    case AVGERAGE_SORT.RACE_DESC:
                      return stand1.avgRace - stand2.avgRace;
                    case AVGERAGE_SORT.QUALI_ASC:
                      return stand2.avgQuali - stand1.avgQuali;
                    case AVGERAGE_SORT.QUALI_DESC:
                      return stand1.avgQuali - stand2.avgQuali;
                  }
                }
                return 0;
              })
              .map((stand: Standing, index: number) => {
                return (
                  <TableRow
                    key={`${stand.name}-${index}`}
                    onClick={() => setActivePart(stand)}
                  >
                    <TableCell align='center'>
                      <Typography variant='body1'>{index + 1}.</Typography>
                    </TableCell>
                    <TableCell align='left'>
                      {view !== STANDINGS_VIEW.TEAM && (
                        <DriverContainer>
                          <div>
                            <Typography variant='body2'>
                              {stand.name}{' '}
                              <Red>
                                {view === STANDINGS_VIEW.DRIVER &&
                                  `(${stand.penaltyPoints})`}
                              </Red>
                            </Typography>
                            <TeamName variant='body2'>
                              <span style={{ color: stand.color }}>
                                {stand.carNumber}
                              </span>
                              {' - '} {stand.teamName}
                            </TeamName>
                          </div>
                        </DriverContainer>
                      )}
                      {view === STANDINGS_VIEW.TEAM && (
                        <StyledTypography
                          bordercolor={stand.color}
                          variant='body2'
                        >
                          {stand.name}
                        </StyledTypography>
                      )}
                    </TableCell>
                    <TableCell align='center'>
                      {view !== STANDINGS_VIEW.AVERAGE ? (
                        <LastRacesContainer amount={isSmallDesktop ? 5 : 3}>
                          {leagueStore
                            .getLastEvents(isSmallDesktop ? 5 : 3)
                            .map((ev: Event, i: number) => {
                              if (ev.result && view === STANDINGS_VIEW.TEAM) {
                                return (
                                  <Grey
                                    variant='body2'
                                    key={ev._id}
                                  >
                                    {leagueStore.getTeamPointsFromEvent(
                                      stand.name,
                                      ev.track,
                                      ev.date
                                    )}
                                  </Grey>
                                );
                              } else if (
                                ev.result &&
                                view === STANDINGS_VIEW.DRIVER
                              ) {
                                const result = ev.result?.find(
                                  (r: EventResult) => r.name === stand.name
                                );
                                if (result) {
                                  return (
                                    <Grey
                                      variant='body2'
                                      key={ev._id}
                                    >
                                      {result.points}
                                    </Grey>
                                  );
                                } else {
                                  return (
                                    <Grey
                                      key={ev._id}
                                      variant='body2'
                                    >
                                      -
                                    </Grey>
                                  );
                                }
                              } else {
                                return (
                                  <Grey
                                    key={ev._id}
                                    variant='body2'
                                  >
                                    -
                                  </Grey>
                                );
                              }
                            })}
                        </LastRacesContainer>
                      ) : (
                        <Typography variant='body2'>{stand.avgRace}</Typography>
                      )}
                    </TableCell>
                    <TableCell align='center'>
                      {view !== STANDINGS_VIEW.AVERAGE ? (
                        <Typography variant='body2'>{stand.points}</Typography>
                      ) : (
                        <Typography variant='body2'>
                          {stand.avgQuali}
                        </Typography>
                      )}
                    </TableCell>
                    {view !== STANDINGS_VIEW.AVERAGE && (
                      <TableCell align='center'>
                        <Typography variant='body2'>
                          <Red>
                            {index === 0
                              ? '-'
                              : diffFilter === DIFF_FILTER.LEADING
                              ? stand.points -
                                leagueStore.getStandingsByView(view)[0].points
                              : stand.points -
                                leagueStore.getStandingsByView(view)[index - 1]
                                  .points}
                          </Red>
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </StyledTableContainer>

      {activePart && (
        <CenterModal
          isOpen={Boolean(activePart)}
          handleClose={() => setActivePart(null)}
          title={activePart ? activePart.name : ''}
          subtitle={getSubtitle()}
          backgroundColor={activePart.color}
          textColor='#fff'
        >
          {view !== STANDINGS_VIEW.TEAM && (
            <Column>
              <DriverRating rating={activePart?.rating} />
              <DriverComparison
                driver={activePart.name}
                mate={
                  activePart.drivers?.filter((d) => d !== activePart.name)[0]
                }
              />
              <Card>
                <CardContent>
                  <Row>
                    <Info>
                      <Typography variant='body1'>Rennen</Typography>
                      <Typography variant='h6'>
                        {leagueStore.getHistoryByDriver(activePart.name)
                          .races || 0}
                      </Typography>
                    </Info>
                    <Info>
                      <Typography variant='body1'>Wins</Typography>
                      <Typography variant='h6'>
                        {leagueStore.getHistoryByDriver(activePart.name).wins ||
                          0}
                      </Typography>
                    </Info>
                    <Info>
                      <Typography variant='body1'>Podien</Typography>
                      <Typography variant='h6'>
                        {leagueStore.getHistoryByDriver(activePart.name)
                          .podien || 0}
                      </Typography>
                    </Info>
                    <Info>
                      <Typography variant='body1'>Titel</Typography>
                      <Typography variant='h6'>
                        {leagueStore.getHistoryByDriver(activePart.name)
                          .titles || 0}
                      </Typography>
                    </Info>
                  </Row>
                </CardContent>
              </Card>
              {leagueStore.getLastEvents(1).length > 0 && (
                <Card>
                  <CardContent>
                    <Row>
                      {leagueStore
                        .getLastEvents(isSmallDesktop ? 8 : 5)
                        .map((e: Event, i: number) => {
                          const result = e.result?.find(
                            (r) => r.name === activePart.name
                          );

                          return (
                            <ShortProfileResults key={e.date + i}>
                              <img
                                src={`${COUNTRY_FLAG_API_SMALL}/${e.countryCode.toLowerCase()}.png`}
                                alt={e.countryCode}
                                
                              />
                              <Typography variant='body2' component='p'>
                                {result ? (
                                  <>
                                    {result.points}
                                    <GreySpan>{` (${result.place}.)`}</GreySpan>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </Typography>
                            </ShortProfileResults>
                          );
                        })}
                    </Row>
                  </CardContent>
                </Card>
              )}
            </Column>
          )}
        </CenterModal>
      )}
      <Menu
        id='diff-menu'
        anchorEl={diffMenu}
        keepMounted
        open={Boolean(diffMenu)}
        onClose={handleDiffMenuClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => handleSetDiffFilter(DIFF_FILTER.LEADING)}
          selected={diffFilter === DIFF_FILTER.LEADING}
        >
          Führenden
        </MenuItem>
        <MenuItem
          onClick={() => handleSetDiffFilter(DIFF_FILTER.INTERVAL)}
          selected={diffFilter === DIFF_FILTER.INTERVAL}
        >
          Interval
        </MenuItem>
      </Menu>
    </Container>
  );
});

interface TableCellProps {
  bordercolor: string;
}

interface LastRacesProps {
  amount: number;
}

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
`;

const StyledTableContainer = styled.div`
  overflow-y: auto;

  .MuiTableCell-root {
    padding: 12px 6px;

    &:first-child {
      padding-right: 4px;
      padding-left: 4px;
    }

    .MuiTableSortLabel-root .MuiTableSortLabel-icon,
    .MuiTableSortLabel-root {
      color: var(--srmBackground);
    }

    .Mui-active,
    .Mui-active .MuiTableSortLabel-icon {
      color: var(--srmGreen);
    }
  }
`;

const StyledTypography = styled(Typography)<TableCellProps>`
  border-left: 4px solid
    ${(props) => (props.bordercolor ? props.bordercolor : '#fff')};
  padding-left: 10px;
`;

const LastRacesContainer = styled.div<LastRacesProps>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.amount}, 1fr)`};
  align-items: center;
  justify-items: center;
  grid-gap: 3px;

  @media only screen and (min-width: 991px) {
    grid-template-columns: ${(props) => `repeat(${props.amount}, 1fr)`};
  }
`;

const Column = styled.div`
  display: grid;
  grid-gap: var(--srmPaddingBig);
  padding: 25px 0 0;

  .MuiCardHeader-root {
    padding-bottom: 4px;
  }
`;

const Row = styled.div`
  display: flex;
  gap: var(--srmPadding);
  justify-content: space-evenly;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const ShortProfileResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DriverContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const TeamName = styled(Typography)`
  color: var(--srmGreyLight);
`;

const Red = styled.span`
  color: var(--srmRed);
`;

const GreySpan = styled.span`
  color: var(--srmGreyLight);
`;

const Grey = styled(Typography)`
  color: var(--srmGreyLight);
`;

const DiffContainer = styled.div`
  display: flex;
  align-items: center;
`;
