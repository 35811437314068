import styled from 'styled-components';

export const FabContainer = styled.div`
  position: absolute;
  z-index: 999;
  bottom: var(--srmPadding);
  right: var(--srmPadding);

  @media only screen and (min-width: 768px) {
    position: fixed;
  }
`;
