import { RacingType } from '../store/LeagueModel';

import f1 from '../assets/icons/f1.png';
import f2 from '../assets/icons/f2.png';

export const getRacingTypeLogo = (racingType: RacingType) => {
  if (racingType === RacingType.F1) {
    return f1;
  } else {
    return f2;
  }
};
