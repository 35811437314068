import styled from 'styled-components';
import { TextField, Button } from '@mui/material';

import { Modal } from '../components/Modal/Modal';
import { FormEvent, useContext, useState } from 'react';
import { UserStore } from '../store';
import { useHistory } from 'react-router-dom';

interface RegisterProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const Register = ({ isOpen, handleClose }: RegisterProps) => {
  const userStore = useContext(UserStore);
  const history = useHistory();

  const [name, setName] = useState<string>('');
  const [pw, setPw] = useState<string>('');
  const [pw2, setPw2] = useState<string>('');

  const handleRegister = async (event: FormEvent) => {
    event.preventDefault();
    const isRegistered = await userStore.register(name, pw);

    if (isRegistered) {
      setTimeout(() => {
        history.push('/home');
      }, 300);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title='Registriere dich jetzt'
      subtitle='Deine Liga wartet auf dich'
    >
      <Inputs onSubmit={handleRegister}>
        <TextField
          type='text'
          color='secondary'
          value={name}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setName(event.target.value)}
          label='PSN-ID'
          placeholder='wie heißt du bei PSN ?'
        />
        <TextField
          type='password'
          color='secondary'
          value={pw}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setPw(event.target.value)}
          label='Passwort'
        />
        <TextField
          type='password'
          color='secondary'
          value={pw2}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setPw2(event.target.value)}
          label='Passwort wiederholen'
          error={Boolean(pw2 && pw !== pw2)}
          helperText={
            pw2 && pw !== pw2 ? 'Passwörter müssen identisch sein' : ''
          }
        />
        <Button
          type='submit'
          color='primary'
          variant='contained'
          disabled={!name || !pw || !pw2}
        >
          Registrieren
        </Button>
        <Button color='secondary' variant='outlined' onClick={handleClose}>
          Schließen
        </Button>
      </Inputs>
    </Modal>
  );
};

const Inputs = styled.form`
  display: grid;
  width: 100%;
  flex-direction: column;
  grid-gap: var(--srmPadding);
`;
