import styled from 'styled-components';
import {
  Typography,
  Divider,
  CardHeader,
  Avatar,
  Card,
  CardContent,
  IconButton,
} from '@mui/material';
import { Event } from '../../components/Event/Event';
import { LeagueStore } from '../../store';
import { useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { Pagination, Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import {
  PageContentNews,
  ScrollingItemsContainerRows,
} from '../../components/Content';

import incidentIconCreated from '../../assets/icons/incidentCreated.jpeg';
import penaltyIcon from '../../assets/icons/penalty.jpeg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router-dom';
import { NAVIGATION_PATH } from '../../utils/navigation';
import { Headline } from '../../components/Headline';

export const News = observer(() => {
  const leagueStore = useContext(LeagueStore);
  const history = useHistory();
  const newsDivisions = leagueStore.newsDivisions;

  return (
    <PageContentNews>
      <ScrollingItemsContainerRows>
        {leagueStore.getIncidentsAgainstMe.length > 0 && (
          <div>
            <Typography variant='h6' component='p'>
              Aktuelle Vorfälle
            </Typography>
            <Divider />
            <br />
            <Card>
              <CardHeader
                avatar={<Avatar variant='rounded' src={incidentIconCreated} />}
                title='Oh oh! 😱'
                subheader='Es gibt Vofälle mit dir'
                action={
                  <IconButton
                    onClick={() => history.push(NAVIGATION_PATH.INCIDENTS)}
                    size='large'
                  >
                    <ChevronRightIcon htmlColor='var(--srmRed)' />
                  </IconButton>
                }
              ></CardHeader>
              <CardContent>
                <Typography variant='body1' component='p'>
                  Bitte bearbeite deine Vorfälle! Es fehlt uns deine Sicht zu{' '}
                  <Red>{leagueStore.getIncidentsAgainstMe.length}</Red>{' '}
                  Vorfällen!
                </Typography>
              </CardContent>
            </Card>
          </div>
        )}

        {leagueStore.lastRacePenalties.length > 0 && (
          <div>
            <Typography variant='h6' component='p'>
              Neue Strafen
            </Typography>
            <Divider />
            <br />
            <Card>
              <CardHeader
                avatar={<Avatar variant='rounded' src={penaltyIcon} />}
                title='Oh Gott! 🤯'
                subheader='Du hast neue Strafen'
                action={
                  <IconButton
                    onClick={() => history.push(NAVIGATION_PATH.PENALTIES)}
                    size='large'
                  >
                    <ChevronRightIcon htmlColor='var(--srmRed)' />
                  </IconButton>
                }
              ></CardHeader>
              <CardContent>
                <Typography variant='body1' component='p'>
                  Dir wurden im letzten Rennen{' '}
                  <Red>{leagueStore.lastRacePenalties.length}</Red> Strafe(n)
                  zugeteilt oder aberkannt! Bitte sieh dir diese an
                </Typography>
              </CardContent>
            </Card>
          </div>
        )}

        {leagueStore.currentLeague && (
          <>
            {leagueStore.getLastEvents().length > 0 && (
              <SwiperContainer>
                <Headline title='Ergebnisse' size='large' enablePadding />
                <Swiper
                  grabCursor={true}
                  slidesPerView={1}
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                    clickable: true,
                    renderBullet: (index, className) =>
                      `<span class='${className}'>${newsDivisions[index].shortName}</span>`,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className='news-slider'
                >
                  {newsDivisions.map((div) => {
                    return leagueStore.getLastEvents(1, div._id).map((e, i) => {
                      if (e.result) {
                        return (
                          <SwiperSlide key={div._id}>
                            <Event divId={div._id} key={e.date} event={e} />
                          </SwiperSlide>
                        );
                      }
                    });
                  })}
                </Swiper>
              </SwiperContainer>
            )}
            {leagueStore.getNextEvents().length > 0 && (
              <SwiperContainer>
                <Headline title='Nächste Events' size='large' enablePadding />
                <Swiper
                  grabCursor={true}
                  slidesPerView={1}
                  spaceBetween={30}
                  loop={true}
                  pagination={{
                    clickable: true,
                    renderBullet: (index, className) =>
                      `<span class='${className}'>${newsDivisions[index].shortName}</span>`,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className='news-slider'
                >
                  {leagueStore.newsDivisions.map((div) => {
                    return leagueStore.getNextEvents(1, div._id).map((e, i) => {
                      return (
                        <SwiperSlide key={div._id}>
                          <Event divId={div._id} key={e.date} event={e} />
                        </SwiperSlide>
                      );
                    });
                  })}
                </Swiper>
              </SwiperContainer>
            )}
          </>
        )}
      </ScrollingItemsContainerRows>
    </PageContentNews>
  );
});

const Red = styled.span`
  color: var(--srmRed);
`;

export const SwiperContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: var(--srmPadding);
`;
