import {
  Typography,
  IconButton,
  NativeSelect,
  Button,
  Chip,
  Menu,
  MenuItem,
  Input,
  InputAdornment,
  FormControl,
  InputLabel,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FullHeightModal } from '../../../components/Modal/FullHeightModal';

import {
  EventResult,
  EventType,
  EventUser,
  Event_Calendar,
  RacingType,
} from '../../../store/LeagueModel';
import { COUNTRY_FLAG_API_BIG } from '../../../App';
import { LeagueStore } from '../../../store';
import { CenterModal } from '../../../components/Modal/CenterModal';
import { EditEventDriver } from './EditEventDriver';
import { observer } from 'mobx-react-lite';

import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { DateTimePicker } from '@mui/lab';

import { getUnixTime } from 'date-fns';

interface EditEventProps {
  currentEvent: Event_Calendar;
  onClose: () => void;
}

export const EditEvent = observer(
  ({ currentEvent, onClose }: EditEventProps) => {
    const leagueStore = useContext(LeagueStore);
    const [createNewDriver, setCreateNewDriver] = useState<boolean>(false);
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const [date, setDate] = useState<Date | null>(null);
    const [isProvisionalResult, setIsProvisionalResult] =
      useState<boolean>(false);

    const [newDriver, setNewDriver] = useState<string>('');
    const [highlightLink, setHighlightLink] = useState<string>('');
    const [newTeam, setNewTeam] = useState<string>('');
    const [results, setResults] = useState<EventResult[]>([]);
    const [menuContent, setMenuContent] = useState<EventUser[]>([]);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [isResultChanged, setIsResultChanged] = useState<boolean>(false);

    const handleResultSave = () => {
      if (currentEvent) {
        leagueStore.updateEvent(currentEvent.divisionId, {
          _id: currentEvent._id,
          track: currentEvent?.track,
          date: date ? getUnixTime(date) : currentEvent?.date,
          type: currentEvent.type,
          highlightLink,
          result: isResultChanged ? results : [],
          isProvisionalResult,
        });

        onClose();
      }
    };

    const handleRemoveEvent = () => {
      leagueStore.removeEvent(currentEvent.divisionId, currentEvent._id);
      handleClose();
    };

    const handleClose = () => {
      setResults([]);
      onClose();
      setNewDriver('');
      setNewTeam('');
      setCreateNewDriver(false);
      setIsResultChanged(false);
    };

    const handleAddNewDriver = () => {
      const newResults = results.slice();

      newResults.push({
        name: newDriver,
        team: newTeam,
        place: 0,
        points: 0,
        quali: null,
        time: '',
        hasFastestLap: false,
        isDnfOrDsq: false,
      });

      setIsResultChanged(true);
      setResults(newResults);
      setCreateNewDriver(false);
    };

    const removeDriver = (index: number) => {
      const newResults = results.slice();
      newResults.splice(index, 1);

      setIsResultChanged(true);
      setResults(newResults);
    };

    const handleUpdateDriver = (data: EventResult, index: number) => {
      const newResults = results.slice();
      newResults[index] = data;

      setResults(newResults);
      setIsResultChanged(true);
    };

    const handleShowMenuContent = (
      event: React.MouseEvent<HTMLButtonElement>,
      data: EventUser[]
    ) => {
      setMenuAnchor(event.currentTarget);
      setMenuContent(data);
    };

    const handleCloseMenuContent = () => {
      setMenuAnchor(null);
      setMenuContent([]);
    };

    useEffect(() => {
      if (currentEvent) {
        setDate(new Date(currentEvent.date * 1000));
        setHighlightLink(currentEvent.highlightLink);
        if (currentEvent.result && currentEvent.result.length > 0) {
          setResults(currentEvent.result);
          setIsResultChanged(true);
        } else {
          setResults(
            currentEvent.commitments.map((c, i) => {
              return {
                name: c.driver,
                team: c.team,
                place: i + 1,
                points: 0,
                quali: null,
                time: '',
                hasFastestLap: false,
                isDnfOrDsq: false,
              };
            })
          );
        }
      }
    }, [currentEvent]);

    return (
      <FullHeightModal
        isOpen={Boolean(currentEvent)}
        onClose={handleClose}
        title={
          currentEvent.racingType === RacingType.F2 &&
          currentEvent.type === EventType.SPRINT
            ? `${currentEvent.track} - Sprint`
            : currentEvent.racingType === RacingType.F2
            ? `${currentEvent.track} - Haupt`
            : currentEvent.track || ''
        }
        subtitle={currentEvent.divisionShortName}
        icon={`${COUNTRY_FLAG_API_BIG}/${currentEvent?.countryCode.toLowerCase()}.png`}
      >
        <Container>
          <ParticipantsContainer>
            <Chip
              label={`Zusagen: ${currentEvent.commitments.length}`}
              color='secondary'
              onClick={(event: any) => {
                handleShowMenuContent(event, currentEvent.commitments);
              }}
            />
            <Chip
              label={`Absagen: ${currentEvent.cancellations.length}`}
              color='secondary'
              onClick={(event: any) => {
                handleShowMenuContent(event, currentEvent.cancellations);
              }}
            />
            <Menu
              anchorEl={menuAnchor}
              keepMounted
              open={Boolean(menuAnchor)}
              onClose={handleCloseMenuContent}
            >
              {menuContent.map((c) => {
                return (
                  <MenuItem dense divider>
                    {`${c.driver} - ${c.team} ${
                      c.note?.includes('spät') ? ' - zu spät' : ''
                    }`}
                  </MenuItem>
                );
              })}
            </Menu>
          </ParticipantsContainer>
          <Accordion defaultExpanded disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h6'>Informationen</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DateTimePicker
                open={showDatePicker}
                onAccept={() => setShowDatePicker(!showDatePicker)}
                onClose={() => setShowDatePicker(!showDatePicker)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    fullWidth
                    color='secondary'
                    size='small'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={() => setShowDatePicker(!showDatePicker)}
                          >
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                label='Datum und Uhrzeit'
                inputFormat='dd.MM.yy | HH:mm'
                mask='__.__.__ | __:__'
                value={date}
                onChange={(value) => setDate(value)}
                ampm={false}
                cancelText='Abbrechen'
              />

              <br />
              <br />

              <FormControl fullWidth color='secondary'>
                <InputLabel htmlFor='highlightLink'>Highlight Link</InputLabel>
                <Input
                  id='highlightLink'
                  color='secondary'
                  type='text'
                  fullWidth
                  value={highlightLink}
                  onChange={(event) => setHighlightLink(event.target.value)}
                  endAdornment={
                    <InputAdornment position='start'>
                      <YouTubeIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <EventResults>
            <Accordion defaultExpanded disableGutters>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'>Ergebniss</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Button
                  fullWidth
                  onClick={() => setCreateNewDriver(true)}
                  variant='outlined'
                  color='secondary'
                >
                  Fahrer ergänzen
                </Button>
                <br />
                <br />
                <FormGroup row>
                  <FormControlLabel
                    value={isProvisionalResult}
                    control={
                      <Checkbox
                        color='primary'
                        onChange={() =>
                          setIsProvisionalResult(!isProvisionalResult)
                        }
                      />
                    }
                    label='Vorläufiges Ergebnis ?'
                    labelPlacement='end'
                  />
                </FormGroup>
                <br />
                <ResultList>
                  <List disablePadding dense>
                    {results.slice().map((r, index) => {
                      return (
                        <EditEventDriver
                          key={r.name}
                          result={r}
                          index={index}
                          handleSave={handleUpdateDriver}
                          handleRemove={removeDriver}
                        />
                      );
                    })}
                  </List>
                </ResultList>
              </AccordionDetails>
            </Accordion>
            <br />
            <Button
              fullWidth
              onClick={handleResultSave}
              variant='contained'
              color='primary'
            >
              Event Speichern
            </Button>
            <br />
            <br />
            <Button
              fullWidth
              onClick={handleRemoveEvent}
              variant='contained'
              color='error'
              disabled={
                (currentEvent.result && currentEvent.result.length > 0) ||
                !leagueStore.isAdmin()
              }
            >
              Event löschen
            </Button>
          </EventResults>
        </Container>

        {/* Add new driver modal */}
        <CenterModal
          isOpen={createNewDriver}
          handleClose={() => setCreateNewDriver(false)}
        >
          <AddDriverContainer>
            <NativeSelect
              id='new-driver'
              fullWidth
              onChange={(event) => setNewDriver(event.target.value)}
              value={newDriver}
              placeholder='Wer ist noch mitgefahren ?'
              required
            >
              <option value='' disabled>
                Fahrer wählen
              </option>
              {leagueStore.getAllDriver().map((d, index) => {
                return (
                  <option key={`${d.name}-${index}`} value={d.name}>
                    {d.name}
                  </option>
                );
              })}
            </NativeSelect>
            <br />
            <NativeSelect
              id='new-team'
              fullWidth
              onChange={(event) => setNewTeam(event.target.value)}
              value={newTeam}
              placeholder='In welchem Team ?'
              required
            >
              <option value='' disabled>
                Team wählen
              </option>
              {leagueStore.getAllTeamNames(currentEvent.divisionId).map((t) => {
                return (
                  <option key={t} value={t}>
                    {t}
                  </option>
                );
              })}
            </NativeSelect>
            <br />
            <Button
              onClick={handleAddNewDriver}
              variant='contained'
              color='primary'
              disabled={!newDriver || !newTeam}
            >
              Ergänzen
            </Button>
          </AddDriverContainer>
        </CenterModal>
      </FullHeightModal>
    );
  }
);

const EventResults = styled.div`
  flex: 1;

  @media only screen and (min-width: 768px) {
    padding-right: 0;
  }
`;

const ResultList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--srmPaddingBig);
`;

const AddDriverContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-gap: var(--srmPadding);
  padding-bottom: var(--srmPadding);
`;

const ParticipantsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--srmPadding);
`;
