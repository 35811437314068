import { Modal } from '../Modal/Modal';
import styled from 'styled-components';
import { DriverType, EventUser } from '../../store/LeagueModel';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { useContext } from 'react';
import { LeagueStore } from '../../store';

import { observer } from 'mobx-react-lite';
import { SRMTooltip } from '../SRMTooltip';

import InfoIcon from '@mui/icons-material/Info';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import { getTeamBackground } from '../../utils/getTeamBackground';
import { DriverTypeMapping } from '../../pages/admin/Drivers/DriversOverview';

interface CommitModal {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  flag: string;
  participants: EventUser[];
  cancellations: EventUser[];
}

export const ParticipantModal = observer(
  ({
    isOpen,
    onClose,
    title,
    subtitle,
    flag,
    participants,
    cancellations,
  }: CommitModal) => {
    const leagueStore = useContext(LeagueStore);

    return (
      <Modal
        isOpen={isOpen}
        handleClose={onClose}
        title={title}
        flag={flag}
        subtitle={subtitle}
      >
        <Content>
          <Accordion defaultExpanded disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h6'>Dabei sind</Typography>
              <Typography variant='subtitle1' component='p'>
                ({participants.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List disablePadding dense>
                {participants.map((p, index) => {
                  const dr = leagueStore.getDriverByName(p.driver);
                  return (
                    <ListItem
                      key={`${p.driver}-${index}`}
                      divider
                      dense
                      disablePadding
                    >
                      <Img src={getTeamBackground(p.team)} alt='team' />
                      <ListItemText
                        primary={p.driver}
                        secondary={`${dr?.carNumber || 0} | ${
                          DriverTypeMapping[
                            dr?.driverType || DriverType.REGULAR
                          ]
                        }`}
                      />
                      <ListItemActions>
                        <ThumbUpIcon htmlColor='var(--srmGreen)' />
                        {dr?.driverType === DriverType.SUBSTITUTE ? (
                          <SRMTooltip
                            text={'Der Fahrer ist ein Ersatzfahrer'}
                            icon={<ChangeCircleIcon color='info' />}
                          />
                        ) : (
                          <ChangeCircleIcon htmlColor='var(--srmGreyLight)' />
                        )}
                        <AlarmOffIcon htmlColor='var(--srmGreyLight)' />
                        {p.note ? (
                          <SRMTooltip
                            text={p.note}
                            size='medium'
                            color='var(--srmGrey)'
                          />
                        ) : (
                          <InfoIcon htmlColor='var(--srmGreyLight)' />
                        )}
                      </ListItemActions>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion defaultExpanded disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='h6'>Abgesagt haben</Typography>
              <Typography variant='subtitle1' component='p'>
                ({cancellations.length})
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <List disablePadding dense>
                {cancellations.map((c, index) => {
                  const dr = leagueStore.getDriverByName(c.driver);
                  return (
                    <ListItem
                      key={`${c.team}-${index}`}
                      divider
                      dense
                      disablePadding
                    >
                      <Img src={getTeamBackground(c.team)} alt='team' />

                      <ListItemText
                        primary={c.driver}
                        secondary={`${dr?.carNumber || 0} | ${
                          DriverTypeMapping[
                            dr?.driverType || DriverType.REGULAR
                          ]
                        }`}
                      />
                      <ListItemActions>
                        <ThumbDownIcon htmlColor='var(--srmRed)' />
                        {dr?.driverType === DriverType.SUBSTITUTE ? (
                          <SRMTooltip
                            text={'Der Fahrer ist ein Ersatzfahrer'}
                            icon={
                              <ChangeCircleIcon htmlColor={'var(--srmGrey)'} />
                            }
                          />
                        ) : (
                          <ChangeCircleIcon htmlColor='var(--srmGreyLight)' />
                        )}
                        {c.note?.includes('(zu spät)') ? (
                          <SRMTooltip
                            text='Zu spät abgemeldet'
                            size='medium'
                            color='var(--srmGrey)'
                            icon={<AlarmOffIcon htmlColor='var(--srmRed)' />}
                          />
                        ) : (
                          <AlarmOffIcon htmlColor='var(--srmGreyLight)' />
                        )}

                        {c.note && c.note.replace('(zu spät)', '') ? (
                          <SRMTooltip
                            text={c.note.replace('(zu spät)', '')}
                            size='medium'
                            color='var(--srmGrey)'
                          />
                        ) : (
                          <InfoIcon htmlColor='var(--srmGreyLight)' />
                        )}
                      </ListItemActions>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </Content>
      </Modal>
    );
  }
);

const Content = styled.div`
  .MuiAccordion-root {
    box-shadow: none;
  }
  .MuiAccordion-root:before {
    height: 0px;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiAccordionSummary-root {
    padding-left: 0;
    padding-right: 0;
    align-items: center;

    h6 {
      margin-right: 5px;
    }

    p {
      padding-top: 2px;
      color: var(--srmGreyLight);
    }

    &:before {
      position: absolute;
      left: 0;
      bottom: 5px;
      right: 0;
      height: 1px;
      content: '';
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
`;

const ListItemActions = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 7px;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  max-height: 28px;
  margin-right: 8px;
  border-radius: 4px;
`;
