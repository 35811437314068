import { useEffect, useState } from 'react';
import { toPx } from './style';

export enum Breakpoint {
  largeSmartphone = 576,
  tablet = 768,
  smallDesktop = 1024,
  desktop = 1200,
  mediumDesktop = 1600,
  largeDesktop = 1920,
}

export const mediaMinWidth = {
  largeSmartphone: toPx(Breakpoint.largeSmartphone),
  tablet: toPx(Breakpoint.tablet),
  smallDesktop: toPx(Breakpoint.smallDesktop),
  desktop: toPx(Breakpoint.desktop),
  mediumDesktop: toPx(Breakpoint.mediumDesktop),
  largeDesktop: toPx(Breakpoint.largeDesktop),
};

export const mediaMaxWidth = {
  largeSmartphone: toPx(Breakpoint.largeSmartphone - 0.02),
  tablet: toPx(Breakpoint.tablet - 0.02),
  smallDesktop: toPx(Breakpoint.smallDesktop - 0.02),
  desktop: toPx(Breakpoint.desktop - 0.02),
  mediumDesktop: toPx(Breakpoint.mediumDesktop - 0.02),
  largeDesktop: toPx(Breakpoint.largeDesktop - 0.02),
};

/**
 * A hook to react on breakpoint changes.
 */
export const useBreakpoint = (breakpoint: Breakpoint): boolean => {
  const [matches, setMatches] = useState<boolean>(
    matchMedia(`(min-width: ${breakpoint}px)`).matches
  );

  useEffect(() => {
    const mm = matchMedia(`(min-width: ${breakpoint}px)`);
    const checkMatches = () => {
      setMatches(mm.matches);
    };

    mm.addEventListener('change', checkMatches);
    return () => mm.removeEventListener('change', checkMatches);
  }, [breakpoint]);

  return matches;
};
