import alfaromeo from '../assets/images/alfaromeo.jpeg';
import alphatauri from '../assets/images/alphatauri.jpeg';
import alpine from '../assets/images/alpine.jpeg';
import art from '../assets/images/art.jpeg';
import astonmartin from '../assets/images/astonmartin.jpeg';
import carlin from '../assets/images/carlin.jpeg';
import charouz from '../assets/images/charouz.jpeg';
import dams from '../assets/images/dams.jpeg';
import ferrari from '../assets/images/ferrari.jpeg';
import haas from '../assets/images/haas.jpeg';
import campos from '../assets/images/campos.jpeg';
import hitech from '../assets/images/hitech.jpeg';
import hwa from '../assets/images/hwa.jpeg';
import mclaren from '../assets/images/mclaren.jpeg';
import mercedes from '../assets/images/mercedes.jpeg';
import mp from '../assets/images/mp.jpeg';
import prema from '../assets/images/prema.jpeg';
import redbull from '../assets/images/redbull.jpeg';
import trident from '../assets/images/trident.jpeg';
import uni from '../assets/images/uni.jpeg';
import williams from '../assets/images/williams.jpeg';

import dhdl_logo from '../assets/images/dhdl-logo.jpeg';
import hl_logo from '../assets/images/hazer-logo.png';

export const getTeamBackground = (team: string) => {
  switch (team) {
    case 'Alfa Romeo':
      return alfaromeo;
    case 'DAMS':
      return dams;
    case 'UNI Virtuosi':
      return uni;
    case 'Alpine':
      return alpine;
    case 'Williams':
      return williams;
    case 'CAMPOS Racing':
      return campos;
    case 'Hitech Grand Prix':
      return hitech;
    case 'Alpha Tauri':
      return alphatauri;
    case 'McLaren':
      return mclaren;
    case 'Trident Racing':
      return trident;
    case 'Carlin Motorsport':
      return carlin;
    case 'Ferrari':
      return ferrari;
    case 'Haas':
      return haas;
    case 'Prema Powerteam':
      return prema;
    case 'Charouz Racing System':
      return charouz;
    case 'Aston Martin':
      return astonmartin;
    case 'ART Grand Prix':
      return art;
    case 'Red Bull':
      return redbull;
    case 'HWA Racelab':
      return hwa;
    case 'Mercedes':
      return mercedes;
    case 'MP Motorsport':
      return mp;
    default:
      return getLeagueLogo(team);
  }
};

export const getLeagueLogo = (leagueName: string) => {
  switch (leagueName) {
    case 'DHDL Racing League':
    case 'DHDL':
      return dhdl_logo; // Liga Logo
    case 'Hazer League':
    case 'HL':
      return hl_logo; // Liga Logo
    default:
      return dhdl_logo;
  }
};
