import styled from 'styled-components';
import { useContext, useState } from 'react';
import {
  Card,
  CardContent as BasicCardContent,
  CardActionArea,
  Button,
  CardHeader,
  Avatar,
} from '@mui/material';
import { UserStore } from '../store';
import { LeagueStore } from '../store';

import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { NAVIGATION_PATH } from '../utils/navigation';
import { observer } from 'mobx-react-lite';
import { JoinLeagueModal } from '../components/JoinLeague';
import { getLeagueLogo } from '../utils/getTeamBackground';

export const Home = observer(() => {
  const userStore = useContext(UserStore);
  const leagueStore = useContext(LeagueStore);
  const [showJoinModal, setShowJoinModal] = useState<boolean>(false);
  const history = useHistory();

  const handleLeagueClick = (id: string) => {
    if (id === leagueStore.currentLeague) {
    } else {
      leagueStore.getLeagueById(id);
    }

    history.push(NAVIGATION_PATH.NEWS);
  };

  return (
    <HomeContainer>
      <CardContainer>
        <Card>
          <CardContent>
            {userStore.getLeagues.map((l) => {
              return (
                <Card key={l.leagueId}>
                  <CardActionArea onClick={() => handleLeagueClick(l.leagueId)}>
                    <CardHeader
                      avatar={<Avatar src={getLeagueLogo(l.name)} />}
                      title={l.name}
                    />
                  </CardActionArea>
                </Card>
              );
            })}
            <Card className='wip'>
              <CardActionArea>
                <CardHeader
                  avatar={
                    <Avatar>
                      <AddIcon />
                    </Avatar>
                  }
                  title='Liga Gründen'
                />
              </CardActionArea>
            </Card>
          </CardContent>
        </Card>
      </CardContainer>
      <Button
        variant='outlined'
        color='secondary'
        onClick={() => setShowJoinModal(true)}
      >
        Liga Beitreten
      </Button>
      {showJoinModal && (
        <JoinLeagueModal
          isOpen={showJoinModal}
          handleClose={() => setShowJoinModal(false)}
        />
      )}
    </HomeContainer>
  );
});

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  justify-content: center;
  height: 100%;
  padding: var(--srmPaddingBig);

  .MuiButtonBase-root {
    width: fit-content;
    align-self: center;
  }
`;

const CardContainer = styled.div`
  display: flex;

  .MuiCard-root {
    flex: 1;
    .MuiButtonBase-root {
      width: 100%;
    }
  }
`;

const CardContent = styled(BasicCardContent)`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;
