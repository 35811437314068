import styled from 'styled-components';
import {
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
} from '@mui/material';

import logo from '../assets/images/app_logo.jpeg';
import { useState, useContext } from 'react';
import { LeagueStore, UserStore } from '../store';
import { observer } from 'mobx-react-lite';
import { Register } from './Register';
import { useHistory } from 'react-router-dom';
import { NAVIGATION_PATH } from '../utils/navigation';

export const Login = observer(() => {
  const userStore = useContext(UserStore);
  const history = useHistory();
  const leagueStore = useContext(LeagueStore);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState<boolean>(false);

  const login = async () => {
    setIsLoading(true);
    const isLoggedIn = await userStore.login(username, password);

    if (isLoggedIn) {
      if (userStore.getLeagues[0]) {
        leagueStore.getLeagueById(userStore.getLeagues[0].leagueId);
      }

      setTimeout(() => {
        setIsLoading(false);
        history.push(NAVIGATION_PATH.NEWS);
      }, 300);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Register
        isOpen={isRegisterOpen}
        handleClose={() => setIsRegisterOpen(false)}
      />

      <Container>
        <Logo src={logo} alt='logo' />
        <Card>
          <CardContent>
            <Typography variant='h5'>Anmelden</Typography>
            <Inputs>
              <TextField
                type='text'
                color='secondary'
                value={username}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => setUsername(event.currentTarget.value)}
                label='Benutzername'
                disabled={isLoading}
              />
              <TextField
                type='password'
                color='secondary'
                value={password}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ) => setPassword(event.currentTarget.value)}
                label='Passwort'
                disabled={isLoading}
              />
              <Button
                color='primary'
                variant='contained'
                size='large'
                onClick={login}
                disabled={!username || !password || isLoading}
              >
                Anmelden
              </Button>
              {/* Fehlermeldung */}
            </Inputs>
          </CardContent>
        </Card>
        <RegisterButton>
          <Typography variant='h6'>Du bist noch nicht registriert ?</Typography>
          <Button
            color='secondary'
            variant='outlined'
            onClick={() => setIsRegisterOpen(!isRegisterOpen)}
          >
            Hier registrieren
          </Button>
        </RegisterButton>
      </Container>
    </>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--srmPaddingBig);
  padding: var(--srmPaddingBig);

  @media only screen and (min-width: 768px) {
    .MuiCard-root {
      width: 500px;
      margin: 0 auto;
    }
  }
`;

const Logo = styled.img`
  align-self: center;
  height: 175px;
  width: 175px;
  border-radius: 5px;
`;

const Inputs = styled.div`
  display: grid;
  flex-direction: column;
  grid-gap: var(--srmPadding);
`;

const RegisterButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;
