import { useHistory, useLocation } from 'react-router-dom';
import {
  Typography,
  Tooltip,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer as MuiSwipeableDrawer,
  Avatar,
  IconButton,
} from '@mui/material';
import { NAVIGATION_PATH } from '../utils/navigation';
import { Breakpoint, mediaMinWidth, useBreakpoint } from '../utils/breakpoints';
import { useContext } from 'react';
import { LeagueStore, UserStore } from '../store';
import styled, { css } from 'styled-components';

import SettingsIcon from '@mui/icons-material/Settings';
import CachedIcon from '@mui/icons-material/Cached';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ErrorIcon from '@mui/icons-material/Error';
import PersonIcon from '@mui/icons-material/Person';

import TodayIcon from '@mui/icons-material/Today';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';

// based on league
import dhdl from '../assets/images/dhdl-logo.jpeg';
import { observer } from 'mobx-react-lite';
import { SRMTooltip } from './SRMTooltip';

interface NavigationProps {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: () => void;
}

export const Navigation = observer(
  ({ isOpen, handleClose, handleOpen }: NavigationProps) => {
    const isTablet = useBreakpoint(Breakpoint.tablet);
    const leagueStore = useContext(LeagueStore);
    const userStore = useContext(UserStore);
    const history = useHistory();
    const location = useLocation();

    const handleMenuClick = (path: NAVIGATION_PATH) => {
      history.push(path);
      handleClose();
    };

    const iOS =
      typeof navigator !== 'undefined' &&
      /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
      <SwipeableDrawer
        $collapsed={userStore.isNavigationCollapsed}
        variant={isTablet ? 'permanent' : 'temporary'}
        anchor={isTablet ? 'left' : 'right'}
        open={isOpen} // is used only on mobile or tablet screens
        onClose={handleClose}
        elevation={6}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        onOpen={handleOpen}
        transitionDuration={180}
        swipeAreaWidth={20}
      >
        <DrawerCurrentLeague>
          <>
            {leagueStore.currentLeague &&
              isTablet &&
              userStore.isNavigationCollapsed && <Avatar src={dhdl} />}
            {leagueStore.currentLeague &&
              (!userStore.isNavigationCollapsed || !isTablet) && (
                <Typography variant='h6'>{leagueStore.name}</Typography>
              )}
            <MenuButtonContainer>
              <Tooltip title='Liga wählen'>
                {!isTablet || (isTablet && !userStore.isNavigationCollapsed) ? (
                  <Button
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={() => handleMenuClick(NAVIGATION_PATH.HOME)}
                  >
                    Liga wählen&nbsp;
                    <CompareArrowsIcon />
                  </Button>
                ) : (
                  <IconButton
                    color='primary'
                    onClick={() => handleMenuClick(NAVIGATION_PATH.HOME)}
                  >
                    <CompareArrowsIcon />
                  </IconButton>
                )}
              </Tooltip>
            </MenuButtonContainer>
          </>
        </DrawerCurrentLeague>

        <DrawerContent>
          <List component='nav'>
            {leagueStore.currentLeague && (
              <>
                {((location.pathname.includes('manage') && !isTablet) ||
                  isTablet) && (
                  <ListItem
                    button
                    selected={location.pathname.includes(
                      NAVIGATION_PATH.PROFILE
                    )}
                    onClick={() => handleMenuClick(NAVIGATION_PATH.PROFILE)}
                  >
                    <SRMTooltip
                      text={userStore.isNavigationCollapsed ? 'Profil' : ''}
                      disableIcon
                    >
                      <ListItemIcon>
                        <PersonIcon htmlColor='#fff' />
                      </ListItemIcon>
                    </SRMTooltip>
                    {(!isTablet ||
                      (isTablet && !userStore.isNavigationCollapsed)) && (
                      <ListItemText primary='Profil' />
                    )}
                  </ListItem>
                )}

                {isTablet && <br />}
                <ListItem
                  button
                  selected={location.pathname.includes(NAVIGATION_PATH.NEWS)}
                  onClick={() => handleMenuClick(NAVIGATION_PATH.NEWS)}
                >
                  <SRMTooltip
                    text={userStore.isNavigationCollapsed ? 'News' : ''}
                    disableIcon
                  >
                    <ListItemIcon>
                      <FiberNewIcon htmlColor='#fff' />
                    </ListItemIcon>
                  </SRMTooltip>
                  {(!isTablet ||
                    (isTablet && !userStore.isNavigationCollapsed)) && (
                    <ListItemText primary='News' />
                  )}
                </ListItem>
                <ListItem
                  button
                  selected={location.pathname.includes(NAVIGATION_PATH.RULES)}
                  onClick={() => handleMenuClick(NAVIGATION_PATH.RULES)}
                >
                  <SRMTooltip
                    text={userStore.isNavigationCollapsed ? 'Regelwerk' : ''}
                    disableIcon
                  >
                    <ListItemIcon>
                      <MenuBookIcon htmlColor='#fff' />
                    </ListItemIcon>
                  </SRMTooltip>
                  {(!isTablet ||
                    (isTablet && !userStore.isNavigationCollapsed)) && (
                    <ListItemText primary='Regelwerk' />
                  )}
                </ListItem>
                {isTablet && (
                  <>
                    <ListItem
                      button
                      selected={location.pathname.includes(
                        NAVIGATION_PATH.STANDINGS
                      )}
                      onClick={() => handleMenuClick(NAVIGATION_PATH.STANDINGS)}
                    >
                      <SRMTooltip
                        text={userStore.isNavigationCollapsed ? 'Tabellen' : ''}
                        disableIcon
                      >
                        <ListItemIcon>
                          <FormatListNumberedIcon htmlColor='#fff' />
                        </ListItemIcon>
                      </SRMTooltip>
                      {(!isTablet ||
                        (isTablet && !userStore.isNavigationCollapsed)) && (
                        <ListItemText primary='Tabellen' />
                      )}
                    </ListItem>
                    <ListItem
                      button
                      selected={
                        location.pathname.includes(NAVIGATION_PATH.EVENTS) &&
                        !location.pathname.includes(
                          NAVIGATION_PATH.MANAGE_EVENTS
                        )
                      }
                      onClick={() => handleMenuClick(NAVIGATION_PATH.EVENTS)}
                    >
                      <SRMTooltip
                        text={userStore.isNavigationCollapsed ? 'Events' : ''}
                        disableIcon
                      >
                        <ListItemIcon>
                          <TodayIcon htmlColor='#fff' />
                        </ListItemIcon>
                      </SRMTooltip>
                      {(!isTablet ||
                        (isTablet && !userStore.isNavigationCollapsed)) && (
                        <ListItemText primary='Events' />
                      )}
                    </ListItem>
                    <ListItem
                      button
                      selected={location.pathname.includes(
                        NAVIGATION_PATH.PENALTIES
                      )}
                      onClick={() => handleMenuClick(NAVIGATION_PATH.PENALTIES)}
                    >
                      <SRMTooltip
                        text={userStore.isNavigationCollapsed ? 'Strafen' : ''}
                        disableIcon
                      >
                        <ListItemIcon>
                          <ErrorIcon htmlColor='#fff' />
                        </ListItemIcon>
                      </SRMTooltip>
                      {(!isTablet ||
                        (isTablet && !userStore.isNavigationCollapsed)) && (
                        <ListItemText primary='Strafen' />
                      )}
                    </ListItem>
                    <ListItem
                      button
                      selected={
                        location.pathname.includes(NAVIGATION_PATH.INCIDENTS) &&
                        !location.pathname.includes(NAVIGATION_PATH.MANAGE)
                      }
                      onClick={() => handleMenuClick(NAVIGATION_PATH.INCIDENTS)}
                    >
                      <SRMTooltip
                        text={userStore.isNavigationCollapsed ? 'Vorfälle' : ''}
                        disableIcon
                      >
                        <ListItemIcon>
                          <GavelIcon htmlColor='#fff' />
                        </ListItemIcon>
                      </SRMTooltip>
                      {(!isTablet ||
                        (isTablet && !userStore.isNavigationCollapsed)) && (
                        <ListItemText primary='Vorfälle' />
                      )}
                    </ListItem>
                  </>
                )}
              </>
            )}

            {(leagueStore.isSteward() || leagueStore.isAdmin()) && (
              <ListItem
                button
                selected={location.pathname.includes(NAVIGATION_PATH.MANAGE)}
                onClick={() => handleMenuClick(NAVIGATION_PATH.MANAGE)}
              >
                <SRMTooltip
                  text={
                    userStore.isNavigationCollapsed ? 'Liga Verwaltung' : ''
                  }
                  disableIcon
                >
                  <ListItemIcon>
                    <AccountBalanceIcon htmlColor='#fff' />
                  </ListItemIcon>
                </SRMTooltip>
                {(!isTablet ||
                  (isTablet && !userStore.isNavigationCollapsed)) && (
                  <ListItemText primary='Liga Verwaltung' />
                )}
              </ListItem>
            )}
          </List>
        </DrawerContent>
        <div>
          <List component='nav'>
            <ListItem
              button
              selected={
                !location.pathname.includes(NAVIGATION_PATH.MANAGE) &&
                location.pathname.includes(NAVIGATION_PATH.SETTINGS)
              }
              onClick={() => handleMenuClick(NAVIGATION_PATH.SETTINGS)}
            >
              <SRMTooltip
                text={userStore.isNavigationCollapsed ? 'Einstellungen' : ''}
                disableIcon
              >
                <ListItemIcon>
                  <SettingsIcon htmlColor='#fff' />
                </ListItemIcon>
              </SRMTooltip>
              <ListItemText
                primary={
                  !isTablet || (isTablet && !userStore.isNavigationCollapsed)
                    ? 'Einstellungen'
                    : ''
                }
              />
            </ListItem>
            <ListItem
              button
              selected={false}
              onClick={() => window.location.reload()}
            >
              <SRMTooltip
                text={userStore.isNavigationCollapsed ? 'Seite neu laden' : ''}
                disableIcon
              >
                <ListItemIcon>
                  <CachedIcon htmlColor='#fff' />
                </ListItemIcon>
              </SRMTooltip>

              <ListItemText
                primary={
                  !isTablet || (isTablet && !userStore.isNavigationCollapsed)
                    ? 'Seite neu laden'
                    : ''
                }
              />
            </ListItem>
            {isTablet && (
              <ListItem
                button
                selected={false}
                onClick={() => userStore.switchCollapsedState()}
              >
                <ListItemIcon>
                  {userStore.isNavigationCollapsed && (
                    <ArrowForwardIcon htmlColor='#fff' />
                  )}
                  {!userStore.isNavigationCollapsed && (
                    <ArrowBackIcon htmlColor='#fff' />
                  )}
                </ListItemIcon>
              </ListItem>
            )}
          </List>
        </div>
      </SwipeableDrawer>
    );
  }
);

const DrawerCurrentLeague = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: var(--srmPadding);
  color: #fff;
  background-color: var(--srmGrey);
  border-bottom-right-radius: 50px;
  border-bottom: 1px solid var(--srmBackground);
  padding: 20px 0;
  height: 120px;
  white-space: nowrap;

  @media only screen and (min-width: ${mediaMinWidth.tablet}) {
    height: 150px;
  }
`;

const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 25px 0;
`;

const MenuButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

const iconSize = 24;
const drawerGap = 15;

interface DrawerProps {
  $collapsed: boolean;
}

const SwipeableDrawer = styled(MuiSwipeableDrawer)<DrawerProps>`
  --iconSize: ${iconSize}px;
  --drawerGap: ${drawerGap}px;

  padding: 0 var(--drawerGap) var(--drawerGap);
  color: var(--refuLight);

  .MuiDrawer-paper {
    width: 70%;
    background-color: var(--srmGrey);
    overflow-x: hidden;

    .MuiListItem-root {
      color: var(--srmBackground);
    }

    .MuiListItemText-root,
    .MuiTypography-root,
    .MuiButton-root {
      white-space: nowrap;
    }

    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
      background-color: var(--srmBackground);
      color: var(--srmGrey);

      .MuiSvgIcon-root {
        fill: var(--srmGrey);
      }
    }
  }

  @media only screen and (min-width: ${mediaMinWidth.largeSmartphone}) {
    .MuiDrawer-paper {
      width: 50%;
    }
  }

  @media only screen and (min-width: ${mediaMinWidth.tablet}) {
    grid-area: navigation;
    position: relative;
    padding: 0 0 var(--drawerGap);
    width: 300px;

    transition: width 0.2s ease-in-out;

    .MuiDrawer-paper {
      transition: width 0.2s ease-in-out;
      width: 300px;
      padding: 0 0 var(--drawerGap);

      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
        0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important;
      background-color: var(--srmGrey) !important;
      color: var(--srmBackground) !important;
    }

    ${(props) =>
      props.$collapsed &&
      css`
        overflow-x: hidden;
        width: 56px;
        .MuiDrawer-paper {
          overflow-x: hidden;
          width: 56px;
          .MuiListItem-root {
            padding-top: 13.5px;
            padding-bottom: 13.5px;
          }
        }
      `}
  }

  @media only screen and (min-width: ${mediaMinWidth.desktop}) {
    ${(props) =>
      props.$collapsed &&
      css`
        .MuiDrawer-paper {
          .MuiListItem-root {
            padding-top: 14.099px;
            padding-bottom: 14.099px;
          }
        }
      `}
  }
`;
