import { Paper as UnstyledPaper, Typography, IconButton } from '@mui/material';
import styled from 'styled-components';

import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import ContenderIcon from '@mui/icons-material/Textsms';
import { useHistory } from 'react-router-dom';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

import { useContext } from 'react';
import { LeagueStore } from '../../store';
import { observer } from 'mobx-react-lite';
import { IncidentState } from '../../store/LeagueModel';
import { DarkBorder } from '../../components/DarkBorder';

export const Overview = observer(() => {
  const history = useHistory();
  const leagueStore = useContext(LeagueStore);

  const navigate = (path: string) => {
    const basePath = history.location.pathname;
    const tempPath = basePath + path;

    history.push(tempPath);
  };
  return (
    <PageContainer>
      <CardContainer>
        <Paper
          elevation={6}
          backgroundcolor='var(--srmRedLight)'
          onClick={() => navigate('/drivers')}
        >
          <GroupIcon htmlColor='var(--srmRed)' fontSize='large' />
          <Typography variant='h6' component='p'>
            Fahrer
          </Typography>
          <Typography
            sx={{ color: 'var(--srmRed)' }}
            variant='h5'
            component='p'
          >
            ({leagueStore.getAllDriver().length})
          </Typography>
        </Paper>
        <Paper
          elevation={6}
          backgroundcolor='var(--srmBlueLight)'
          onClick={() => navigate('/events')}
        >
          <EventIcon htmlColor='var(--srmBlue)' fontSize='large' />
          <Typography variant='h6' component='p'>
            Events
          </Typography>
          <Typography
            sx={{ color: 'var(--srmBlue)' }}
            variant='h5'
            component='p'
          >
            ({leagueStore.getAllEvents().length})
          </Typography>
        </Paper>

        <Paper
          elevation={6}
          backgroundcolor='var(--srmLilaLight)'
          onClick={() => navigate('/divisions')}
        >
          <AccountBalanceIcon htmlColor='var(--srmLila)' fontSize='large' />
          <Typography variant='h6' component='p'>
            Divisionen
          </Typography>
          <Typography
            sx={{ color: 'var(--srmLila)' }}
            variant='h5'
            component='p'
          >
            ({leagueStore.getAllDivisions.length})
          </Typography>
        </Paper>

        <Paper
          elevation={6}
          backgroundcolor='var(--srmPinkLight)'
          onClick={() => navigate('/contenders')}
        >
          <ContenderIcon htmlColor='var(--srmPink)' fontSize='large' />
          <Typography variant='h6' component='p'>
            Bewerber
          </Typography>
          <Typography
            sx={{ color: 'var(--srmPink)' }}
            variant='h5'
            component='p'
          >
            ({leagueStore.getContenders().length})
          </Typography>
        </Paper>
        <Paper
          elevation={6}
          backgroundcolor='var(--srmOrangeLight)'
          onClick={() => navigate('/incidents')}
        >
          <ContentPasteSearchIcon
            htmlColor='var(--srmOrange)'
            fontSize='large'
          />
          <Typography variant='h6' component='p'>
            Vorfälle
          </Typography>
          <Typography
            sx={{ color: 'var(--srmOrange)' }}
            variant='h5'
            component='p'
          >
            (
            {
              leagueStore
                .getIncidentsById()
                .filter((inc) => inc.state !== IncidentState.DONE).length
            }
            )
          </Typography>
        </Paper>
        <Paper
          elevation={6}
          backgroundcolor='var(--srmGreenLight)'
          onClick={() => navigate('/settings')}
        >
          <SettingsIcon htmlColor='var(--srmGreen)' fontSize='large' />
          <Typography variant='h6' component='p'>
            Einstellungen
          </Typography>
        </Paper>
      </CardContainer>
      {leagueStore.joinCode && (
        <DarkBorder>
          <Typography variant='h6' component='p'>
            {leagueStore.joinCode}
            {document.queryCommandSupported('copy') && (
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(leagueStore.joinCode)
                }
                size='large'
              >
                <FileCopyIcon htmlColor='var(--srmGreen)' />
              </IconButton>
            )}
          </Typography>
        </DarkBorder>
      )}
    </PageContainer>
  );
});

interface PaperProps {
  backgroundcolor: string;
}

const PageContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div`
  padding: var(--srmPadding);
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--srmPaddingBig);
  height: 100%;
`;

const Paper = styled(UnstyledPaper)<PaperProps>`
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.backgroundcolor} !important;

  &:hover {
    cursor: pointer;
  }
`;
