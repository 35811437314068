import {
  NativeSelect,
  FormControl,
  InputLabel,
  TextField,
  Button,
} from '@mui/material';
import { useEffect, useState, useContext, FormEvent } from 'react';
import styled from 'styled-components';
import { LeagueStore } from '../../store';
import { Incident_IF, CreatePenalty } from '../../store/LeagueModel';
import { Modal } from '../Modal/Modal';

interface CreatePenaltyModalProps {
  incident: Incident_IF | null;
  isOpen: boolean;
  handleClose: () => void;
  createPenalty: (penalty: CreatePenalty) => void;
}

export const CreatePenaltyModal = ({
  incident,
  isOpen,
  handleClose,
  createPenalty,
}: CreatePenaltyModalProps) => {
  const leagueStore = useContext(LeagueStore);
  const [driver, setDriver] = useState<string>('');
  const [type, setType] = useState<string>('Abmahnung');
  const [penaltyPoints, setPenaltyPoints] = useState<number>(0);
  const [describtion, setDescribtion] = useState<string>('');

  const handleCreatePenalty = (event: FormEvent) => {
    event.preventDefault();

    createPenalty({
      driver,
      note: describtion,
      penaltyPoints,
      type,
    });
  };

  useEffect(() => {
    if (incident) {
      setDriver(incident?.receiver.driver);
    }
  }, [incident]);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title='Strafe festlegen'
      subtitle={`${incident?.author.driver} vs. ${incident?.receiver.driver}`}
    >
      <form onSubmit={handleCreatePenalty}>
        <FormControl fullWidth>
          <InputLabel htmlFor='penalty-driver'>
            Wer bekommt die Strafe (aberkannt) ?
          </InputLabel>
          <NativeSelect
            id='penalty-driver'
            fullWidth
            onChange={(event) => setDriver(event.target.value)}
            value={driver}
            required
          >
            <option disabled value={''}>
              Fahrer wählen
            </option>
            <optgroup label='Beteiligte Fahrer'>
              <option value={incident?.receiver.driver}>
                {incident?.receiver.driver}
              </option>
              <option value={incident?.author.driver}>
                {incident?.author.driver}
              </option>
            </optgroup>
            <optgroup label='Alle Fahrer'>
              {leagueStore.getAllDriver().map((dr, index) => {
                return (
                  <option key={`${dr.name}-${index}`} value={dr.name}>
                    {dr.name}
                  </option>
                );
              })}
            </optgroup>
          </NativeSelect>
        </FormControl>
        <br />
        <br />
        <Row>
          <FormControl fullWidth required>
            <InputLabel htmlFor='penalty-type'>Art der Strafe</InputLabel>
            <NativeSelect
              id='penalty-type'
              fullWidth
              onChange={(event) => setType(event.target.value)}
              value={type}
              required
            >
              <option value='Abmahnung'>Abmahnung</option>
              <option value='Ausschluss'>Ausschluss</option>
              <option value='Fehlverhalten'>Fehlverhalten</option>
              <option value='Kein Clip'>Kein Clip</option>
              <option value='Keine Strafe'>Keine Strafe</option>
              <option value='Q2 Sperre'>Q2 Sperre</option>
              <option value='Q3 Sperre'>Q3 Sperre</option>
              <option value='Qualifying Sperre'>Qualifying Sperre</option>
              <option value='Saison Sperre'>Saison Sperre</option>
              <option value='Spielfehler'>Spielfehler</option>
              <option value='Strafe aberkannt'>Strafe aberkannt</option>
              <option value='Strafe gültig'>Strafe gültig</option>
              <option value='Rennsperre'>Rennsperre</option>
              <option value='Rennunfall'>Rennunfall</option>
              <option value='Verwarnung'>Verwarnung</option>
              <option value='Zeitstrafe'>Zeitstrafe</option>
            </NativeSelect>
          </FormControl>

          <TextField
            type='number'
            label='Strafpunkte'
            required
            value={penaltyPoints}
            onChange={(event: any) =>
              setPenaltyPoints(parseInt(event.target.value, 10))
            }
            fullWidth
            variant='standard'
          />
        </Row>
        <br />
        <br />
        <TextField
          variant='standard'
          multiline
          maxRows={3}
          minRows={1}
          label='Beschreibung der Entscheidung'
          required
          value={describtion}
          onChange={(event: any) => setDescribtion(event.target.value)}
          fullWidth
        />
        <br />
        <br />
        <Button
          type='submit'
          variant='contained'
          color='primary'
          fullWidth
          disabled={!driver || !type || !describtion}
        >
          Strafe anwenden
        </Button>
      </form>
    </Modal>
  );
};

const Row = styled.div`
  display: flex;
  gap: var(--srmPadding);
`;
