import {
  Card,
  CardContent,
  TextField,
  InputAdornment,
  IconButton,
  Chip,
} from '@mui/material';
import { ChipContainer } from './Tags';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import styled from 'styled-components';
import { DriverTypeMapping } from '../pages/admin/Drivers/DriversOverview';

interface SearchProps {
  placeholder: string;
  searchTerm: string;
  onSearch: (term: string) => void;
  onClear: () => void;
  tags?: string[];
  onRemoveTag?: (index: number) => void;
}

export const Search = ({
  placeholder,
  searchTerm,
  onSearch,
  onClear,
  tags,
  onRemoveTag,
}: SearchProps) => {
  const handleRemoveTag = (index: number) => {
    if (onRemoveTag) {
      onRemoveTag(index);
    }
  };

  return (
    <Card>
      <CardContent>
        <TextField
          placeholder={placeholder}
          value={searchTerm}
          onChange={(event) => onSearch(event.target.value)}
          fullWidth
          size='small'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={onClear} edge='end'>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {tags && tags.length > 0 && (
          <>
            <Margin />
            <ChipContainer>
              {tags.map((t, i) => {
                return (
                  <Chip
                    key={t}
                    color='primary'
                    label={DriverTypeMapping[t] || t}
                    size='small'
                    onDelete={() => handleRemoveTag(i)}
                  />
                );
              })}
            </ChipContainer>
          </>
        )}
      </CardContent>
    </Card>
  );
};

const Margin = styled.div`
  margin-top: var(--srmPadding);
`;

export const SearchContainer = styled.div`
  padding: var(--srmPadding);
`;
