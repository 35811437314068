import { Event } from '../../components/Event/Event';
import { useContext } from 'react';
import { LeagueStore } from '../../store';
import { observer } from 'mobx-react-lite';
import {
  PageContent,
  ScrollingItemsContainerRows,
} from '../../components/Content';

export const Calendar = observer(() => {
  const leagueStore = useContext(LeagueStore);

  return (
    <PageContent>
      <ScrollingItemsContainerRows>
        {leagueStore.getAllEvents(leagueStore.currentDivision).map((e, i) => {
          return <Event key={`Event-${i}`} event={e} />;
        })}
      </ScrollingItemsContainerRows>
    </PageContent>
  );
});
