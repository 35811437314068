import styled from 'styled-components';
import {
  Checkbox,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useState, useContext, useEffect } from 'react';
import { LeagueStore } from '../../../store';
import { DivisionDriver, DriverType } from '../../../store/LeagueModel';
import { EditLayout } from '../../../components/Edit/EditLayout';

import PersonIcon from '@mui/icons-material/Person';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { observer } from 'mobx-react-lite';
import { EditDriver } from './EditDriver';

import { FilterModal } from '../../../components/FilterModal';
import { Search, SearchContainer } from '../../../components/Search';
import { timeout } from '../../../utils/time';
import { Loading } from '../../../components/Loading';
import { Headline } from '../../../components/Headline';

export const DriverTypeMapping: Record<string, string> = {
  [DriverType.ALL]: 'Alle',
  [DriverType.REGULAR]: 'Stammfahrer',
  [DriverType.SUBSTITUTE]: 'Ersatzfahrer',
  [DriverType.CONTENDER]: 'Bewerber',
};

export const DriversOverview = observer(() => {
  const leagueStore = useContext(LeagueStore);
  const [currentDriver, setCurrentDriver] = useState<DivisionDriver | null>(
    null
  );
  const [search, setSearch] = useState<string>('');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filterTags, setFilterTags] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allDriver, setAllDriver] = useState<DivisionDriver[]>([]);
  const [driver, setDriver] = useState<DivisionDriver[]>([]);
  // const [sortBy, setSortBy] = useState<string>('');

  

  const handleAddFilterTag = (tag: string | string[]) => {
    setFilterTags(typeof tag === 'string' ? tag.split(',') : tag);
  };

  const handleRemoveFilterTag = (index: number) => {
    const tempTags = filterTags.slice();
    tempTags.splice(index, 1);
    setFilterTags(tempTags);
  };

  useEffect(() => {
    setIsLoading(true);
    const data = leagueStore.getAllDriver();
    timeout(300).then(() => {
      setDriver(data);
      setAllDriver(data);
      setIsLoading(false);
      
      
    });
  }, [leagueStore.currentLeague]);

  useEffect(() => {
    const tempData = allDriver
      .filter((dr) => {
        if (
          filterTags.length === 0 ||
          filterTags.includes(
            dr.divisions.find((d) => d.divisionId)?.divisionId || ''
          ) ||
          filterTags.includes(dr.driverType)
        ) {
          return dr;
        }
      })
      .filter((dr) =>
        search ? dr.name.toLowerCase().includes(search.toLowerCase()) : dr
      );

    setDriver(tempData);
  }, [search, filterTags]);

  return (
    <PageLayout>
      <SearchContainer>
        <Search
          placeholder='Suche einen Fahrer'
          searchTerm={search}
          onSearch={setSearch}
          onClear={() => setSearch('')}
          tags={filterTags.map((t) => {
            return (
              DriverTypeMapping[t] || leagueStore.getDivisionNameById(t) || t
            );
          })}
          onRemoveTag={handleRemoveFilterTag}
        />
      </SearchContainer>
      <Headline title='Fahrer' infoText={driver.length} enablePadding />
      {isLoading ? (
        <Loading />
      ) : (
        <EditLayout onFilterClick={() => setShowFilter(true)}>
          <List dense>
            {driver.map((dr) => {
              return (
                <ListItem
                  key={dr.name}
                  onClick={() => setCurrentDriver(dr)}
                  button
                  divider
                  dense
                >
                  <ListItemIcon>
                    <PersonIcon
                      htmlColor={
                        dr.driverType === DriverType.CONTENDER
                          ? 'var(--srmGreyLight)'
                          : 'var(--srmGreen)'
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Row>
                        <Typography variant='body2' component='span'>
                          {dr.name}&nbsp;
                          <Red>({dr.penaltyPoints})</Red>
                        </Typography>
                        <Skeleton
                          variant='circular'
                          width={12}
                          height={12}
                          animation={false}
                          sx={{
                            backgroundColor: dr.isActive
                              ? 'var(--srmSuccess)'
                              : 'var(--srmRed)',
                          }}
                        />

                        <Skeleton
                          variant='circular'
                          width={12}
                          height={12}
                          animation={false}
                          sx={{
                            backgroundColor: leagueStore.isAdmin(dr.name)
                              ? 'var(--srmWarning)'
                              : leagueStore.isSteward(dr.name)
                              ? 'var(--srmInfo)'
                              : 'var(--srmGreyLight)',
                          }}
                        />
                      </Row>
                    }
                    secondary={DriverTypeMapping[dr.driverType]}
                  />
                  <ChevronRightIcon htmlColor='var(--srmGreyLight)' />
                </ListItem>
              );
            })}
          </List>
        </EditLayout>
      )}

      {/* Filter Modal */}
      {showFilter && (
        <FilterModal
          isOpen={showFilter}
          onClose={() => setShowFilter(false)}
          title='Filtern & Sortieren'
        >
          <FormControl fullWidth>
            <InputLabel id='filter-drivers'>Filter nach</InputLabel>
            <Select
              id='filter-drivers'
              multiple
              value={filterTags}
              onChange={(event: SelectChangeEvent<typeof filterTags>) =>
                handleAddFilterTag(event.target.value)
              }
              renderValue={(selected) =>
                selected
                  .map((s) => {
                    return (
                      DriverTypeMapping[s] ||
                      leagueStore.getDivisionNameById(s) ||
                      s
                    );
                  })
                  .join(', ')
              }
            >
              {[
                { tag: 'SUBSTITUTE', name: 'Ersatzfahrer' },
                { tag: 'REGULAR', name: 'Stammfahrer' },
                ...leagueStore.getAllDivisions.map((d) => {
                  return { tag: d._id, name: d.name };
                }),
              ].map((t) => (
                <MenuItem key={t.tag} value={t.tag}>
                  <Checkbox checked={filterTags.indexOf(t.tag) > -1} />
                  <ListItemText primary={t.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <br />
          <br />
          <FormControl fullWidth>
            <InputLabel id='sort-drivers'>Sortieren nach</InputLabel>
            <Select
              id='sort-drivers'
              onChange={(event) => setSortBy(event.target.value)}
              value={sortBy}
              color='secondary'
            >
              <MenuItem value='Time'>Member since</MenuItem>
              <MenuItem value='Tags'>Name DESC & ASEC</MenuItem>
            </Select>
          </FormControl> */}
        </FilterModal>
      )}
      {/* Edit Single Driver */}
      {currentDriver && (
        <EditDriver
          currentDriver={currentDriver}
          onClose={() => setCurrentDriver(null)}
        />
      )}
    </PageLayout>
  );
});

const PageLayout = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 3px;
`;

const Red = styled.span`
  color: var(--srmRed);
`;
