import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import EditIcon from '@mui/icons-material/Edit';
import {
  PageContent,
  ScrollingItemsContainer,
} from '../../../components/Content';

import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { LeagueStore } from '../../../store';
import styled from 'styled-components';

import { Headline } from '../../../components/Headline';
import { getRacingTypeLogo } from '../../../utils/getRacingTypeLogo';
import { Breakpoint } from '../../../utils/breakpoints';
import { fromUnixTime, isBefore } from 'date-fns';

export const DivisionsOverview = observer(() => {
  const leagueStore = useContext(LeagueStore);

  return (
    <PageContent>
      <Headline title={`Verwaltung der Divisionen`} />
      <br />
      <ScrollingItemsContainer>
        {leagueStore.allDivisions.map((div) => {
          return (
            <Card key={div._id}>
              <CardHeader
                title={
                  <Title>
                    <SmallImg
                      src={getRacingTypeLogo(div.racingType)}
                      alt={div.racingType}
                      sizes='inherit'
                    />
                    <Typography variant='subtitle1' component='p'>
                      {div.name} - {div.shortName}
                    </Typography>
                  </Title>
                }
                action={
                  <IconButton
                    onClick={() => {
                      return;
                    }}
                    size='large'
                    disabled
                  >
                    <EditIcon htmlColor='var(--srmGreyMed)' />
                  </IconButton>
                }
              />
              <CardContent>
                <ChartContainer>
                  <Chart>
                    <ResponsivePie
                      data={[
                        {
                          id: 'Fahrer',
                          value: div.drivers.length,
                          color: 'var(--srmGreen)',
                        },
                        {
                          id: 'Freie Plätze',
                          value: 20 - div.drivers.length,
                          color: 'var(--srmGreyLight)',
                        },
                      ]}
                      padAngle={2}
                      margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                      cornerRadius={2}
                      arcLabelsSkipAngle={5}
                      innerRadius={0.5}
                      colors={({ data }) => (data as any).color}
                      activeInnerRadiusOffset={1}
                      activeOuterRadiusOffset={1}
                      enableArcLinkLabels={false}
                      arcLabelsTextColor='#333'
                    />
                  </Chart>
                  <Chart>
                    <ResponsivePie
                      data={[
                        {
                          id: 'Abgeschlossen',
                          value: div.events.filter(
                            (ev) => ev.result && ev.result.length > 0
                          ).length,
                          color: 'var(--srmGreen)',
                        },
                        {
                          id: 'Ergebnis fehlt',
                          value: div.events.filter(
                            (ev) =>
                              isBefore(fromUnixTime(ev.date), new Date()) &&
                              (!ev.result || ev.result.length === 0)
                          ).length,
                          color: 'var(--srmRed)',
                        },
                        {
                          id: 'Offen',
                          value: div.events.filter(
                            (ev) => !ev.result || ev.result.length === 0
                          ).length,
                          color: 'var(--srmGreyLight)',
                        },
                      ]}
                      padAngle={2}
                      margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                      cornerRadius={2}
                      arcLabelsSkipAngle={10}
                      innerRadius={0.5}
                      colors={({ data }) => (data as any).color}
                      activeInnerRadiusOffset={1}
                      activeOuterRadiusOffset={1}
                      enableArcLinkLabels={false}
                      arcLabelsTextColor='#333'
                    />
                  </Chart>
                  <Typography variant='body1' component='p'>
                    Fahrer
                  </Typography>
                  <Typography variant='body1' component='p'>
                    Events
                  </Typography>
                </ChartContainer>
              </CardContent>
            </Card>
          );
        })}
      </ScrollingItemsContainer>
    </PageContent>
  );
});

const Title = styled.div`
  display: flex;
  grid-gap: 3px;
  align-items: center;

  p {
    padding-top: 2px;
  }
`;

const SmallImg = styled.img`
  max-height: 12px;
  margin-right: 3px;
`;

const ChartContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  grid-gap: 5px;
  align-items: center;
  justify-items: center;
`;

const Chart = styled.div`
  position: relative;
  height: 75px;
  width: 100%;

  @media only screen and (min-width: ${Breakpoint.tablet}px) {
    height: 100px;
    width: 100%;
  }
`;
