import { Chip, FormControl, InputLabel, NativeSelect } from '@mui/material';
import styled from 'styled-components';
import { DriverTypeMapping } from '../pages/admin/Drivers/DriversOverview';
import { DriverType, RacingType } from '../store/LeagueModel';

interface TagsProps {
  tags: string[];
  onRemove: (index: number) => void;
  onChange: (tag: string) => void;
}

export const Tags = ({ tags, onRemove, onChange }: TagsProps) => {
  return (
    <>
      <ChipContainer>
        {tags?.map((t, i) => {
          return (
            <Chip
              key={t}
              size='small'
              color='primary'
              label={t}
              onDelete={() => onRemove(i)}
            />
          );
        })}
      </ChipContainer>
      <br />
      <FormControl fullWidth>
        <InputLabel htmlFor='driver-tags' color='secondary'>
          Tags ergänzen
        </InputLabel>
        <NativeSelect
          id='driver-tags'
          fullWidth
          onChange={(event) => onChange(event.target.value)}
          required
          color='secondary'
        >
          <optgroup label='Zeit am..'>
            <option value={'Montag'} disabled={tags.includes('Montag')}>
              Montag
            </option>
            <option value={'Dienstag'} disabled={tags.includes('Dienstag')}>
              Dienstag
            </option>
            <option value={'Mittwoch'} disabled={tags.includes('Mittwoch')}>
              Mittwoch
            </option>
            <option value={'Donnerstag'} disabled={tags.includes('Donnerstag')}>
              Donnerstag
            </option>
            <option value={'Freitag'} disabled={tags.includes('Freitag')}>
              Freitag
            </option>
            <option value={'Samstag'} disabled={tags.includes('Samstag')}>
              Samstag
            </option>
            <option value={'Sonntag'} disabled={tags.includes('Sonntag')}>
              Sonntag
            </option>
          </optgroup>
          <optgroup label='Pace des Fahrers...'>
            <option value={'Langsam'} disabled={tags.includes('Langsam')}>
              Langsam
            </option>
            <option value={'Mittel'} disabled={tags.includes('Mittel')}>
              Mittel
            </option>
            <option value={'Schnell'} disabled={tags.includes('Schnell')}>
              Schnell
            </option>
          </optgroup>
          <optgroup label='Interesse an...'>
            <option
              value={DriverTypeMapping[DriverType.REGULAR]}
              disabled={tags.includes(DriverTypeMapping[DriverType.REGULAR])}
            >
              {DriverTypeMapping[DriverType.REGULAR]}
            </option>
            <option
              value={DriverTypeMapping[DriverType.SUBSTITUTE]}
              disabled={tags.includes(DriverTypeMapping[DriverType.SUBSTITUTE])}
            >
              {DriverTypeMapping[DriverType.SUBSTITUTE]}
            </option>
          </optgroup>
          <optgroup label='Will fahren in...'>
            <option
              value={RacingType.F1}
              disabled={tags.includes(RacingType.F1)}
            >
              {RacingType.F1}
            </option>
            <option
              value={RacingType.F2}
              disabled={tags.includes(RacingType.F2)}
            >
              {RacingType.F2}
            </option>
          </optgroup>
        </NativeSelect>
      </FormControl>
    </>
  );
};

export const ChipContainer = styled.div`
  .MuiChip-root {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;
