import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Chip,
  Typography,
} from '@mui/material';

import { Incident_IF } from '../../store/LeagueModel';
import { observer } from 'mobx-react-lite';

import penaltyIcon from '../../assets/icons/penalty.jpeg';
import styled from 'styled-components';
import { Breakpoint, useBreakpoint } from '../../utils/breakpoints';

interface PenaltyRowProps {
  data: Incident_IF;
}

export const PenaltyRow = observer(({ data }: PenaltyRowProps) => {
  const isSmallDesktop = useBreakpoint(Breakpoint.smallDesktop);
  return (
    <Card>
      {data.penalty && (
        <>
          <CardHeader
            avatar={<Avatar src={penaltyIcon} variant='rounded' />}
            title={data.author.driver}
            subheader={`vs. ${data.receiver.driver}`}
            action={
              <FlexChip>
                <Chip
                  color='primary'
                  size={isSmallDesktop ? 'medium' : 'small'}
                  label={data.penalty.type}
                />
                <Chip
                  className='penalty-track-chip__max-width'
                  size={isSmallDesktop ? 'medium' : 'small'}
                  label={data.track}
                />
              </FlexChip>
            }
          />
          <CardContent>
            <Typography variant='body1' component='p'>
              Strafe für: {data.penalty.driver}{' '}
              <Red>({data.penalty.penaltyPoints})</Red>
            </Typography>

            <br />
            <Typography variant='body1' component='p'>
              {data.penalty.note}
            </Typography>
          </CardContent>
        </>
      )}
    </Card>
  );
});

const Red = styled.span`
  color: var(--srmRed);
`;

const FlexChip = styled.div`
  display: grid;
  grid-gap: 4px;
`;
