import { Button, NativeSelect, TextField } from '@mui/material';
import { Modal } from '../Modal/Modal';
import styled from 'styled-components';
import { CancelCommitEvent, EventUser } from '../../store/LeagueModel';
import { useContext, useState, FormEvent } from 'react';
import { LeagueStore } from '../../store';
import { observer } from 'mobx-react-lite';

interface CommitModalProps {
  divId?: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle: string;
  flag: string;
  selectedEvent: CancelCommitEvent;
  cancellations: EventUser[];
}

export const CommitModal = observer(
  ({
    divId,
    isOpen,
    onClose,
    title,
    subtitle,
    flag,
    selectedEvent,
    cancellations,
  }: CommitModalProps) => {
    const leagueStore = useContext(LeagueStore);
    const [note, setNote] = useState<string>('');
    const [team, setTeam] = useState<string>('');

    const commitEvent = (event: FormEvent) => {
      event.preventDefault();

      leagueStore.commitEvent({
        ...selectedEvent,
        note,
        team,
        divId,
      });

      setNote('');
      onClose();
    };
    return (
      <Modal
        isOpen={isOpen}
        handleClose={onClose}
        title={title}
        flag={flag}
        subtitle={subtitle}
      >
        <ModalContent>
          <form onSubmit={commitEvent}>
            {!leagueStore.isRegularDriver && (
              <NativeSelect
                fullWidth
                onChange={(event) => setTeam(event.target.value)}
                value={team}
                placeholder='Wähle ein Team für das du fahren willst '
                required
              >
                {cancellations.map((c, index) => {
                  return (
                    <option key={`${index}-${c.team}`} value={c.team}>
                      {c.team}
                    </option>
                  );
                })}
              </NativeSelect>
            )}

            <TextField
              variant='standard'
              id='note'
              label='Notiz - Optional'
              placeholder='z.B. Ich komme zum Rennen'
              value={note}
              onChange={(event) => setNote(event.target.value)}
              fullWidth
            />
            <Button type='submit' variant='contained' color='primary' fullWidth>
              Zusagen
            </Button>
          </form>
        </ModalContent>
      </Modal>
    );
  }
);

const ModalContent = styled.div`
  .MuiButton-root {
    margin-top: var(--srmPaddingBig);
  }
`;
