import styled from 'styled-components';
import {
  Avatar,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  NativeSelect,
  Button,
} from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { LeagueStore } from '../../store';
import { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { COUNTRY_FLAG_API_SMALL } from '../../App';

import {
  PageContent,
  ScrollingItemsContainerRows,
} from '../../components/Content';
import { getTeamBackground } from '../../utils/getTeamBackground';
import { DriverType } from '../../store/LeagueModel';

import DoneIcon from '@mui/icons-material/CheckCircle';
import NotDoneIcon from '@mui/icons-material/HighlightOff';

import { Quizz } from './Quizz';
import { useBreakpoint, Breakpoint } from '../../utils/breakpoints';
import { DriverComparison } from '../../components/DriverComparison/DriverComparison';
import { DriverRating } from '../../components/DriverRating';

const colorById: Record<string, string> = {
  quali: 'black',
  race: 'red',
};

export const Profile = observer(() => {
  const leagueStore = useContext(LeagueStore);
  const [showQuizz, setShowQuizz] = useState<boolean>(false);

  const isTablet = useBreakpoint(Breakpoint.tablet);

  return (
    <ProfileContainer>
      {leagueStore.currentLeague && leagueStore.driver && (
        <>
          <Header>
            <TeamBorder bgColor={leagueStore.getTeam.color} />

            {leagueStore.driver?.driverType === DriverType.REGULAR && (
              <PointsContainer>
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='p'>
                      {leagueStore.driver?.points || 0}
                    </Typography>
                    <Typography variant='body2' component='p'>
                      Fahrer Pkt.
                    </Typography>
                  </CardContent>
                </Card>
              </PointsContainer>
            )}
            <LeagueSelect>
              {leagueStore.getAllDriverDivisions().length > 1 ? (
                <NativeSelect
                  value={leagueStore.currentProfileDivision}
                  onChange={(event: any) =>
                    leagueStore.setCurrentProfileDivision(event.target.value)
                  }
                >
                  {leagueStore.getAllDriverDivisions().map((div) => (
                    <option key={div._id} value={div._id}>
                      {div.name}
                    </option>
                  ))}
                </NativeSelect>
              ) : (
                <Typography variant='h6' component='p'>
                  {leagueStore.driver?.driverType === DriverType.REGULAR
                    ? leagueStore.getProfileDivision &&
                      leagueStore.getProfileDivision.name
                    : leagueStore.name}
                </Typography>
              )}
            </LeagueSelect>
            <LeagueInfo>
              <TeamLogo
                src={getTeamBackground(
                  leagueStore.driver?.team?.name ?? leagueStore.shortName
                )}
                alt='team-image'
              />
            </LeagueInfo>
            {leagueStore.driver?.driverType === DriverType.REGULAR && (
              <PointsContainer>
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='p'>
                      {leagueStore.getTeam.points}
                    </Typography>
                    <Typography variant='body2' component='p'>
                      Team Pkt.
                    </Typography>
                  </CardContent>
                </Card>
              </PointsContainer>
            )}
          </Header>

          <PageContent>
            <ScrollingItemsContainerRows>
              {leagueStore.driver?.driverType === DriverType.REGULAR && (
                <>
                  <DriverRating
                    rating={leagueStore.driver?.rating}
                    penaltyPoints={leagueStore.driver.penaltyPoints || 0}
                  />
                  <DriverComparison />
                  <Card>
                    <CardHeader
                      title='Formkurve'
                      titleTypographyProps={{ variant: 'h6' }}
                      subheader={`Der letzten ${isTablet ? '10' : '7'} Rennen`}
                    />
                    <CardContent>
                      <ChartContainer>
                        <ResponsiveLine
                          curve='monotoneX'
                          data={leagueStore.currentForm(isTablet ? 10 : 7)}
                          margin={{ top: 0, right: 10, bottom: 35, left: 35 }}
                          yScale={{
                            type: 'linear',
                            min: 0,
                            max: 20,
                            stacked: false,
                            reverse: true,
                          }}
                          markers={[
                            {
                              axis: 'y',
                              value: leagueStore.driver.averages.avgRace,
                              lineStyle: {
                                stroke: colorById['race'],
                                strokeWidth: 2,
                                strokeDasharray: 10,
                              },
                              legendOrientation: 'vertical',
                            },
                            {
                              axis: 'y',
                              value: leagueStore.driver.averages.avgQuali,
                              lineStyle: {
                                stroke: colorById['quali'],
                                strokeWidth: 2,
                                strokeDasharray: 10,
                              },
                              legendOrientation: 'vertical',
                            },
                          ]}
                          colors={({ id }) => colorById[id]}
                          lineWidth={3}
                          enablePointLabel={true}
                          pointColor='#fff'
                          pointSize={16}
                          pointLabelYOffset={4}
                          pointBorderWidth={2}
                          pointBorderColor={{
                            from: 'serieColor',
                            modifiers: [],
                          }}
                          enableArea={false}
                          areaBlendMode='multiply'
                          gridYValues={[1, 5, 10, 15, 20]}
                          gridXValues={[0]}
                          axisTop={null}
                          axisRight={null}
                          axisBottom={null}
                          axisLeft={{
                            tickSize: 0,
                            tickPadding: 20,
                            tickRotation: 0,
                            tickValues: [1, 5, 10, 15, 20],
                          }}
                          tooltip={undefined}
                        />

                        <FlagContainer>
                          {leagueStore
                            .getLastEvents(
                              isTablet ? 10 : 7,
                              leagueStore.currentProfileDivision
                            )
                            .map((e) => {
                              return (
                                <Avatar
                                  key={e.date}
                                  variant='square'
                                  src={`${COUNTRY_FLAG_API_SMALL}/${e.countryCode.toLowerCase()}.png`}
                                />
                              );
                            })}
                        </FlagContainer>
                      </ChartContainer>
                      <LegendContainer>
                        <Legend>
                          <LegendCircle color={colorById['race']} />
                          <Typography variant='body1' component='p'>
                            Rennen
                          </Typography>
                        </Legend>
                        <Legend>
                          <LegendCircle color={colorById['quali']} />
                          <Typography variant='body1' component='p'>
                            Qualifying
                          </Typography>
                        </Legend>
                      </LegendContainer>
                      <LegendContainer>
                        <Legend>
                          <LegendDashedLine color={colorById['race']} />
                          <Typography variant='body1' component='p'>
                            ∅ {leagueStore.driver?.averages.avgRace || 0}
                          </Typography>
                        </Legend>
                        <Legend>
                          <LegendDashedLine color={colorById['quali']} />
                          <Typography variant='body1' component='p'>
                            ∅ {leagueStore.driver?.averages.avgQuali || 0}
                          </Typography>
                        </Legend>
                      </LegendContainer>
                      <div>
                        <Typography variant='body1' component='p'></Typography>
                      </div>
                    </CardContent>
                  </Card>

                  <Card>
                    <CardHeader
                      title='Historie'
                      subheader='Diese Saison (all time)'
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardContent>
                      <Col>
                        <HistoryRow>
                          <Typography variant='subtitle1' component='p'>
                            Rennen
                          </Typography>
                          <Typography variant='h5' component='p'>
                            {leagueStore.driver?.currentHistory.races || 0}
                          </Typography>
                          <Typography variant='h6' component='p'>
                            ({leagueStore.driver?.allTimeHistory.races || 0})
                          </Typography>
                        </HistoryRow>
                        <Divider />
                        <HistoryRow>
                          <Typography variant='subtitle1' component='p'>
                            Siege
                          </Typography>
                          <Typography variant='h5' component='p'>
                            {leagueStore.driver?.currentHistory.wins || 0}
                          </Typography>
                          <Typography variant='h6' component='p'>
                            ({leagueStore.driver?.allTimeHistory.wins || 0})
                          </Typography>
                        </HistoryRow>
                        <Divider />
                        <HistoryRow>
                          <Typography variant='subtitle1' component='p'>
                            Poles
                          </Typography>
                          <Typography variant='h5' component='p'>
                            {leagueStore.driver?.currentHistory.poles || 0}
                          </Typography>
                          <Typography variant='h6' component='p'>
                            ({leagueStore.driver?.allTimeHistory.poles || 0})
                          </Typography>
                        </HistoryRow>
                        <Divider />
                        <HistoryRow>
                          <Typography variant='subtitle1' component='p'>
                            Podien
                          </Typography>
                          <Typography variant='h5' component='p'>
                            {leagueStore.driver?.currentHistory.podien || 0}
                          </Typography>
                          <Typography variant='h6' component='p'>
                            ({leagueStore.driver?.allTimeHistory.podien || 0})
                          </Typography>
                        </HistoryRow>
                        <Divider />
                        <HistoryRow>
                          <Typography variant='subtitle1' component='p'>
                            Schnellste Runden
                          </Typography>
                          <Typography variant='h5' component='p'>
                            {leagueStore.driver?.currentHistory.fastestLaps ||
                              0}
                          </Typography>
                          <Typography variant='h6' component='p'>
                            (
                            {leagueStore.driver?.allTimeHistory.fastestLaps ||
                              0}
                            )
                          </Typography>
                        </HistoryRow>
                        <Divider />
                        <HistoryRow>
                          <Typography variant='subtitle1' component='p'>
                            Dnf/Dsq
                          </Typography>
                          <Typography variant='h5' component='p'>
                            {leagueStore.driver?.currentHistory.dnf || 0}
                          </Typography>
                          <Typography variant='h6' component='p'>
                            ({leagueStore.driver?.allTimeHistory.dnf || 0})
                          </Typography>
                        </HistoryRow>
                      </Col>
                    </CardContent>
                  </Card>
                </>
              )}

              {leagueStore.driver?.driverType === DriverType.CONTENDER && (
                <>
                  <Card>
                    <CardHeader
                      title='Bewerbungsstatus'
                      subheader='Absolviere die folgenden Punkte'
                      titleTypographyProps={{ variant: 'h6' }}
                    />
                    <CardContent>
                      <HistoryRow>
                        <Typography variant='subtitle1' component='p'>
                          Absolviere unsere theoretische Fahrprüfung, damit wir
                          sicherstellen können, dass du das Regelwerk gelesen
                          hast. Anschließend darfst du an einem Testrennen
                          teilnehmen.
                        </Typography>
                        {leagueStore.contenderState?.quizz ? (
                          <DoneIcon htmlColor='var(--srmGreen)' />
                        ) : (
                          <NotDoneIcon htmlColor='var(--srmRed)' />
                        )}
                      </HistoryRow>
                      <br />
                      <Button
                        color='secondary'
                        variant='outlined'
                        onClick={() => setShowQuizz(true)}
                        disabled={leagueStore.contenderState?.quizz}
                      >
                        {!leagueStore.contenderState?.quizz
                          ? 'Hier gehts zur Fahrprüfung'
                          : 'Bereits bestanden'}
                      </Button>
                      <br />
                      <br />
                      <Divider />
                      <br />
                      <HistoryRow>
                        <Typography variant='subtitle1' component='p'>
                          Nehme an einem Testrennen teil, damit wir deine
                          Sicherheit, Fairness und Pace auf der Strecke
                          beurteilen können.
                        </Typography>
                        {leagueStore.contenderState?.testRace ? (
                          <DoneIcon htmlColor='var(--srmGreen)' />
                        ) : (
                          <NotDoneIcon htmlColor='var(--srmRed)' />
                        )}
                      </HistoryRow>
                    </CardContent>
                  </Card>
                  {showQuizz && (
                    <Quizz
                      isOpen={showQuizz}
                      handleClose={() => setShowQuizz(false)}
                    />
                  )}
                </>
              )}
            </ScrollingItemsContainerRows>
          </PageContent>
        </>
      )}
    </ProfileContainer>
  );
});

interface LegendCircleProps {
  color: string;
}

interface TeamBorderProps {
  bgColor: string;
}

const ProfileContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  .MuiCardHeader-root {
    padding-bottom: 4px;
  }
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: var(--srmPaddingBig);

  .MuiAvatar-root {
    height: 90px;
    width: 90px;
    box-shadow: 0 0 20px var(--srmBackground);
  }

  .MuiCardContent-root {
    padding: 6px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .MuiCardContent-root:last-child {
    padding-bottom: 6px;
  }

  @media only screen and (min-width: ${Breakpoint.mediumDesktop}px) {
    padding-left: 20%;
    padding-right: 20%;
    margin: 0 auto;
  }
`;

const PointsContainer = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 35px;

  .MuiTypography-h6 {
    line-height: 1;
  }
`;

const TeamBorder = styled.div<TeamBorderProps>`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 85px;
  overflow: hidden;
  background-color: ${(props) => props.bgColor};
  box-shadow: 0 2px 10px ${(props) => props.bgColor};
`;

const LeagueInfo = styled.div`
  position: relative;
  z-index: 10;
  padding-top: 30px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
`;

const LeagueSelect = styled.div`
  position: absolute;
  bottom: 0;
`;

const ChartContainer = styled.div`
  position: relative;
  height: 275px;
  margin-bottom: var(--srmPaddingBig);
`;

const FlagContainer = styled.div`
  position: absolute;
  width: calc(100% + 7.5px);
  bottom: -10px;
  left: 0;
  padding-left: 17.5px;
  display: flex;
  justify-content: space-between;

  img {
    height: 24px;
    width: 24px;
  }
`;

const LegendContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
`;

const LegendCircle = styled.div<LegendCircleProps>`
  height: 12px;
  width: 12px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin-right: 10px;
`;

const LegendDashedLine = styled.div<LegendCircleProps>`
  width: 12px;
  height: 2px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--srmPadding);
`;

const HistoryRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 10px;

  p:last-child {
    color: var(--srmGreyLight);
  }
`;

const TeamLogo = styled.img`
  aspect-ratio: 16/9;
  height: 75px;
  border-radius: 10px;
`;
