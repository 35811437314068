import { AlertColor, Slide, SlideProps, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { MessageStore } from '../../store';

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction='up' />;
};

export const Message = observer(() => {
  const messageStore = useContext(MessageStore);

  return (
    <Snackbar
      open={Boolean(messageStore.getMessage.message)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={SlideTransition}
    >
      <Alert
        variant='filled'
        severity={messageStore.getMessage.type.toLowerCase() as AlertColor}
      >
        {messageStore.getMessage.message}
      </Alert>
    </Snackbar>
  );
});
