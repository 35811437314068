import { TextField, Button } from '@mui/material';
import { FormEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { LeagueStore } from '../store';
import { CenterModal } from './Modal/CenterModal';

interface JoinLeagueModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const JoinLeagueModal = ({
  isOpen,
  handleClose,
}: JoinLeagueModalProps) => {
  const leagueStore = useContext(LeagueStore);
  const history = useHistory();
  const [code, setCode] = useState<string>('');

  const handleJoin = async (event: FormEvent) => {
    event.preventDefault();
    const isJoined = await leagueStore.joinLeague(code);

    if (isJoined) {
      handleClose();

      setTimeout(() => {
        history.push('/profile');
      }, 300);
    }
  };

  return (
    <CenterModal
      isOpen={isOpen}
      handleClose={handleClose}
      title='Wie lautet der Ligacode ?'
    >
      <CodeContainer onSubmit={handleJoin}>
        <TextField
          type='text'
          color='secondary'
          value={code}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setCode(event.target.value)}
          label='Ligacode'
          variant='standard'
        />
        <Button
          variant='contained'
          color='primary'
          onClick={handleJoin}
          disabled={!code}
        >
          Beitreten
        </Button>
        <Button variant='outlined' color='secondary' onClick={handleClose}>
          Schließen
        </Button>
      </CodeContainer>
    </CenterModal>
  );
};

const CodeContainer = styled.form`
  margin-top: var(--srmPadding);
  display: grid;
  gap: var(--srmPadding);
`;
