import { Divider, Typography, Chip } from '@mui/material';
import styled from 'styled-components';
import { SRMTooltip } from './SRMTooltip';

interface HeadlineProps {
  title: string;
  tooltipText?: string;
  infoText?: string | number;
  enablePadding?: boolean;
  size?: 'medium' | 'large';
}

export const Headline = ({
  title,
  tooltipText,
  infoText,
  enablePadding = false,
  size = 'medium',
}: HeadlineProps) => {
  return (
    <div>
      <HeadlineContainer enablePadding={enablePadding}>
        <Typography
          variant={size === 'large' ? 'h6' : 'subtitle1'}
          component='p'
        >
          {title}
        </Typography>
        {tooltipText && <SRMTooltip text={tooltipText} />}
        {infoText && <Chip size='small' label={infoText} />}
      </HeadlineContainer>
      <Divider />
    </div>
  );
};

interface HeadlineContainerProps {
  enablePadding?: boolean;
}

const HeadlineContainer = styled.div<HeadlineContainerProps>`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  ${(props) => props.enablePadding && 'padding: 0 var(--srmPadding)'};

  .MuiChip-label {
    padding: 3px 6px;
  }
`;
