import styled from 'styled-components';

export const DarkBorder = styled.div`
  height: 56px;
  background-color: var(--srmGrey);
  color: var(--srmBackground);
  display: flex;
  justify-content: center;
  align-items: center;
`;
