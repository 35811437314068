import { useState, useContext, useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Layout } from './pages/Layout';
import { Login } from './pages/Login';

import { theme } from './style/theme';
import { Profile } from './pages/user/Profile';
import { Standings } from './pages/user/Standings';
import { Penalties } from './pages/user/Penalties';
import { Calendar } from './pages/user/Calendar';
import { Incidents } from './pages/user/Incidents';
import { Overview } from './pages/admin/Overview';
import { LeagueStore, UserStore } from './store';
import { observer } from 'mobx-react-lite';
import { DriversOverview } from './pages/admin/Drivers/DriversOverview';
import { EventsOverview } from './pages/admin/Events/EventsOverview';
import { LeagueSettings } from './pages/admin/Settings/LeagueSettings';
import { DivisionsOverview } from './pages/admin/Divisions/DivisionsOverview';
import { EditComplaints } from './pages/admin/Complaints/EditComplaints';
import { News } from './pages/user/News';
import { JoinLeagueModal } from './components/JoinLeague';
import { Home } from './pages/Home';
import { Rules } from './pages/user/Rules';
import { Settings } from './pages/user/Settings';
import { Message } from './components/Message';
import { ContendersOverview } from './pages/admin/Contenders/ContendersOverview';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Rights } from './store/LeagueModel';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import deLocale from 'date-fns/locale/de';
import { LocalizationProvider } from '@mui/lab';

export const COUNTRY_FLAG_API_BIG = `${process.env.REACT_APP_FLAG_API}/48x36`;
export const COUNTRY_FLAG_API_MEDIUM = `${process.env.REACT_APP_FLAG_API}/36x27`;
export const COUNTRY_FLAG_API_SMALL = `${process.env.REACT_APP_FLAG_API}/16x12`;

export const App = observer(() => {
  const userStore = useContext(UserStore);
  const leagueStore = useContext(LeagueStore);
  const [showJoinModal, setShowJoinModal] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    if (userStore.isAuthorized()) {
      if (userStore.getLeagues.length > 0 && !leagueStore.currentLeague) {
        leagueStore.getLeagueById(userStore.getLeagues[0].leagueId);
      }
    }
  }, [userStore]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
      <ThemeProvider theme={theme}>
        {showJoinModal && (
          <JoinLeagueModal
            isOpen={showJoinModal}
            handleClose={() => setShowJoinModal(!showJoinModal)}
          />
        )}

        <Switch location={location} key={location?.pathname}>
          <Route exact path='/' key='/'>
            {userStore.isAuthorized() ? (
              <Redirect
                to={userStore.getLeagues.length === 1 ? '/news' : '/home'}
              />
            ) : (
              <Login />
            )}
          </Route>
          {userStore.isAuthorized() ? (
            <Layout>
              <Route exact path='/home'>
                <Home />
              </Route>
              <Route exact path='/news'>
                <News />
              </Route>
              <Route exact path='/profile'>
                <Profile />
              </Route>
              <Route exact path='/standings'>
                <Standings />
              </Route>
              <Route exact path='/penalties'>
                <Penalties />
              </Route>
              <Route exact path='/incidents'>
                <Incidents />
              </Route>
              <Route exact path='/events'>
                <Calendar />
              </Route>
              <Route exact path='/rules'>
                <Rules />
              </Route>
              <Route exact path='/settings'>
                <Settings />
              </Route>
              <Route exact path='/manage'>
                <ProtectedRoute
                  redirectPath='/news'
                  needMinRightsOf={Rights.STEWARD}
                >
                  <Overview />
                </ProtectedRoute>
              </Route>
              <Route exact path='/manage/drivers'>
                <ProtectedRoute
                  redirectPath='/manage'
                  needMinRightsOf={Rights.ADMIN}
                >
                  <DriversOverview />
                </ProtectedRoute>
              </Route>
              <Route exact path='/manage/events'>
                <ProtectedRoute
                  redirectPath='/manage'
                  needMinRightsOf={Rights.STEWARD}
                >
                  <EventsOverview />
                </ProtectedRoute>
              </Route>
              <Route exact path='/manage/settings'>
                <ProtectedRoute
                  redirectPath='/manage'
                  needMinRightsOf={Rights.ADMIN}
                >
                  <LeagueSettings />
                </ProtectedRoute>
              </Route>
              <Route exact path='/manage/contenders'>
                <ProtectedRoute
                  redirectPath='/manage'
                  needMinRightsOf={Rights.ADMIN}
                >
                  <ContendersOverview />
                </ProtectedRoute>
              </Route>
              <Route exact path='/manage/incidents'>
                <ProtectedRoute
                  redirectPath='/manage'
                  needMinRightsOf={Rights.STEWARD}
                >
                  <EditComplaints />
                </ProtectedRoute>
              </Route>
              <Route exact path='/manage/divisions'>
                <ProtectedRoute
                  redirectPath='/manage'
                  needMinRightsOf={Rights.ADMIN}
                >
                  <DivisionsOverview />
                </ProtectedRoute>
              </Route>
            </Layout>
          ) : (
            <Login />
          )}
        </Switch>
        <Message />
      </ThemeProvider>
    </LocalizationProvider>
  );
});
