import { ReactNode, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { MessageStore, UserStore } from '../store';
import { Rights } from '../store/LeagueModel';
import { MessageType } from '../store/MessageStore';

interface ProtectedRouteProps {
  redirectPath: string;
  needMinRightsOf: Rights;
  children: ReactNode;
}

export const ProtectedRoute = ({
  redirectPath,
  needMinRightsOf,
  children,
}: ProtectedRouteProps) => {
  const messageStore = useContext(MessageStore);
  const userStore = useContext(UserStore);
  if (
    userStore.rightsRole === Rights.ADMIN ||
    (needMinRightsOf === Rights.STEWARD &&
      userStore.rightsRole === Rights.STEWARD)
  ) {
    return <>{children}</>;
  } else {
    messageStore.sendMessage('NO_RIGHTS', MessageType.INFO);
    return <Redirect to={redirectPath} />;
  }
};
