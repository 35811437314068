import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import {
  IncidentState,
  Incident_IF,
  CreatePenalty,
} from '../../store/LeagueModel';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { format, fromUnixTime } from 'date-fns';

import LinkIcon from '@mui/icons-material/Link';
import incidentIconCreated from '../../assets/icons/incidentCreated.jpeg';
import incidentIconInProcess from '../../assets/icons/incidentInProcess.jpeg';
import incidentIconDone from '../../assets/icons/incidentDone.jpeg';

import { useState, useMemo, useContext, FormEvent } from 'react';
import { LeagueStore, UserStore } from '../../store';
import { Modal } from '../Modal/Modal';
import { CreatePenaltyModal } from '../CreatePenalty/CreatePenalty';

interface IncidentProps {
  incident: Incident_IF;
  isManagerView?: boolean;
}

export const IncidentStateMapping: Record<IncidentState, string> = {
  [IncidentState.CREATED]: incidentIconCreated,
  [IncidentState.IN_PROCESS]: incidentIconInProcess,
  [IncidentState.DONE]: incidentIconDone,
};

export const Incident = observer(
  ({ incident, isManagerView = false }: IncidentProps) => {
    const userStore = useContext(UserStore);
    const leagueStore = useContext(LeagueStore);
    const date = format(fromUnixTime(incident.date), 'dd.MM.yy | HH:mm');
    const [currentIncident, setCurrentIncident] = useState<Incident_IF | null>(
      null
    );
    const [newNote, setNewNote] = useState<string>('');
    const [newView, setNewView] = useState<string>('');
    const [showCreatePenalty, setShowCreatePenalty] = useState<boolean>(false);

    const amIReceiver = useMemo(() => {
      return currentIncident?.receiver.driver === userStore.displayName;
    }, [currentIncident]);

    const amIAuthor = useMemo(() => {
      return currentIncident?.author.driver === userStore.displayName;
    }, [currentIncident]);

    const handleUpdateIncident = (event: FormEvent) => {
      if (currentIncident) {
        leagueStore.updateIncident({
          _id: currentIncident._id,
          driver: currentIncident.receiver.driver,
          link: newView,
          note: newNote,
        });
        setCurrentIncident(null);
        setNewNote('');
        setNewView('');
      }

      event.preventDefault();
    };

    const handleCreatePenalty = (penalty: CreatePenalty) => {
      if (currentIncident && showCreatePenalty) {
        leagueStore.createPenalty(currentIncident, penalty);
        setCurrentIncident(null);
        setShowCreatePenalty(false);
      }
    };

    return (
      <Container>
        <Card>
          <CardActionArea onClick={() => setCurrentIncident(incident)}>
            <CardHeader
              avatar={
                <Avatar
                  variant='rounded'
                  src={IncidentStateMapping[incident.state]}
                />
              }
              title={incident.author.driver}
              subheader={`vs. ${incident.receiver.driver}`}
              action={
                <Chip
                  size='small'
                  color='primary'
                  label={leagueStore.getDivisionShortNameById(
                    incident.divisionId
                  )}
                />
              }
            />
            <CardContent>
              <InfoContainer>
                <InfoPart>
                  <Typography variant='body2' component='p'>
                    Strecke
                  </Typography>
                  <Typography variant='body1' component='p'>
                    {incident.track}
                  </Typography>
                </InfoPart>
                <InfoPart>
                  <Typography variant='body2' component='p'>
                    Datum
                  </Typography>
                  <Typography variant='body1' component='p'>
                    {date}
                  </Typography>
                </InfoPart>
              </InfoContainer>
            </CardContent>
          </CardActionArea>
        </Card>

        {/* Update Incident Modal */}
        {Boolean(currentIncident) && !showCreatePenalty && (
          <Modal
            isOpen={Boolean(currentIncident) && !showCreatePenalty}
            handleClose={() => setCurrentIncident(null)}
          >
            <ModalContent>
              {currentIncident && (
                <>
                  <CardHeader
                    avatar={
                      <Avatar
                        variant='rounded'
                        src={IncidentStateMapping[currentIncident.state]}
                      />
                    }
                    title={`${currentIncident.author.driver} in ${currentIncident.track}`}
                    subheader={`vs. ${currentIncident.receiver.driver}`}
                    action={
                      currentIncident?.penalty &&
                      currentIncident.penalty.type && (
                        <Chip
                          color='primary'
                          label={currentIncident.penalty.type}
                        />
                      )
                    }
                  />

                  <Typography variant='h6' component='p'>
                    {amIAuthor
                      ? 'Deine Sicht'
                      : `${currentIncident.author.driver} 's Sicht`}
                    {currentIncident.author.link?.includes('https') && (
                      <a
                        href={currentIncident.author.link}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        <IconButton color='primary' size='large'>
                          <LinkIcon />
                        </IconButton>
                      </a>
                    )}
                  </Typography>
                  <Typography variant='body1' component='p'>
                    {currentIncident.author.note}
                  </Typography>
                  <br />
                  <Divider />
                  <br />
                  <Typography variant='h6' component='p'>
                    {amIReceiver
                      ? 'Deine Sicht'
                      : `${currentIncident?.receiver.driver}'s Sicht`}
                    {currentIncident.receiver.link !== '' && (
                      <a
                        href={currentIncident.receiver.link}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        <IconButton color='primary' size='large'>
                          <LinkIcon />
                        </IconButton>
                      </a>
                    )}
                  </Typography>

                  {amIReceiver && currentIncident.receiver.note === '' ? (
                    <form onSubmit={handleUpdateIncident}>
                      <TextField
                        id='view'
                        label='Deine Sicht'
                        placeholder='Via Twitch, YouTube oder WeTransfer'
                        value={newView}
                        onChange={(event) => setNewView(event.target.value)}
                        fullWidth
                      />
                      <br />
                      <br />
                      <TextField
                        id='note'
                        multiline
                        maxRows={3}
                        label='Beschreibung des Vorfalls'
                        placeholder='Es war so...'
                        required
                        value={newNote}
                        onChange={(event) => setNewNote(event.target.value)}
                        fullWidth
                      />
                      <br />
                      <br />
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        fullWidth
                        disabled={!newNote}
                      >
                        Sicht einreichen
                      </Button>
                    </form>
                  ) : (
                    <Typography variant='body1' component='p'>
                      {currentIncident.receiver.note}{' '}
                      <span>
                        {currentIncident.receiver.date
                          ? `(${format(
                              fromUnixTime(currentIncident.receiver.date),
                              'dd.MM.yy | HH:mm'
                            )})`
                          : ''}
                      </span>
                    </Typography>
                  )}
                </>
              )}
              {isManagerView && !currentIncident?.penalty && (
                <>
                  <br />
                  <br />
                  <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => setShowCreatePenalty(true)}
                  >
                    Strafe erstellen
                  </Button>
                </>
              )}
            </ModalContent>
          </Modal>
        )}
        {showCreatePenalty && (
          <CreatePenaltyModal
            incident={currentIncident}
            isOpen={showCreatePenalty}
            handleClose={() => setShowCreatePenalty(false)}
            createPenalty={handleCreatePenalty}
          />
        )}
      </Container>
    );
  }
);

const Container = styled.div`
  .MuiCardContent-root {
    padding: 0 6px 14px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const InfoPart = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .MuiTypography-body2 {
    color: var(--srmGreyLight);
  }
`;

const ModalContent = styled.div`
  .MuiCardHeader-title {
    line-height: 1.2;
  }
  .MuiCardHeader-root {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;
