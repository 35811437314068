import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Chip,
  NativeSelect,
  FormControl,
  InputLabel,
  IconButton,
  TextField,
  Switch,
  Select,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material';
import {
  PageContent,
  ScrollingItemsContainerRowsSmallGap,
} from '../../../components/Content';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { observer } from 'mobx-react-lite';
import { FormEvent, useContext, useState } from 'react';
import { LeagueStore } from '../../../store';
import styled from 'styled-components';
import SaveIcon from '@mui/icons-material/Save';

import { SRMTooltip } from '../../../components/SRMTooltip';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { add, format } from 'date-fns';
import { nextMonday } from 'date-fns/esm';
import deLocale from 'date-fns/locale/de';

export const LeagueSettings = observer(() => {
  const leagueStore = useContext(LeagueStore);
  const [newAdmin, setNewAdmin] = useState<string>('');
  const [newSteward, setNewSteward] = useState<string>('');

  const [editIncidents, setEditIncidents] = useState<boolean>(false); // based on league.settings
  const [editRaceSuspension, setEditRaceSuspension] = useState<boolean>(false); // based on league.settings

  const [raceSuspensionPoints, setRaceSuspensionPoints] = useState<number>(0); // based on league.settings
  const [editIncidentsWeekDay, setEditIncidentsWeekDay] = useState<number>(0); // based on league.settings
  const [editIncidentsTime, setEditIncidentsTime] = useState<string | null>(
    null
  ); // based on league.settings

  const [editSubstitutes, setEditSubstitutes] = useState<boolean>(false); // based on league.settings
  const [substitutesHours, setSubstitutesHours] = useState<number>(0); // based on league.settings

  const addNewSteward = (event: FormEvent) => {
    event.preventDefault();
    leagueStore.addNewSteward(newSteward);
    setNewSteward('');
  };

  const addNewAdmin = (event: FormEvent) => {
    event.preventDefault();
    leagueStore.addNewAdmin(newAdmin);
    setNewAdmin('');
  };

  return (
    <PageContent>
      <ScrollingItemsContainerRowsSmallGap>
        <Accordion expanded={editIncidents} disabled>
          <AccordionSummarySwitch
            expandIcon={
              <Switch
                edge='end'
                onChange={() => setEditIncidents(!editIncidents)}
                checked={editIncidents}
              />
            }
          >
            <SRMTooltip text='Hier kann der Wochentag und die Uhrzeit bis zu dieser ein Vorfall, der noch nicht abgeschlossen worden ist, bearbeitet werden kann z.B. bis Dienstags 18:00 Uhr'>
              <Typography>Vorfälle überarbeiten</Typography>
            </SRMTooltip>
          </AccordionSummarySwitch>
          <AccordionDetails>
            <Row>
              <FormControl fullWidth>
                <InputLabel id='weekday'>Wochentag</InputLabel>
                <Select
                  id='weekday'
                  value={editIncidentsWeekDay.toString()}
                  onChange={(event: SelectChangeEvent) =>
                    setEditIncidentsWeekDay(parseInt(event.target.value, 10))
                  }
                >
                  {new Array(7).fill(null).map((_, index) => {
                    return (
                      <MenuItem value={index} key={index}>
                        {format(
                          add(nextMonday(new Date()), { days: index }),
                          'EEEE',
                          {
                            locale: deLocale,
                          }
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TimePicker
                label='Uhrzeit'
                value={editIncidentsTime}
                onChange={(time) => setEditIncidentsTime(time)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Row>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={editRaceSuspension} disabled>
          <AccordionSummarySwitch
            expandIcon={
              <Switch
                edge='end'
                onChange={() => setEditRaceSuspension(!editRaceSuspension)}
                checked={editRaceSuspension}
              />
            }
          >
            <SRMTooltip text='Hier kann die Anzahl an Strafpunkten festgelegt werden, bei denen ein Fahrer eine automatische Rennsperre erhalten soll, wenn diese erreicht worden sind. z.B. alle 10 Punkte.'>
              <Typography>Rennsperren</Typography>
            </SRMTooltip>
          </AccordionSummarySwitch>
          <AccordionDetails>
            <TextField
              type='number'
              color='secondary'
              fullWidth
              value={raceSuspensionPoints}
              inputProps={{
                min: 0,
              }}
              onChange={(event) =>
                setRaceSuspensionPoints(parseInt(event.currentTarget.value, 10))
              }
              label='Strafpunkte'
            />
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={editSubstitutes} disabled>
          <AccordionSummarySwitch
            expandIcon={
              <Switch
                edge='end'
                onChange={() => setEditSubstitutes(!editSubstitutes)}
                checked={editSubstitutes}
              />
            }
          >
            <SRMTooltip text='Hier kann eingestellt werden, ob sich Ersatzfahrer auch zu einem Liga-Event anmelden können und ab wie vielen Stunden vor einem Liga-Event andere Stammfahrer (abhängig von deren Fahrerlaubnis) sich ebenfalls anmelden können, sodass die Ersatzfahrer mehr Zeit haben und dadurch bevorzugt werden. Bei 0 Stunden können sich nur Ersatzfahrer anmelden'>
              <Typography>Ersatzfahrer Anmeldungen</Typography>
            </SRMTooltip>
          </AccordionSummarySwitch>
          <AccordionDetails>
            <TextField
              type='number'
              color='secondary'
              fullWidth
              value={substitutesHours}
              onChange={(event) =>
                setSubstitutesHours(parseInt(event.currentTarget.value, 10))
              }
              inputProps={{
                min: 0,
              }}
              label='Stunden vor Liga-Event'
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Admins der Liga</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChipContainer>
              {leagueStore.getAdmins.map((a) => {
                return (
                  <Chip
                    key={`admin-${a}`}
                    label={a}
                    size='small'
                    color='warning'
                    onDelete={
                      leagueStore.ownerId === leagueStore.driver?.driverId
                        ? () => leagueStore.removeAdmin(a)
                        : undefined
                    }
                  />
                );
              })}
            </ChipContainer>
            {leagueStore.ownerId === leagueStore.driver?.driverId && (
              <>
                <br />
                <Form onSubmit={addNewAdmin}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor='new-admins' color='secondary'>
                      Neuen Admin bestimmen
                    </InputLabel>
                    <NativeSelect
                      id='new-admins'
                      fullWidth
                      value={newAdmin}
                      onChange={(event) => setNewAdmin(event.target.value)}
                      required
                      color='secondary'
                    >
                      <option value='' disabled></option>
                      {leagueStore.getAllDriver().map((dr, i) => {
                        return (
                          <option
                            value={dr.name}
                            key={`new-admin-${dr.name}`}
                            disabled={leagueStore.getAdmins.includes(dr.name)}
                          >
                            {dr.name}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </FormControl>
                  <IconButton
                    type='submit'
                    disabled={
                      leagueStore.ownerId !== leagueStore.driver?.driverId
                    }
                  >
                    <SaveIcon />
                  </IconButton>
                </Form>
              </>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Stewards der Liga</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ChipContainer>
              {leagueStore.getStewards.map((s) => {
                return (
                  <Chip
                    key={`steward-${s}`}
                    label={s}
                    size='small'
                    color='info'
                    onDelete={() => leagueStore.removeSteward(s)}
                  />
                );
              })}
            </ChipContainer>
            <br />
            <Form onSubmit={addNewSteward}>
              <FormControl fullWidth>
                <InputLabel htmlFor='new-admins' color='secondary'>
                  Neuen Steward bestimmen
                </InputLabel>
                <NativeSelect
                  id='new-admins'
                  fullWidth
                  onChange={(event) => setNewSteward(event.target.value)}
                  required
                  value={newSteward}
                  color='secondary'
                >
                  <option value='' disabled></option>
                  {leagueStore.getAllDriver().map((dr, i) => {
                    return (
                      <option
                        value={dr.name}
                        key={`new-steward-${dr.name}`}
                        disabled={leagueStore.getStewards.includes(dr.name)}
                      >
                        {dr.name}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
              <IconButton
                type='submit'
                disabled={!leagueStore.isAdmin() || !newSteward}
              >
                <SaveIcon />
              </IconButton>
            </Form>
          </AccordionDetails>
        </Accordion>
      </ScrollingItemsContainerRowsSmallGap>
    </PageContent>
  );
});

const ChipContainer = styled.div`
  .MuiChip-root {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
`;

const AccordionSummarySwitch = styled(AccordionSummary)`
  .MuiAccordionSummary-expandIconWrapper {
    transform: none !important;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;
