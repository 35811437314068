import * as React from 'react';
import styled from 'styled-components';
import { Modal as StyledModal, Typography } from '@mui/material';
import { COUNTRY_FLAG_API_BIG } from '../../App';
import { Transition } from '../FilterModal';

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  background?: string;
  backgroundColor?: string | null;
  title?: string;
  subtitle?: string;
  noPadding?: boolean;
  flag?: string;
}

export const Modal = ({
  isOpen,
  handleClose,
  children,
  background,
  backgroundColor,
  title,
  subtitle = '',
  noPadding = false,
  flag,
}: ModalProps) => {
  const onClose = (event: any) => {
    if (event.target.id === 'modalBackground') {
      handleClose();
    }
  };

  return (
    <StyledModal open={isOpen} onClose={onClose}>
      <div
        id='modalBackground'
        className='modal'
        style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          outline: 'none',
        }}
        onClick={onClose}
      >
        <Transition direction='down'>
          <ModalContent
            className='modal'
            background={background}
            backgroundColor={backgroundColor}
            noPadding={noPadding}
          >
            {title && (
              <div>
                <TitleItems>
                  <Title>
                    <Typography variant='h5'>{title}</Typography>
                    <Typography variant='subtitle1' component='span'>
                      {subtitle}
                    </Typography>
                  </Title>
                  {flag && (
                    <Img
                      src={`${COUNTRY_FLAG_API_BIG}/${flag.toLowerCase()}.png`}
                      alt={flag}
                    />
                  )}
                </TitleItems>
              </div>
            )}

            {children}
          </ModalContent>
        </Transition>
      </div>
    </StyledModal>
  );
};

interface ModalContentProps {
  background?: string;
  backgroundColor?: string | null;
  noPadding?: boolean;
}

const ModalContent = styled.div<ModalContentProps>`
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
  top: 0;
  position: absolute;
  padding: ${(props) => (props.noPadding ? '15px 0' : '15px')};
  ${(props) =>
    props.backgroundColor
      ? `background-color: ${props.backgroundColor}`
      : 'background-color: #fff'};
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  @media only screen and (min-width: 768px) {
    height: auto;
    max-height: 80%;
    border-radius: 15px;
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
    max-width: 50%;
  }

  @media only screen and (min-width: 1024px) {
    max-width: 40%;
  }
`;

const TitleItems = styled.div`
  padding-bottom: var(--srmPadding);
  display: flex;
  align-items: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    flex: 1;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h5,
  span {
    line-height: 1.1;
  }
`;

const Img = styled.img`
  max-width: 48px;
`;
