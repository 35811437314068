import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { LeagueStore } from '../../../store';
import { Incident } from '../../../components/Incident';
import { ScrollingItemsContainer } from '../../../components/Content';
import { Incident_IF } from '../../../store/LeagueModel';
import { Headline } from '../../../components/Headline';
import { Search, SearchContainer } from '../../../components/Search';
import { FilterModal } from '../../../components/FilterModal';
import { EditLayout } from '../../../components/Edit/EditLayout';
import { timeout } from '../../../utils/time';
import { Loading } from '../../../components/Loading';
import { DriverTypeMapping } from '../Drivers/DriversOverview';

export const EditComplaints = observer(() => {
  const leagueStore = useContext(LeagueStore);
  const [search, setSearch] = useState<string>('');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [incidents, setIncidents] = useState<Incident_IF[]>([]);
  const [allIncidents, setAllIncidents] = useState<Incident_IF[]>([]);
  const [filterTags, setFilterTags] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleAddFilterTag = (tag: string | string[]) => {
    setFilterTags(typeof tag === 'string' ? tag.split(',') : tag);
  };

  const handleRemoveFilterTag = (index: number) => {
    const tempTags = filterTags.slice();
    tempTags.splice(index, 1);
    setFilterTags(tempTags);
  };

  useEffect(() => {
    setIsLoading(true);
    const tempIncidents = leagueStore.getIncidentsById();

    timeout(300).then(() => {
      setAllIncidents(tempIncidents);
      setIncidents(tempIncidents);
      setIsLoading(false);
    });
  }, [leagueStore.currentLeague]);

  useEffect(() => {
    const tempIncidents = allIncidents
      .slice()
      .filter((inc) => {
        if (filterTags.length === 0 || filterTags.includes(inc.divisionId)) {
          return inc;
        }
      })
      .filter((inc) => {
        if (search) {
          if (
            inc.author.driver.toLowerCase().includes(search.toLowerCase()) ||
            inc.track.toLowerCase().includes(search.toLowerCase()) ||
            inc.receiver.driver.toLowerCase().includes(search.toLowerCase())
          ) {
            return inc;
          }
        } else {
          return inc;
        }
      });

    setIncidents(tempIncidents);
  }, [search, filterTags]);

  return (
    <Container>
      <SearchContainer>
        <Search
          placeholder='Suche einen Fahrer oder Strecke'
          searchTerm={search}
          onSearch={setSearch}
          onClear={() => setSearch('')}
          tags={filterTags.map((t) => {
            return (
              DriverTypeMapping[t] || leagueStore.getDivisionNameById(t) || t
            );
          })}
          onRemoveTag={handleRemoveFilterTag}
        />
      </SearchContainer>
      <Headline title='Vorfälle' infoText={incidents.length} enablePadding />

      {isLoading ? (
        <Loading />
      ) : (
        <EditLayout
          onFilterClick={() => setShowFilter(!showFilter)}
          enablePadding
        >
          <ScrollingItemsContainer>
            {incidents?.map((inc, i) => {
              return <Incident key={inc.date + i} incident={inc} isManagerView />;
            })}
          </ScrollingItemsContainer>
        </EditLayout>
      )}

      {/* Filter Modal */}
      {showFilter && (
        <FilterModal
          isOpen={showFilter}
          onClose={() => setShowFilter(false)}
          title='Filtern & Sortieren'
        >
          <FormControl fullWidth>
            <InputLabel id='filter-drivers'>Filtern nach</InputLabel>
            <Select
              id='filter-drivers'
              multiple
              value={filterTags}
              onChange={(event: SelectChangeEvent<typeof filterTags>) =>
                handleAddFilterTag(event.target.value)
              }
              renderValue={(selected) =>
                selected
                  .map((s) => {
                    return (
                      DriverTypeMapping[s] ||
                      leagueStore.getDivisionNameById(s) ||
                      s
                    );
                  })
                  .join(', ')
              }
            >
              {[
                { name: 'Testrennen', tag: 'Testrennen' },
                ...leagueStore.getAllDivisions.map((d) => {
                  return { name: d.name, tag: d._id };
                }),
              ].map((d, i) => (
                <MenuItem key={`${i}-${d.tag}`} value={d.tag}>
                  <Checkbox checked={filterTags.indexOf(d.tag) > -1} />
                  <ListItemText primary={d.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FilterModal>
      )}
    </Container>
  );
});

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
  overflow-y: hidden;
`;
