import { createContext } from 'react';
import UserModel from './UserModel';
import LeagueModel from './LeagueModel';
import MessageModal from './MessageStore';

const message = new MessageModal();
const user = new UserModel(message);

const UserStore = createContext(user);
const MessageStore = createContext(message);
const LeagueStore = createContext(new LeagueModel(user, message));

export { UserStore, LeagueStore, MessageStore };
