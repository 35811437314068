import styled from 'styled-components';
import { Breakpoint } from '../utils/breakpoints';

export const PageContent = styled.div`
  position: relative;
  padding: var(--srmPadding);
  height: 100%;
`;

export const PageContentNews = styled.div`
  position: relative;
  padding: var(--srmPadding) calc(var(--srmPadding) - 15px);
  height: 100%;
`;

export const ScrollingItemsContainer = styled.div`
  display: grid;
  grid-gap: var(--srmPaddingBig);
  padding-bottom: var(--srmPaddingBig);

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ScrollingItemsContainerRows = styled.div`
  display: grid;
  grid-gap: var(--srmPaddingBig);
  padding-bottom: var(--srmPaddingBig);

  @media only screen and (min-width: ${Breakpoint.smallDesktop}px) {
    width: 90%;
    margin: 0 auto;
  }

  @media only screen and (min-width: ${Breakpoint.desktop}px) {
    width: 80%;
    margin: 0 auto;
  }

  @media only screen and (min-width: ${Breakpoint.mediumDesktop}px) {
    width: 75%;
    margin: 0 auto;
  }
`;

export const ScrollingItemsContainerRowsSmallGap = styled.div`
  display: grid;
  padding-bottom: var(--srmPaddingBig);
  grid-gap: 10px;

  @media only screen and (min-width: ${Breakpoint.smallDesktop}px) {
    width: 90%;
    margin: 0 auto;
  }

  @media only screen and (min-width: ${Breakpoint.desktop}px) {
    width: 80%;
    margin: 0 auto;
  }

  @media only screen and (min-width: ${Breakpoint.mediumDesktop}px) {
    width: 75%;
    margin: 0 auto;
  }
`;
