import { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Typography,
  IconButton,
  TextField,
  ListItem,
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  FormControlLabel,
  Switch,
  ListItemText,
} from '@mui/material';
import { EventResult } from '../../../store/LeagueModel';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SpeedIcon from '@mui/icons-material/Speed';
import { CenterModal } from '../../../components/Modal/CenterModal';

interface EditEventDriverProps {
  result: EventResult;
  index: number;
  handleSave: (result: EventResult, index: number) => void;
  handleRemove: (index: number) => void;
}

export const EditEventDriver = ({
  result,
  index,
  handleSave,
  handleRemove,
}: EditEventDriverProps) => {
  const [addInfo, setAddInfo] = useState<boolean>(false);
  const [place, setPlace] = useState<number>(result.place);
  const [time, setTime] = useState<string>(result.time);
  const [qualiPlace, setQualiPlace] = useState<number | undefined>(
    result.quali?.place
  );
  const [qualiTime, setQualiTime] = useState<number | undefined>(
    result.quali?.time
  );
  const [hasFastestLap, setHasFastestLap] = useState<boolean>(
    result.hasFastestLap
  );
  const [isDnfOrDsq, setIsDnfOrDsq] = useState<boolean>(result.isDnfOrDsq);

  const handleAddInfo = () => {
    const data = {
      ...result,
      time: time || '',
      place,
      quali: {
        time: qualiTime || 0,
        place: qualiPlace || 1,
      },
      hasFastestLap,
      isDnfOrDsq,
    };

    handleSave(data, index);

    // Reset
    setAddInfo(false);
    setPlace(1);
    setTime('');
    setQualiPlace(1);
    setQualiTime(undefined);
    setHasFastestLap(false);
    setIsDnfOrDsq(false);
  };

  useEffect(() => {
    setPlace(result.place || 1);
    setTime(result.time);
    setQualiPlace(result.quali?.place || 1);
    setQualiTime(result.quali?.time);
    setHasFastestLap(result.hasFastestLap);
    setIsDnfOrDsq(result.isDnfOrDsq);
  }, [addInfo]);

  return (
    <Row>
      <ListItem
        button
        key={result.name}
        divider
        dense
        disablePadding
        onClick={() => setAddInfo(true)}
      >
        <ListItemText
          primary={`${result.place}. ${result.name}`}
          secondary={result.team}
        />
        <ListItemActions>
          <>
            <CheckCircleIcon
              fontSize='small'
              htmlColor={
                result.time && result.place && result.quali
                  ? 'var(--srmGreen)'
                  : 'var(--srmGreyLight)'
              }
            />

            <SpeedIcon
              fontSize='small'
              htmlColor={
                result.hasFastestLap ? 'var(--srmPink)' : 'var(--srmGreyLight)'
              }
            />
          </>
        </ListItemActions>
      </ListItem>
      <IconButton size='small' onClick={() => handleRemove(index)}>
        <DeleteForeverIcon htmlColor='var(--srmRed)' />
      </IconButton>

      {/* Edit Driver Result Modal */}
      <CenterModal isOpen={addInfo} handleClose={() => setAddInfo(false)}>
        <ModalContent>
          <Typography variant='h6'>{result.name}</Typography>
          <br />
          <SelectRow>
            <TextField
              type='text'
              color='secondary'
              value={time}
              placeholder='45:12,123, 1,234'
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setTime(event.currentTarget.value)}
              label='Rennzeit'
            />
            <FormControl fullWidth>
              <InputLabel htmlFor='place'>Rennplatzierung</InputLabel>
              <NativeSelect
                id='place'
                fullWidth
                onChange={(event) => setPlace(parseInt(event.target.value, 10))}
                value={place}
                placeholder='Platz'
                required
              >
                {new Array(20).fill(null).map((item, index) => {
                  return (
                    <option
                      key={`Race Place ${index + 1}`}
                      value={index + 1}
                    >{`${index + 1}. Platz`}</option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          </SelectRow>
          <br />
          <SelectRow>
            <TextField
              type='number'
              color='secondary'
              value={qualiTime}
              placeholder='in Sekunden'
              onChange={(
                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setQualiTime(parseFloat(event.currentTarget.value))}
              label='Qualizeit'
            />

            <FormControl fullWidth>
              <InputLabel htmlFor='quali-place'>Qualiplatzierung</InputLabel>
              <NativeSelect
                id='quali-place'
                fullWidth
                onChange={(event) =>
                  setQualiPlace(parseInt(event.target.value, 10))
                }
                value={qualiPlace}
                placeholder='Platz'
                required
              >
                {new Array(20).fill(null).map((item, index) => {
                  return (
                    <option
                      key={`Quali Place ${index + 1}`}
                      value={index + 1}
                    >{`${index + 1}. Platz`}</option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          </SelectRow>
          <br />
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={hasFastestLap}
                onChange={() => setHasFastestLap(!hasFastestLap)}
                name='fastestLap'
              />
            }
            label='Schnellste Runde ?'
          />
          <br />
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={isDnfOrDsq}
                onChange={() => setIsDnfOrDsq(!isDnfOrDsq)}
                name='dnf/dsq'
              />
            }
            label='DNF/DSQ'
          />
          <br />
          <Button
            onClick={handleAddInfo}
            disabled={!time || qualiTime === undefined || !place || !qualiPlace}
            variant='contained'
            color='primary'
          >
            Speichern
          </Button>
        </ModalContent>
      </CenterModal>
    </Row>
  );
};

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
`;

const ModalContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SelectRow = styled.div`
  display: flex;
  gap: var(--srmPadding);
`;

const ListItemActions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  align-items: center;
  justify-content: center;
`;
