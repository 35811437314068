import {
  Typography,
  Button,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import styled from 'styled-components';
import {
  PageContent,
  ScrollingItemsContainerRowsSmallGap,
} from '../../components/Content';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/CheckCircle';

import { FormEvent, useContext, useState } from 'react';
import { UserStore } from '../../store';
import { observer } from 'mobx-react-lite';

export const Settings = observer(() => {
  const userStore = useContext(UserStore);

  // MessageId
  const [messageId, setMessageId] = useState<string>('');

  // Password
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>('');

  // Display name
  const [newDisplayName, newNewDisplayName] = useState<string>('');

  // Login name
  const [newLoginName, setNewLoginName] = useState<string>('');

  const handleSaveLoginName = (event: FormEvent) => {
    userStore.changeLoginName(oldPassword, newLoginName);
    event.preventDefault();
  };

  const handleSaveDisplayName = (event: FormEvent) => {
    userStore.changeDisplayName(oldPassword, newDisplayName);
    event.preventDefault();
  };

  const handleSavePassword = (event: FormEvent) => {
    userStore.changePassword(oldPassword, newPassword, newPasswordRepeat);
    event.preventDefault();
  };

  const handleAddMessageId = (event: FormEvent) => {
    event.preventDefault();
    userStore.addMessageId(messageId);
    setMessageId('');
  };

  return (
    <PageContent>
      <ScrollingItemsContainerRowsSmallGap>
        <Button
          onClick={() => userStore.logout()}
          color='secondary'
          variant='contained'
        >
          Abmelden
        </Button>
        <br />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Loginname ändern</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form onSubmit={handleSaveLoginName}>
              <FormContent>
                <TextField
                  type='password'
                  color='secondary'
                  required
                  value={oldPassword}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => setOldPassword(event.target.value)}
                  label='Aktuelles Passwort'
                />
                <TextField
                  type='text'
                  color='secondary'
                  required
                  value={newLoginName}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => setNewLoginName(event.target.value)}
                  label='Neuer Loginname'
                />
                <Button
                  type='submit'
                  color='primary'
                  variant='contained'
                  disabled={!oldPassword || !newLoginName}
                >
                  Speichern
                </Button>
                <Typography variant='body2' component='p'>
                  * nach dem Speichern wirst du abgemeldet
                </Typography>
              </FormContent>
            </form>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Passwort ändern</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form onSubmit={handleSavePassword}>
              <FormContent>
                <TextField
                  type='password'
                  color='secondary'
                  required
                  value={oldPassword}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => setOldPassword(event.target.value)}
                  label='Aktuelles Passwort'
                />
                <TextField
                  type='password'
                  color='secondary'
                  required
                  value={newPassword}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => setNewPassword(event.target.value)}
                  label='Neues Passwort'
                />
                <TextField
                  type='password'
                  color='secondary'
                  required
                  value={newPasswordRepeat}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => setNewPasswordRepeat(event.target.value)}
                  label='Neues Passwort wiederholen'
                />
                <Button
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={!oldPassword || !newPassword || !newPasswordRepeat}
                >
                  Speichern
                </Button>
                <Typography variant='body2' component='p'>
                  * nach dem Speichern wirst du abgemeldet
                </Typography>
              </FormContent>
            </form>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Discord Erinnerungen</Typography>&nbsp;
            {userStore.settings.hasMessageId && (
              <DoneIcon fontSize='small' htmlColor='var(--srmGreen)' />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <form onSubmit={handleAddMessageId}>
              <FormContent>
                <TextField
                  type='text'
                  color='secondary'
                  value={messageId}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => setMessageId(event.target.value)}
                  label='Deine ID'
                />

                <Button type='submit' color='primary' variant='contained'>
                  Speichern
                </Button>
              </FormContent>
            </form>
          </AccordionDetails>
        </Accordion>
        <Accordion disabled>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Anzeigename ändern</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form onSubmit={handleSaveDisplayName}>
              <FormContent>
                <TextField
                  type='password'
                  color='secondary'
                  required
                  value={oldPassword}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => setOldPassword(event.target.value)}
                  label='Aktuelles Passwort'
                />
                <TextField
                  type='text'
                  color='secondary'
                  required
                  value={newDisplayName}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => newNewDisplayName(event.target.value)}
                  label='Neuer Anzeigename'
                />
                <Button
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={!oldPassword || !newDisplayName}
                >
                  Speichern
                </Button>
                <Typography variant='body2' component='p'>
                  * nach dem Speichern wirst du abgemeldet
                </Typography>
              </FormContent>
            </form>
          </AccordionDetails>
        </Accordion>
        <Accordion disabled>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Account löschen</Typography>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
        </Accordion>
        <Accordion disabled>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Liga verlassen</Typography>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
        </Accordion>
      </ScrollingItemsContainerRowsSmallGap>
    </PageContent>
  );
});

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--srmPadding);
`;
